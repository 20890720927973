import React, { useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

export default function Activate(props) {
  const Key = useRef(null);

  let history = useHistory();
  const cookies = new Cookies();

  function resendemail() {
    axios
      .post("https://spibi-online.de/resendemail", {
        userid: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          props.addnotification("E-Mail wurde gesendet");
        } else {
          if (response.data.acctivated) {
            history.push("/");
          } else {
            props.addnotification(response.data.msg);
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    console.log("resend");
  }

  return (
    <div className="Anmelden">
      <div className="Form-card">
        <h3 className="text-white">
          Mit Hilfe des Links in der E-Mail können sie ihr Konto aktivieren
        </h3>
        <div className="Anmelden-p text-white">
          Du hast die E-Mail nicht erhalten?
          <br />
          <div
            onClick={resendemail}
            style={{ color: "#7FDBFF", textDecoration: "underline" }}
          >
            Neu Senden
          </div>
        </div>
        <div className="Anmelden-p text-white">
          Du hast bereits auf den Link in der E-Mail geklickt?
          <br />
          <button
            onClick={() => {
              history.push("/");
              window.location.reload();
            }}
            className="btn bg-[#62abe9]"
          >
            Zurück zur Startseite
          </button>
        </div>
      </div>
    </div>
  );
}
