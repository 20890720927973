import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

export default function AddOldSpibis(props) {
  const cookies = new Cookies();
  let history = useHistory();
  const [pdf, setPdf] = useState(null);
  const title = useRef(null);
  const year = useRef(null);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  });

  function handlePdf(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 1000000000) {
        setPdf(upload.target.result);
      } else {
      }
    };
    reader.readAsDataURL(file);
  }

  function handleSubmit(e) {
    if (
      title.current.value != null &&
      year.current.value != null &&
      pdf != null
    ) {
      axios
        .post("https://spibi-online.de/addOldSpibi", {
          id: cookies.get("User_id"),
          title: title.current.value,
          year: year.current.value,
          pdf: pdf,
        })
        .then((response) => {
          if (response.data != true) {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      props.addnotification("Du hast vergessen etwas einzutragen");
    }
  }

  return (
    <div className="AddOldSpibis">
      <h3>Alte Spibis hinzufügen</h3>
      <div className="AddOldSpibis-form">
        <label maxLength={200} htmlFor="title">
          Titel:
        </label>
        <input type="text" name="title" placeholder="Titel" ref={title} />
        <label maxLength={200} htmlFor="year">
          Jahr der Ausgabe:
        </label>
        <input
          type="number"
          max="2100"
          min="1950"
          step="1"
          name="year"
          ref={year}
        />
        <label maxLength={200} htmlFor="file">
          PDF hochladen:
        </label>
        <input
          type="file"
          name="file"
          className="AddOldSpibis-file-input"
          id="file"
          onChange={handlePdf}
          encType="multipart/form-data"
          required
        />
        <button className="AddOldSpibis-submit" onClick={handleSubmit}>
          Hinzufügen
        </button>
      </div>
    </div>
  );
}
