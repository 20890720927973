import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Artikel from "./../Artikel";
import Banner from "./../banner";
import { useLocation } from "react-router-dom";
import LogoB from "./../icons/spibiLogo.svg";
import LogoW from "./../icons/spibiLogo_weiß.svg";

import Artikelloading from "./../Artikelloading";
import Homestory from "./../img/Homestory.webp";

import Lehrersprüche from "./Kategorienimgs/0F1850FE-7C94-4FE0-BE4E-B082D0F11811.webp";
import Schülersprüche from "./Kategorienimgs/A4EA5221-82C4-4803-BAE1-13B7DE751473.01821713fc0665f21d0c.png.webp";
import SRVLogo from "./Kategorienimgs/SRV-Logo_Vektor.svg";

export default function Home(props) {
  const location = useLocation();
  const cookies = new Cookies();
  const [search, setsearch] = useState("");
  const [filterHidden, setFilterHidden] = useState(false);
  const [category, setcategory] = useState("");
  const [sorting, setsorting] = useState("1");
  const [artikel, setartikel] = useState(props.artikel);
  const [sortedartikel, setsortedartikel] = useState([]);
  const [Name, setname] = useState(props.name);
  const [artikelids, setartikelids] = useState(null);
  const [banners, setbanners] = useState(props.banners);
  const [Plus, setPlus] = useState(false);
  const [gelesen, setgelesen] = useState(props.gelesen);
  const homeScroll = useRef(null);
  const [toggleLeft, settoggleLeft] = useState(false);
  const [img, setimg] = useState(null);
  let history = useHistory();
  let something = 0;

  function handleinputChange(e) {
    setsearch(e.target.value);
  }

  function setcategoryfoo(search) {
    if (search === category) {
      setcategory("");
    } else {
      setcategory(search);
    }
  }

  function showFilter() {
    if (filterHidden === true) {
      setFilterHidden(false);
    } else {
      setFilterHidden(true);
    }
  }

  function setsetsorting(e) {
    setsorting(e.target.value);
  }

  useEffect(() => {
    setartikel(props.artikel);
    setbanners(props.banners);
    sort();
  }, [props.artikel, props.banners]);

  useEffect(() => {
    setname(props.name);
    setgelesen(props.gelesen);
  }, [props.name, props.gelesen]);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isPlus", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == true) {
          setPlus(true);
        }
      });
  }, [props.artikel]);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getProfilepicture", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response);
        setimg(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function sort() {
    let curartikel = props.artikel.map((obj) => obj);

    // if (sorting === "2") {
    //   curartikel = curartikel.reverse();
    // } else if (sorting === "1") {
    //   curartikel = curartikel.sort((a, b) => {
    //     console.log(a.name);
    //     console.log(Date.parse(a.time));
    //     if (Date.parse(a.time) < Date.parse(b.time)) {
    //       return 1;
    //     }
    //     if (Date.parse(a.time) > Date.parse(b.time)) {
    //       return -1;
    //     }
    //   });
    // } else if (sorting === "3") {
    //   curartikel = curartikel.sort((a, b) => {
    //     if (a.views < b.views) {
    //       return 1;
    //     }
    //     if (a.views > b.views) {
    //       return -1;
    //     }
    //   });
    // } else if (sorting === "4") {
    //   curartikel = curartikel.sort((a, b) => {
    //     if (a.views < b.views) {
    //       return -1;
    //     }
    //     if (a.views > b.views) {
    //       return 1;
    //     }
    //   });
    // }
    console.log(search);
    curartikel = curartikel.filter((obj) => {
      if (obj.Kategorie !== "Gisinside") {
        return false;
      }

      console.log(obj);
      let search2;
      if (search) {
        search2 = search.toLowerCase();
      } else {
        search2 = "";
      }

      if (search === "") {
        return true;
      } else if (obj.name.toLowerCase().includes(search2)) {
        return true;
      } else if (obj.autor.toLowerCase().includes(search2)) {
        return true;
      } else if (obj.titletext.toLowerCase().includes(search2)) {
        return true;
      } else {
        return false;
      }
    });

    setsortedartikel(curartikel);
    //let foo = 0;
    // let curartikel2 = curartikel.map((obj, index) => {
    //   if (!obj.plus || obj.artikel[0] != null) {
    //     foo += 1;
    //     return { id: foo };
    //   } else {
    //     return { id: null };
    //   }
    // });
    // setartikelids(curartikel2);
  }

  useEffect(() => {
    sort();
  }, [sorting, search]);

  useEffect(() => {
    setsearch(props.search);
  }, [props.search]);

  const executeScroll = () => homeScroll.current.scrollIntoView();

  let foo = 0;

  function logout() {
    cookies.set("User_id", "", { path: "/" });
    history.push("/Anmelden");
    window.location.reload();
  }

  return (
    <div>
      <div className="navbar bg-base-100 rounded-lg !w-[96vw]">
        <div className="navbar-start">
          <div className="only-phone">
            <label htmlFor="my-drawer-3" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
          </div>
          <div className="dropdown only-desktop">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Kategorien">Kategorien</Link>
              </li>
              <li>
                <Link to="/createartikel">Artikel erstellen</Link>
              </li>
              <li className="text-base-300 hover:bg-base-100">
                {/*to="/Events"*/}
                <div className="hover:bg-base-100">Events</div>
              </li>

              <li className="text-base-300 hover:bg-base-100">
                <div className="hover:bg-base-100">Umfragen</div>
              </li>
              <hr />
              <li>
                <Link to="/Konto">Einstellungen</Link>
              </li>
              {/* <li>
                <Link to="/myArticles">Meine Artikel</Link>
              </li> */}
              {/* <li>
                <Link to="/createArtikel">Artikel erstellen</Link>
              </li> */}
              <li>
                <div onClick={() => logout()}>Abmelden</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <Link className="btn btn-ghost normal-case text-xl" to="/">
            <img
              src={props.colormode == "cmyk" ? LogoB : LogoW}
              className="navbar-top-text-logo"
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-end">
          <div className="form-control only-desktop">
            <input
              type="text"
              placeholder="Search"
              className="input input-bordered !rounded-full"
              onChange={handleinputChange}
            />
          </div>
          <Link to="/Konto" className="max-h-[48px]">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <div className="w-[48px] rounded-full">
                {img != "Unknown" && img != " " && img != "" ? (
                  <img
                    src={img}
                    loading="lazy"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                ) : (
                  <span className="material-symbols-outlined relative top-[-1px] text-[48px]">
                    account_circle
                  </span>
                )}
              </div>
            </label>
          </Link>
        </div>
      </div>
      <Link key={1} to="/Kategorien">
        <div className="zurück-kategorien z-[10000]">
          <span className="material-symbols-outlined text-primary-content rounded-full bg-[#dddddd00]">
            arrow_back
          </span>
        </div>
      </Link>
      <div className="Home pb-60">
        <div className="Home-inner" ref={homeScroll}>
          <div className="Home-Artikel flex justify-center relative">
            <div className="artikel-list-normal only-phone object-cover">
              <Link to="/Kategorien/gisinside/Lehrersprueche" key={0}>
                <Artikel
                  key2={0}
                  key={String(0)}
                  id={"12345"}
                  img={Lehrersprüche}
                  title={"Lehrersprüche"}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/gisinside/SRV" key={2}>
                <Artikel
                  key2={2}
                  key={String(2)}
                  id={"12345"}
                  img={SRVLogo}
                  title={""}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/gisinside/Homestorys" key={4}>
                <Artikel
                  key2={0}
                  key={String(0)}
                  id={"12345"}
                  img={Homestory}
                  title={"Homestorys"}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/gisinside/Schülersprüche" key={5}>
                <Artikel
                  key2={2}
                  key={String(2)}
                  id={"12345"}
                  img={Schülersprüche}
                  title={"Schülersprüche"}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              {sortedartikel.length > 0 &&
                sortedartikel.map((obj, index) => {
                  let searchedbanner = [];
                  if (banners) {
                    searchedbanner = banners.filter(
                      (banner) => Number(banner.position) === index
                    );
                  }
                  if (searchedbanner.length > 0) {
                    return (
                      <div key={index}>
                        <Artikel
                          key2={index}
                          key={String(index)}
                          id={obj._id}
                          img={obj.img}
                          title={obj.name}
                          color={obj.color}
                          text={obj.titletext}
                          autor={obj.autor}
                          artikeltext={obj.artikel}
                          className={"normal-article"}
                          plus={obj.plus}
                          colormode={props.colormode}
                          tags={obj.tags}
                          colors={props.colors}
                          gelesen={gelesen}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <Artikel
                        key2={index}
                        key={String(index)}
                        id={obj._id}
                        img={obj.img}
                        title={obj.name}
                        color={obj.color}
                        text={obj.titletext}
                        autor={obj.autor}
                        artikeltext={obj.artikel}
                        className={"normal-article"}
                        plus={obj.plus}
                        colormode={props.colormode}
                        tags={obj.tags}
                        colors={props.colors}
                        gelesen={gelesen}
                      />
                    </div>
                  );
                })}
              {sortedartikel.length < 1 && search.length < 1 && (
                <div>
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                </div>
              )}
            </div>

            <div
              className={
                "spibi-artikel only-desktop relative Home-limitsize flex "
              }
            >
              <div className=" absolute">
                <Link to="/Kategorien/gisinside/Lehrersprueche" key={0}>
                  <Artikel
                    key2={0}
                    key={String(0)}
                    id={"12345"}
                    img={Lehrersprüche}
                    title={"Lehrersprüche"}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                <Link to="/Kategorien/gisinside/SRV" key={2}>
                  <Artikel
                    key2={2}
                    key={String(2)}
                    id={"12345"}
                    img={SRVLogo}
                    title={""}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                {sortedartikel.length > 0 &&
                  sortedartikel.map((obj, index) => {
                    if (index % 2 == 0) {
                      return (
                        <Artikel
                          key2={index}
                          key={String(index)}
                          id={obj._id}
                          img={obj.img}
                          title={obj.name}
                          color={obj.color}
                          colormode={props.colormode}
                          text={obj.titletext}
                          autor={obj.autor}
                          colors={props.colors}
                          artikeltext={obj.artikel}
                          className={"normal-article"}
                          plus={obj.plus}
                          tags={obj.tags}
                          gelesen={gelesen}
                        />
                      );
                    }
                  })}
                {sortedartikel.length < 1 && search.length < 1 && (
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                )}
              </div>
              <div className="absolute right-0">
                <Link to="/Kategorien/gisinside/Homestorys" key={0}>
                  <Artikel
                    key2={0}
                    key={String(0)}
                    id={"12345"}
                    img={Homestory}
                    title={"Homestorys"}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                <Link to="/Kategorien/gisinside/Schülersprüche" key={2}>
                  <Artikel
                    key2={2}
                    key={String(2)}
                    id={"12345"}
                    img={Schülersprüche}
                    title={"Schülersprüche"}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                {sortedartikel.length > 0 &&
                  sortedartikel.map((obj, index) => {
                    if (index % 2 != 0) {
                      return (
                        <Artikel
                          key2={index}
                          key={String(index)}
                          id={obj._id}
                          img={obj.img}
                          title={obj.name}
                          color={obj.color}
                          colormode={props.colormode}
                          text={obj.titletext}
                          autor={obj.autor}
                          colors={props.colors}
                          artikeltext={obj.artikel}
                          className={"normal-article"}
                          plus={obj.plus}
                          tags={obj.tags}
                          gelesen={gelesen}
                        />
                      );
                    }
                  })}
                {sortedartikel.length < 1 && search.length < 1 && (
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
