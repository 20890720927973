import React from "react";

export default function Artikelloading(props) {
  let { version } = props;

  if (version === 1) {
    return (
      <div>
        <div className="artikel card !bg-base-300 shadow-md ">
          <figure>
            <div className="card_image loading"></div>
          </figure>
        </div>
        <div className="artikel card !bg-base-300 shadow-md ">
          <figure>
            <div className="card_image loading"></div>
          </figure>
        </div>
        <div className="artikel card !bg-base-300 shadow-md ">
          <figure>
            <div className="card_image loading"></div>
          </figure>
        </div>
      </div>
    );
  } else if (version === 4) {
    return (
      <div className="first-article card !bg-base-300 shadow-md ">
        <figure>
          <div className="card_image !h-[50vmin] loading"></div>
        </figure>
      </div>
    );
  }
}
