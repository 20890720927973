import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

export default function Artikelbearbeiten(props) {
  const img = useRef(null);
  const title = useRef(null);
  const text = useRef(null);
  const color = useRef(null);
  const artikeltext = useRef(null);

  const [Kommentare, setKommentare] = useState(null);

  const cookies = new Cookies();
  let history = useHistory();

  useEffect(() => {
    getKommentare();
  }, []);

  function getKommentare() {
    axios
      .post("https://spibi-online.de/getKommentare", {
        artikelid: props.id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        setKommentare(response.data.kommentare);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function handlelöschen(id, kommentartext) {
    axios
      .post("https://spibi-online.de/Kommentarloeschen", {
        artikelid: props.id,
        text: kommentartext,
        kommentarid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
          props.addnotification("Kommentar wurde gelöscht", "green");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  return (
    <div to={props.id} style={{ display: "block" }}>
      <span className="Artikelbearbeiten" style={{ display: "block" }}>
        Headline: <br />
        <input
          ref={title}
          className="Artikelbearbeiten-Headline"
          type="text"
          defaultValue={props.title}
        />
        Autor: <br />
        <div className="Artikelbearbeiten-Autor">{props.autor}</div>
        {Kommentare != null &&
          Kommentare.map((obj, index) => {
            return (
              <div key={index} className="Artikelbearbeiten-Kommentar">
                <div className="Artikelbearbeiten-Kommentar-name">
                  {obj.name}
                </div>
                <div className="Artikelbearbeiten-Kommentar-text">
                  {obj.text}
                </div>
                <button
                  className="Artikelbearbeiten-löschen"
                  onClick={() => handlelöschen(Kommentare[index].id, obj.text)}
                >
                  Löschen
                </button>
              </div>
            );
          })}
      </span>
    </div>
  );
}
