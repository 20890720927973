import React from "react";

const PlusfreischaltenUser = (props) => {
  return (
    <div className="PlusfreischaltenUser">
      <div className="PlusfreischaltenUser-name">{props.name}</div>
      <button
        className={props.buttonText == "+" ? "buttonGreen" : "buttonRed"}
        onClick={() => props.handleAdd(props._id)}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

export default PlusfreischaltenUser;
