import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
} from "react-router-dom";

export default function Anmelden(props) {
  const Benutzername = useRef(null);
  const password = useRef(null);
  const location = useLocation();
  const [cookiesallowed, setcookiesallowed] = useState(props.cookiesallowed);

  useEffect(() => {
    setcookiesallowed(props.cookiesallowed);
  }, [props.cookiesallowed]);

  const cookies = new Cookies();
  let history = useHistory();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (cookies.get("Cookieallowed") || cookiesallowed === true) {
      if (
        Benutzername.current.value == null ||
        password.current.value == null
      ) {
        props.addnotification("Du musst alles Ausfüllen!");
        return;
      }
      if (
        Benutzername.current.value == "Benutzername" ||
        password.current.value == "• • • •"
      ) {
        window.location.replace("http://www.zoomquilt.org");
        return;
      }
      axios
        .post("https://spibi-online.de/login", {
          name: capitalizeFirstLetter(Benutzername.current.value.toLowerCase()),
          password: password.current.value,
        })
        .then((response) => {
          if (response.data.msg != null) {
            props.addnotification(response.data.msg, "red");
          } else {
            cookies.set("User_id", response.data.id, { path: "/" });
            cookies.set("Hasaccount", true, { path: "/" });
            props.setUserid(response.data.id);
            props.setstartloadingagainupdate();
            history.push("/");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      props.addnotification(
        "Du musst die Cookies erlauben um dich anmelden zu können! #000"
      );
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        let e = { preventDefault: function () {} };
        handleSubmit(e);
        console.log("test");
      }
    });
  }, []);

  if (
    !props.Autorised &&
    location.pathname !== "/Registrieren" &&
    location.pathname !== "/Activate" &&
    location.pathname !== "/Infospassword"
  ) {
    return (
      <div className="Anmelden">
        <div className="Form-card relative">
          <h3 className="mb-2">Anmeldung</h3>
          <div className="Anmelden-input-div">
            <input
              type="text"
              placeholder="Benutzername"
              ref={Benutzername}
              className="!rounded-full placeholder:opacity-30"
            />
          </div>
          <div className="Anmelden-input-div">
            <input
              type="password"
              id="Passwort"
              className="!rounded-full placeholder:opacity-30"
              placeholder="• • • •"
              ref={password}
            />
          </div>
          <button className="Anmelden-btn text-black" onClick={handleSubmit}>
            Anmelden
          </button>
          <p className="Anmelden-p">
            Hast du noch kein Konto?
            <br />
            <Link to="/Registrieren">Registrieren</Link>
          </p>
          <p className="Anmelden-p">
            Hast du dein Passwort vergessen?
            <br />
            <Link to="/Passwortzurücksetzen">Passwort zurücksetzen</Link>
          </p>
        </div>
        <div className="absolute top-[90vh] text-white width-[50vw]">
          <Link
            to="/Datenschutz"
            className="absolute left-[5vw] link link-hover"
          >
            Datenschutz
          </Link>
          <div className="absolute right-[50%]">|</div>
          <a
            href="https://gis-wf.de/Impressum"
            className="link link-hover absolute right-[5vw]"
          >
            Impressum
          </a>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
