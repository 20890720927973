import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function Artikelselect(props) {
  const [bearbeitenmode, setbearbeitenmode] = useState(false);

  const curtag = useRef(null);

  const cookies = new Cookies();
  let history = useHistory();

  // axios
  //   .post("https://spibi-online.de/bearbeitevorArtikel", {
  //     userid: cookies.get("User_id"),
  //     id: props.id,
  //     img: img,
  //     title: title,
  //     text: titletext,
  //     artikeltext: text,
  //     tags: tags,
  //   })
  //   .then((response) => {
  //     console.log("test");
  //     if (response.data.msg != null) {
  //       props.addnotification(response.data.msg);
  //     } else {
  //       props.addnotification(
  //         "Artikel wurde geändert. Lade die Seite neu um ihn zu sehen"
  //       );
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error, "error");
  //   });

  let array = props.artikeltext;

  return (
    <Link to={props.link} className="card w-96 mt-4 bg-base-100 shadow-xl">
      <figure>
        <img src={props.img} alt="Titelbild" />
      </figure>
      <div className="card-body">
        <h2 className="card-title">{props.title}</h2>
        <p>{props.text}</p>
        <p>Autor: {props.autor}</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            props.handleAdd(props.id, false);
          }}
        >
          Add
        </button>
        {/* <button
          className="btn btn-primary"
          onClick={() => {
            props.handleAdd(props.id, true);
          }}
        >
          Add as Plus
        </button> */}
        <button
          className="btn btn-primary"
          onClick={() => {
            props.deleatArtikel(props.id);
          }}
        >
          Löschen
        </button>
      </div>
    </Link>
  );
}
