import React, { useState, useEffect, useContext } from "react";
import Logo from "./icons/spibiLogo.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Startseite = () => {
  const [Artikellist, setArtikellist] = useState([]);
  const [state, setState] = useState(0);

  let history = useHistory();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/firstthreeartikel")
      .then((response) => {
        setArtikellist(response.data.artikel);
        console.log(response.data.artikel);
      })
      .catch((error) => {
        console.log(error, "error");
      });
    //   incstate();
  }, []);

  //   function incstate() {
  //     let next = state + 1;
  //     console.log(next);
  //     setState(next);
  //   }

  return (
    <div>
      {/* <div className="Startseite w-full h-full fixed"></div> */}
      <div
        onClick={() => history.push("/Registrieren")}
        className="fixed top-2 z-4 h-48 w-full relative flex flex-col justify-center items-center"
      >
        <img src={Logo} loading="lazy" className="h-32 p-8 z-10" />
        <div className="ml-48 text-2xl lg:flex hidden">
          Deine Schülerzeitung
        </div>
      </div>
      <div className="top-58 h-[70vh] w-full flex justify-center relative font-serif">
        {/* {state === 0 && Artikellist.length > 0 && (
          <div
            onClick={() => history.push("/Registrieren")}
            className="relative h-[60vh] w-[96vw] overflow-hidden max-w-[150vmin] ml-[2vw] mr-[2vw]"
          >
            <div
              key={0}
              className="w-[40vh] top-[15vh] lg:block hidden absolute left-0 z-1 h-[30vh] object-cover"
            >
              <img
                src={Artikellist[0].img}
                className="h-[30vh] w-[40vh] rounded-lg"
              />
              <div className="absolute card w-[40vh] h-1/2 bottom-0 Artikelimg"></div>
              <div className="absolute text-white bottom-2 left-2 text-2xl lg:text-4xl artikel-title">
                {Artikellist[0].name}
              </div>
            </div>
            <div
              key={1}
              className="h-[50vh] max-w-[150vmin] flex justify-center items-center relative z-10 top-[5vh]"
            >
              <div className="h-[60vmin] w-[90vmin] relative">
                <img
                  src={Artikellist[1].img}
                  className="h-full w-full rounded-lg object-cover"
                />
                <div className="absolute card w-full h-1/2 bottom-0 Artikelimg"></div>
                <div className="absolute text-white bottom-4 w-full pl-2 text-4xl lg:text-5xl artikel-title">
                  {Artikellist[1].name}
                </div>
              </div>
            </div>
            <div
              key={2}
              className="w-[40vh] top-[15vh] lg:block hidden absolute right-0 z-1 h-[30vh]"
            >
              <img
                src={Artikellist[2].img}
                className="h-[30vh] w-[40vh] rounded-lg object-cover"
              />
              <div className="absolute card w-[40vh] h-1/2 bottom-0 Artikelimg"></div>
              <div className="absolute text-white bottom-2 left-2 text-2xl lg:text-4xl artikel-title">
                {Artikellist[2].name}
              </div>
            </div>
          </div>
        )} */}

        <div className="absolute bottom-[10vh] lg:bottom-2 flex flex-row">
          <button
            onClick={() => history.push("/Anmelden")}
            className="btn btn-primary w-40 text-white rounded-full normal-case	"
          >
            Anmelden
          </button>
          <button
            onClick={() => history.push("/Registrieren")}
            className="btn btn-primary ml-10 w-40 text-white rounded-full normal-case	"
          >
            Registrieren
          </button>
        </div>
      </div>
    </div>
  );
};

export default Startseite;
