import React from "react";

const Anleitungen = () => {
  return (
    <div className="h-full w-full overflow-y-scroll">
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10">
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-xl font-medium">
          Hast du ein Problem?
        </div>
        <div className="collapse-content">
          <p>Schreibe uns eine E-Mail unter spibi-online@iserv-gis.de</p>
          <p>oder Stell uns eine Frage unter dem Punkt Hilfe</p>
        </div>
      </div>
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10">
        <input type="checkbox" className="peer" />

        <div className="collapse-title text-xl font-medium">
          Was ist die Schülerzeitung?
        </div>
        <div className="collapse-content">
          <p>
            Die Schülerzeitung ist eine Zeitschrift, die von Schülern für
            Schüler erstellt wird. Sie dient dazu, Neuigkeiten und
            Veranstaltungen an der Schule zu verbreiten sowie Meinungen und
            Gedanken der Schüler auszudrücken.
          </p>
        </div>
      </div>
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10">
        <input type="checkbox" className="peer" />

        <div className="collapse-title text-xl font-medium">
          Wer kann an der Schülerzeitung teilnehmen?
        </div>
        <div className="collapse-content">
          <p>
            Jeder Schüler der Schule kann an der Schülerzeitung teilnehmen,
            unabhängig von Alter oder Klassenstufe.
          </p>
        </div>
      </div>
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10">
        <input type="checkbox" className="peer" />

        <div className="collapse-title text-xl font-medium">
          Was kann ich in der Schülerzeitung veröffentlichen?
        </div>
        <div className="collapse-content">
          <p>
            Du kannst Beiträge über Neuigkeiten und Veranstaltungen an der
            Schule veröffentlichen, sowie Meinungsartikel, Gedichte, Comics oder
            andere kreative Arbeiten.
          </p>
        </div>
      </div>
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10">
        <input type="checkbox" className="peer" />

        <div className="collapse-title text-xl font-medium">
          Was passiert, wenn mein Beitrag nicht veröffentlicht wird?
        </div>
        <div className="collapse-content">
          <p>
            Das Redaktionsteam der Schülerzeitung hat das Recht, Beiträge aus
            redaktionellen Gründen nicht zu veröffentlichen, z. B. weil sie
            unangemessen, beleidigend oder ungenau sind. In solchen Fällen wird
            das Redaktionsteam normalerweise mit dem Autor sprechen und nach
            Möglichkeit Feedback geben.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Anleitungen;
