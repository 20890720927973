import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Footer from "./footer";

const Datenschutz = (props) => {
  return (
    <div className="Überuns data-protection p-5 mb-12 mt-20">
      <div className="data-protection-text">
        <b>Datenschutzerklärung</b>
      </div>
      <div className="data-protection-text">
        Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
        Datenschutz hat einen besonders hohen Stellenwert für die
        Geschäftsleitung für folgenden Herausgeber dieser Website: SpiBi-Online.
        Eine Nutzung der Internetseiten der SpiBi-Online ist grundsätzlich ohne
        jede Angabe personenbezogener Daten möglich. Sofern eine betroffene
        Person besondere Services unseres Unternehmens über unsere Internetseite
        in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
        personenbezogener Daten erforderlich werden. Ist die Verarbeitung
        personenbezogener Daten erforderlich und besteht für eine solche
        Verarbeitung keine gesetzliche Grundlage, holen wir generell eine
        Einwilligung der betroffenen Person ein.
      </div>

      <div className="data-protection-text">
        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der
        Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person,
        erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in
        Übereinstimmung mit den für die SpiBi-Online geltenden
        landesspezifischen Datenschutzbestimmungen. Mittels dieser
        Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über
        Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten
        personenbezogenen Daten informieren. Ferner werden betroffene Personen
        mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte
        aufgeklärt.
      </div>

      <div className="data-protection-text">
        Die SpiBi-Online hat als für die Verarbeitung Verantwortlicher
        zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen
        möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten
        personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte
        Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein
        absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht
        es jeder betroffenen Person frei, personenbezogene Daten auch auf
        alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
      </div>

      <div className="data-protection-text">
        <b>1. Begriffsbestimmungen</b>
      </div>

      <div className="data-protection-text">
        Die Datenschutzerklärung der SpiBi-Online beruht auf den
        Begrifflichkeiten, die durch den Europäischen Richtlinien- und
        Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO)
        verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die
        Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
        lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir
        vorab die verwendeten Begrifflichkeiten erläutern.
      </div>

      <div className="data-protection-text">
        Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden
        Begriffe:
      </div>

      <div className="data-protection-text">
        <b>1) Personenbezogene Daten</b>
      </div>

      <div className="data-protection-text">
        Personenbezogene Daten sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person") beziehen. Als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
        besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
        genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
        Identität dieser natürlichen Person sind, identifiziert werden kann.
      </div>
      <div className="data-protection-text">
        <b>2) Betroffene Person</b>
      </div>
      <div className="data-protection-text">
        Betroffene Person ist jede identifizierte oder identifizierbare
        natürliche Person, deren personenbezogene Daten von dem für die
        Verarbeitung Verantwortlichen verarbeitet werden.
      </div>
      <div className="data-protection-text">
        <b>3) Verarbeitung</b>
      </div>
      <div className="data-protection-text">
        Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten, wie das Erheben, das Erfassen, die
        Organisation, das Ordnen, die Speicherung, die Anpassung oder
        Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
        durch Übermittlung, Verbreitung oder eine andere Form der
        Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
        das Löschen oder die Vernichtung.
      </div>
      <div className="data-protection-text">
        <b>4) Einschränkung der Verarbeitung</b>
      </div>
      <div className="data-protection-text">
        Einschränkung der Verarbeitung ist die Markierung gespeicherter
        personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
        einzuschränken.
      </div>
      <div className="data-protection-text">
        <b>5) Profiling</b>
      </div>
      <div className="data-protection-text">
        Profiling ist jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese personenbezogenen
        Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf
        eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte
        bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
        persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
        Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren
        oder vorherzusagen.
      </div>
      <div className="data-protection-text">
        <b>6) Pseudonymisierung</b>
      </div>
      <div className="data-protection-text">
        Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer
        Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung
        zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
        Person zugeordnet werden können, sofern diese zusätzlichen Informationen
        gesondert aufbewahrt werden und technischen und organisatorischen
        Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
        Daten nicht einer identifizierten oder identifizierbaren natürlichen
        Person zugewiesen werden.
      </div>
      <div className="data-protection-text">
        <b>7) Verantwortlicher oder für die Verarbeitung Verantwortlicher</b>
      </div>
      <div className="data-protection-text">
        Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
        natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten entscheidet. Sind die
        Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das
        Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche
        beziehungsweise können die bestimmten Kriterien seiner Benennung nach
        dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
      </div>
      <div className="data-protection-text">
        <b>8) Auftragsverarbeiter</b>
      </div>
      <div className="data-protection-text">
        Auftragsverarbeiter ist eine natürliche oder juristische Person,
        Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
        Auftrag des Verantwortlichen verarbeitet.
      </div>
      <div className="data-protection-text">
        <b>9) Empfänger</b>
      </div>
      <div className="data-protection-text">
        Empfänger ist eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt
        werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt
        oder nicht. Behörden, die im Rahmen eines bestimmten
        Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
        Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
        jedoch nicht als Empfänger.
      </div>
      <div className="data-protection-text">
        <b>10) Dritter</b>
      </div>
      <div className="data-protection-text">
        Dritter ist eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle außer der betroffenen Person, dem
        Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
        der unmittelbaren Verantwortung des Verantwortlichen oder des
        Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
        verarbeiten.
      </div>
      <div className="data-protection-text">
        <b>11) Einwilligung</b>
      </div>
      <div className="data-protection-text">
        Einwilligung ist jede von der betroffenen Person freiwillig für den
        bestimmten Fall in informierter Weise und unmissverständlich abgegebene
        Willensbekundung in Form einer Erklärung oder einer sonstigen
        eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
        verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
        personenbezogenen Daten einverstanden ist.
      </div>
      <div className="data-protection-text">
        2. Name und Anschrift des für die Verarbeitung Verantwortlichen
      </div>
      <div className="data-protection-text">
        Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in
        den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze
        und anderer Bestimmungen mit datenschutzrechtlichem Charakter, ist die:
      </div>
      <div className="data-protection-text">
        <b>Herausgeber: SpiBi-Online</b>
      </div>
      <div className="data-protection-text">
        Anschrift: Schlossplatz 13
        <br />
        38304 Wolfenbüttel
        <br />
        Germany
        <br />
        Tel.: + (49) 5331 92300
        <br />
        E-Mail: spibi-online@iserv-gis.de
        <br />
        Website: 10.99.3.23:3000
      </div>
      <br />
      <div className="data-protection-text">
        <b>3. Cookies</b>
      </div>
      <div className="data-protection-text">
        Die Internetseiten der SpiBi-Online verwenden Cookies. Cookies sind
        Textdateien, welche über einen Internetbrowser auf einem Computersystem
        abgelegt und gespeichert werden.
      </div>
      <div className="data-protection-text">
        Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
        enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige
        Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche
        Internetseiten und Server dem konkreten Internetbrowser zugeordnet
        werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es
        den besuchten Internetseiten und Servern, den individuellen Browser der
        betroffenen Person von anderen Internetbrowsern, die andere Cookies
        enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über
        die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
      </div>
      <div className="data-protection-text">
        Durch den Einsatz von Cookies kann die SpiBi-Online den Nutzern dieser
        Internetseite nutzerfreundlichere Services bereitstellen, die ohne die
        Cookie-Setzung nicht möglich wären.
      </div>
      <div className="data-protection-text">
        Mittels eines Cookies können die Informationen und Angebote auf unserer
        Internetseite im Sinne des Benutzers optimiert werden. Cookies
        ermöglichen uns, wie bereits erwähnt, die Benutzer unserer Internetseite
        wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die
        Verwendung unserer Internetseite zu erleichtern. Der Benutzer einer
        Internetseite, die Cookies verwendet, muss beispielsweise nicht bei
        jedem Besuch der Internetseite erneut seine Zugangsdaten eingeben, weil
        dies von der Internetseite und dem auf dem Computersystem des Benutzers
        abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie
        eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die
        Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein
        Cookie.
      </div>
      <div className="data-protection-text">
        Beim Aufruf unserer Website wird der Nutzer über die Verwendung von
        Cookies zu Analysezwecken informiert und seine Einwilligung zur
        Verarbeitung der in diesem Zusammenhang verwendeten personenbezogenen
        Daten eingeholt. In diesem Zusammenhang erfolgt auch ein Hinweis auf
        diese Datenschutzerklärung.
      </div>
      <div className="data-protection-text">
        Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
        Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
      </div>
      <div className="data-protection-text">
        Die betroffene Person kann die Setzung von Cookies durch unsere
        Internetseite jederzeit mittels einer entsprechenden Einstellung des
        genutzten Internetbrowsers verhindern und damit der Setzung von Cookies
        dauerhaft widersprechen. Ferner können bereits gesetzte Cookies
        jederzeit über einen Internetbrowser oder andere Softwareprogramme
        gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich.
        Deaktiviert die betroffene Person die Setzung von Cookies in dem
        genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen
        unserer Internetseite vollumfänglich nutzbar.
      </div>
      <div className="data-protection-text">
        <b>4. Erfassung von allgemeinen Daten und Informationen</b>
      </div>
      <div className="data-protection-text">
        Die Internetseite der SpiBi-Online erfasst mit jedem Aufruf der
        Internetseite durch eine betroffene Person oder ein automatisiertes
        System eine Reihe von allgemeinen Daten und Informationen. Diese
        allgemeinen Daten und Informationen werden in den Logfiles des Servers
        gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und
        Versionen, (2) das vom zugreifenden System verwendete Betriebssystem,
        (3) die Internetseite, von welcher ein zugreifendes System auf unsere
        Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten,
        welche über ein zugreifendes System auf unserer Internetseite
        angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die
        Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der
        Internet-Service-Provider des zugreifenden Systems und (8) sonstige
        ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
        Angriffen auf unsere informationstechnologischen Systeme dienen.
      </div>
      <div className="data-protection-text">
        Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die
        SpiBi-Online keine Rückschlüsse auf die betroffene Person. Diese
        Informationen werden vielmehr benötigt, um (1) die Inhalte unserer
        Internetseite korrekt auszuliefern, (2) die Inhalte unserer
        Internetseite sowie die Werbung für diese zu optimieren, (3) die
        dauerhafte Funktionsfähigkeit unserer informationstechnologischen
        Systeme und der Technik unserer Internetseite zu gewährleisten sowie (4)
        um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
        Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym
        erhobenen Daten und Informationen werden durch die SpiBi-Online daher
        einerseits statistisch und ferner mit dem Ziel ausgewertet, den
        Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen,
        um auch ein optimales Schutzniveau für die von uns verarbeiteten
        personenbezogenen Daten sicherzustellen. Die anonymen Daten der
        Server-Logfiles werden getrennt von allen durch eine betroffene Person
        angegebenen personenbezogenen Daten gespeichert.
      </div>
      <div className="data-protection-text">
        Daneben werden auch, soweit die ausdrückliche Erlaubnis der betreffenden
        Nutzer und unter Einhaltung der geltenden Datenschutzbestimmungen
        vorliegt, folgende persönliche Daten erfasst:
      </div>
      <div className="data-protection-text">Vor- und Nachnamen der Nutzer</div>
      <div className="data-protection-text">E-Mail-Adresse der Nutzer</div>
      <div className="data-protection-text">Persönlicher Benutzername</div>
      <div className="data-protection-text">App bezogene Daten</div>
      <div className="data-protection-text">
        Die Verarbeitung der personenbezogenen Daten erfolgt aufgrund unseres
        berechtigten Interesses zur Erfüllung unserer vertraglich vereinbarten
        Leistungen und zur Optimierung unseres Online-Angebotes.
      </div>
      <div className="data-protection-text">
        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
        machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch
        (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
        Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der
        Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind
        Rückschlüsse auf Ihre Person nicht möglich.Nicht hiervon betroffen sind
        die IP-Adressen des Nutzers oder andere Daten, die die Zuordnung der
        Daten zu einem Nutzer ermöglichen. Eine Speicherung dieser Daten
        zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht
        statt.
      </div>
      <div className="data-protection-text">
        <b>5. Abonnement unseres Newsletters</b>
      </div>
      <div className="data-protection-text">
        Auf der Internetseite der SpiBi-Online wird den Benutzern die
        Möglichkeit eingeräumt, den Newsletter unseres Unternehmens zu
        abonnieren. Welche personenbezogenen Daten bei der Bestellung des
        Newsletters an den für die Verarbeitung Verantwortlichen übermittelt
        werden, ergibt sich aus der hierzu verwendeten Eingabemaske.
      </div>
      <div className="data-protection-text">
        Die SpiBi-Online informiert ihre Kunden und Geschäftspartner in
        regelmäßigen Abständen im Wege eines Newsletters über Angebote des
        Unternehmens. Der Newsletter unseres Unternehmens kann von der
        betroffenen Person grundsätzlich nur dann empfangen werden, wenn (1) die
        betroffene Person über eine gültige E-Mail-Adresse verfügt und (2) die
        betroffene Person sich für den Newsletterversand registriert. An die von
        einer betroffenen Person erstmalig für den Newsletterversand
        eingetragene E-Mail-Adresse wird aus rechtlichen Gründen eine
        Bestätigungsmail im Double-Opt-In-Verfahren versendet. Diese
        Bestätigungsmail dient der Überprüfung, ob der Inhaber der
        E-Mail-Adresse als betroffene Person den Empfang des Newsletters
        autorisiert hat.
      </div>
      <div className="data-protection-text">
        Bei der Anmeldung zum Newsletter speichern wir ferner die vom
        Internet-Service-Provider (ISP) vergebene IP-Adresse des von der
        betroffenen Person zum Zeitpunkt der Anmeldung verwendeten
        Computersystems sowie das Datum und die Uhrzeit der Anmeldung. Die
        Erhebung dieser Daten ist erforderlich, um den(möglichen) Missbrauch der
        E-Mail-Adresse einer betroffenen Person zu einem späteren Zeitpunkt
        nachvollziehen zu können und dient deshalb der rechtlichen Absicherung
        des für die Verarbeitung Verantwortlichen.
      </div>
      <div className="data-protection-text">
        Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen
        Daten werden ausschließlich zum Versand unseres Newsletters verwendet.
        Ferner könnten Abonnenten des Newsletters per E-Mail informiert werden,
        sofern dies für den Betrieb des Newsletter-Dienstes oder eine
        diesbezügliche Registrierung erforderlich ist, wie dies im Falle von
        Änderungen am Newsletterangebot oder bei der Veränderung der technischen
        Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im
        Rahmen des Newsletter-Dienstes erhobenen personenbezogenen Daten an
        Dritte. Das Abonnement unseres Newsletters kann durch die betroffene
        Person jederzeit gekündigt werden. Die Einwilligung in die Speicherung
        personenbezogener Daten, die die betroffene Person uns für den
        Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum
        Zwecke des Widerrufs der Einwilligung findet sich in jedem Newsletter
        ein entsprechender Link. Ferner besteht die Möglichkeit, sich jederzeit
        auch direkt auf der Internetseite des für die Verarbeitung
        Verantwortlichen vom Newsletterversand abzumelden oder dies dem für die
        Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
      </div>
      <div className="data-protection-text">
        Teile unseres Newsletters können Werbematerial enthalten.
      </div>
      <div className="data-protection-text">
        <b>6. Newsletter-Tracking</b>
      </div>
      <div className="data-protection-text">
        Die Newsletter der SpiBi-Online enthalten sogenannte Zählpixel. Ein
        Zählpixel ist eine Miniaturgrafik, die in solche E-Mails eingebettet
        wird, welche im HTML-Format versendet werden, um eine
        Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen. Dadurch
        kann eine statistische Auswertung des Erfolges oder Misserfolges von
        Online-Marketing-Kampagnen durchgeführt werden. Anhand des eingebetteten
        Zählpixels kann die SpiBi-Online erkennen, ob und wann eine E-Mail von
        einer betroffenen Person geöffnet wurde und welche in der E-Mail
        befindlichen Links von der betroffenen Person aufgerufen wurden.
      </div>
      <div className="data-protection-text">
        Solche, über die in den Newslettern enthaltenen Zählpixel erhobenen
        personenbezogenen Daten, werden, von dem für die Verarbeitung
        Verantwortlichen, gespeichert und ausgewertet, um den Newsletterversand
        zu optimieren und den Inhalt zukünftiger Newsletter noch besser den
        Interessen der betroffenen Person anzupassen. Diese personenbezogenen
        Daten werden nicht an Dritte weitergegeben. Betroffene Personen sind
        jederzeit berechtigt, die diesbezügliche gesonderte, über das
        Double-Opt-In-Verfahren abgegebene Einwilligungserklärung zu widerrufen.
        Nach einem Widerruf werden diese personenbezogenen Daten von dem für die
        Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom Erhalt des
        Newsletters deutet die SpiBi-Online automatisch als Widerruf.
      </div>
      <div className="data-protection-text">
        <b>7. Registrierung auf unserer Internetseite</b>
      </div>
      <div className="data-protection-text">
        Die betroffene Person hat die Möglichkeit, sich auf der Internetseite
        des für die Verarbeitung Verantwortlichen unter Angabe von
        personenbezogenen Daten zu registrieren. Welche personenbezogenen Daten
        dabei an den für die Verarbeitung Verantwortlichen übermittelt werden,
        ergibt sich aus der jeweiligen Eingabemaske, die für die Registrierung
        verwendet wird. Die von der betroffenen Person eingegebenen
        personenbezogenen Daten werden ausschließlich für die interne Verwendung
        bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke
        erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann
        die Weitergabe an einen oder mehrere Auftragsverarbeiter, beispielsweise
        einen Paketdienstleister, veranlassen, der die personenbezogenen Daten
        ebenfalls ausschließlich für eine interne Verwendung, die dem für die
        Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.
      </div>
      <div className="data-protection-text">
        Durch eine Registrierung auf der Internetseite des für die Verarbeitung
        Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP) der
        betroffenen Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der
        Registrierung gespeichert. Die Speicherung dieser Daten erfolgt vor dem
        Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert
        werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene
        Straftaten aufzuklären. Insofern ist die Speicherung dieser Daten zur
        Absicherung des für die Verarbeitung Verantwortlichen erforderlich. Eine
        Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern
        keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der
        Strafverfolgung dient.
      </div>
      <div className="data-protection-text">
        Die Registrierung der betroffenen Person unter freiwilliger Angabe
        personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen
        dazu, der betroffenen Person Inhalte oder Leistungen anzubieten, die
        aufgrund der Natur der Sache nur registrierten Benutzern angeboten
        werden können. Registrierten Personen steht die Möglichkeit frei, die
        bei der Registrierung angegebenen personenbezogenen Daten jederzeit
        abzuändern oder vollständig aus dem Datenbestand des für die
        Verarbeitung Verantwortlichen löschen zu lassen.
      </div>
      <div className="data-protection-text">
        Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen
        Person jederzeit auf Anfrage Auskunft darüber, welche personenbezogenen
        Daten über die betroffene Person gespeichert sind. Ferner berichtigt
        oder löscht der für die Verarbeitung Verantwortliche personenbezogene
        Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der
        Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
        betroffenen Person in diesem Zusammenhang als Ansprechpartner zur
        Verfügung.
      </div>
      <div className="data-protection-text">
        <b>8. Kontaktmöglichkeit über die Internetseite</b>
      </div>
      <div className="data-protection-text">
        Die Internetseite der SpiBi-Online enthält aufgrund von gesetzlichen
        Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu
        unserem Unternehmen sowie eine unmittelbare Kommunikation mit uns
        ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten
        elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene
        Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem für
        die Verarbeitung Verantwortlichen aufnimmt, werden die von der
        betroffenen Person übermittelten personenbezogenen Daten automatisch
        gespeichert. Solche auf freiwilliger Basis von einer betroffenen Person
        an den für die Verarbeitung Verantwortlichen übermittelten
        personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
        Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine
        Weitergabe dieser personenbezogenen Daten an Dritte.
      </div>
      <div className="data-protection-text">
        <b>
          9. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
        </b>
      </div>
      <div className="data-protection-text">
        Der für die Verarbeitung Verantwortliche verarbeitet und speichert
        personenbezogene Daten der betroffenen Person nur für den Zeitraum, der
        zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies
        durch den europäischen Richtlinien- und Verordnungsgeber oder einen
        anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die
        Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
      </div>
      <div className="data-protection-text">
        Entfällt der Speicherungszweck oder läuft eine vom europäischen
        Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
        Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
        personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen
        Vorschriften gesperrt oder gelöscht.
      </div>
      <div className="data-protection-text">
        <b>10. Rechte der betroffenen Person</b>
      </div>
      <div className="data-protection-text">
        <b>1) Recht auf Bestätigung</b>
      </div>
      <div className="data-protection-text">
        Jede betroffene Person hat das vom europäischen Richtlinien- und
        Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
        Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
        betreffende personenbezogene Daten verarbeitet werden. Möchte eine
        betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie
        sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
        Verantwortlichen wenden.
      </div>
      <div className="data-protection-text">
        <b>2) Recht auf Auskunft</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche
        Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten
        und eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische
        Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über
        folgende Informationen zugestanden:
      </div>
      <div className="data-protection-text">
        <ul>
          <li>die Verarbeitungszwecke</li>
          <li>
            die Kategorien personenbezogener Daten, die verarbeitet werden
          </li>
          <li>
            die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen
          </li>
          <li>
            Falls möglich die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer
          </li>
          <li>
            das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung
          </li>
          <li>
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
          </li>
          <li>
            wenn die personenbezogenen Daten nicht bei der betroffenen Person
            erhoben werden: Alle verfügbaren Informationen über die Herkunft der
            Daten
          </li>
          <li>
            das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und —
            zumindest in diesen Fällen — aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene Person
          </li>
        </ul>
      </div>
      <div className="data-protection-text">
        Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
        personenbezogene Daten an ein Drittland oder an eine internationale
        Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der
        betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten
        Garantien im Zusammenhang mit der Übermittlung zu erhalten. Möchte eine
        betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie
        sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
        Verantwortlichen wenden.
      </div>
      <div className="data-protection-text">
        <b>3) Recht auf Berichtigung</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        die unverzügliche Berichtigung sie betreffender unrichtiger
        personenbezogener Daten zu verlangen. Ferner steht der betroffenen
        Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung,
        die Vervollständigung unvollständiger personenbezogener Daten — auch
        mittels einer ergänzenden Erklärung — zu verlangen. Möchte eine
        betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie
        sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
        Verantwortlichen wenden.
      </div>
      <div className="data-protection-text">
        <b>4) Recht auf Löschung (Recht auf Vergessen werden)</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        von dem Verantwortlichen zu verlangen, dass die sie betreffenden
        personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der
        folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich
        ist:
      </div>
      <div className="data-protection-text">
        <ul>
          <li>
            Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
            auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig
            sind.
          </li>
          <li>
            Die betroffene Person widerruft ihre Einwilligung, auf die sich die
            Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs.
            2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen
            Rechtsgrundlage für die Verarbeitung.
          </li>
          <li>
            Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch
            gegen die Verarbeitung ein, und es liegen keine vorrangigen
            berechtigten Gründe für die Verarbeitung vor, oder die betroffene
            Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die
            Verarbeitung ein.
          </li>
          <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
          <li>
            Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
          </li>
          <li>
            Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
            der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
          </li>
        </ul>
      </div>
      <div className="data-protection-text">
        Sofern einer der oben genannten Gründe zutrifft und eine betroffene
        Person die Löschung von personenbezogenen Daten, die bei der
        SpiBi-Online gespeichert sind, veranlassen möchte, kann sie sich hierzu
        jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
        wenden. Der Mitarbeiter der SpiBi-Online wird veranlassen, dass dem
        Löschverlangen unverzüglich nachgekommen wird.Wurden die
        personenbezogenen Daten von der SpiBi-Online öffentlich gemacht und ist
        unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur
        Löschung der personenbezogenen Daten verpflichtet, so trifft die
        SpiBi-Online unter Berücksichtigung der verfügbaren Technologie und der
        Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
        andere für die Datenverarbeitung Verantwortliche, welche die
        veröffentlichten personenbezogenen Daten verarbeiten, darüber in
        Kenntnis zu setzen, dass die betroffene Person von diesen anderen für
        die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu
        diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser
        personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht
        erforderlich ist. Der Mitarbeiter der SpiBi-Online wird im Einzelfall
        das Notwendige veranlassen.
      </div>
      <div className="data-protection-text">
        <b>5) Recht auf Einschränkung der Verarbeitung</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        von dem Verantwortlichen die Einschränkung der Verarbeitung zu
        verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
      </div>
      <div className="data-protection-text">
        Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
        Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen
        ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.
      </div>
      <div className="data-protection-text">
        Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
        Löschung der personenbezogenen Daten ab und verlangt stattdessen die
        Einschränkung der Nutzung der personenbezogenen Daten.
      </div>
      <div className="data-protection-text">
        Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke
        der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </div>
      <div className="data-protection-text">
        Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art.
        21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die
        berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen
        Person überwiegen.
      </div>
      <div className="data-protection-text">
        Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
        betroffene Person die Einschränkung von personenbezogenen Daten, die bei
        der SpiBi-Online gespeichert sind, verlangen möchte, kann sie sich
        hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
        Verantwortlichen wenden. Der Mitarbeiter der SpiBi-Online wird die
        Einschränkung der Verarbeitung veranlassen.
      </div>
      <div className="data-protection-text">
        <b>6) Recht auf Datenübertragbarkeit</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        die sie betreffenden personenbezogenen Daten, welche durch die
        betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem
        strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie
        hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne
        Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten
        bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der
        Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2
        Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b
        DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
        erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
        öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen
        wurde. Ferner hat die betroffene Person bei der Ausübung ihres Rechts
        auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, zu
        erwirken, dass die personenbezogenen Daten direkt von einem
        Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
        soweit dies technisch machbar ist und sofern hiervon nicht die Rechte
        und Freiheiten anderer Personen beeinträchtigt werden. Zur
        Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
        betroffene Person jederzeit an einen Mitarbeiter der SpiBi-Online
        wenden.
      </div>
      <div className="data-protection-text">
        <b>7) Recht auf Widerspruch</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
        gegen die Verarbeitung sie betreffender personenbezogener Daten, die
        aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO erfolgt,
        Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen
        gestütztes Profiling. Die SpiBi-Online verarbeitet die personenbezogenen
        Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können
        zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den
        Interessen, Rechten und Freiheiten der betroffenen Person überwiegen,
        oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen. Verarbeitet die SpiBi-Online
        personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
        betroffene Person das Recht, jederzeit Widerspruch gegen die
        Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
        einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
        Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
        gegenüber der SpiBi-Online der Verarbeitung für Zwecke der
        Direktwerbung, so wird die SpiBi-Online die personenbezogenen Daten
        nicht mehr für diese Zwecke verarbeiten. Zudem hat die betroffene Person
        das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
        gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei
        der SpiBi-Online zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1
        DSGVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
        Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden
        Aufgabe erforderlich. Zur Ausübung des Rechts auf Widerspruch kann sich
        die betroffene Person direkt jeden Mitarbeiter der SpiBi-Online oder
        einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner
        frei, im Zusammenhang mit der Nutzung von Diensten der
        Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
        Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
        technische Spezifikationen verwendet werden.
      </div>
      <div className="data-protection-text">
        <b>
          8) Automatisierte Entscheidungen im Einzelfall, einschließlich
          Profiling
        </b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        nicht einer ausschließlich auf einer automatisierten Verarbeitung —
        einschließlich Profiling — beruhenden Entscheidung unterworfen zu
        werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in
        ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung (1)
        nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der
        betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2)
        aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
        denen der Verantwortliche unterliegt, zulässig ist und diese
        Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und
        Freiheiten sowie der berechtigten Interessen der betroffenen Person
        enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen
        Person erfolgt. Ist die Entscheidung (1) für den Abschluss oder die
        Erfüllung eines Vertrags zwischen der betroffenen Person und dem
        Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher
        Einwilligung der betroffenen Person, trifft die SpiBi-Online angemessene
        Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten
        Interessen der betroffenen Person zu wahren, wozu mindestens das Recht
        auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
        auf Darlegung des eigenen Standpunkts und auf Anfechtung der
        Entscheidung gehört. Möchte die betroffene Person Rechte mit Bezug auf
        automatisierte Entscheidungen geltend machen, kann sie sich hierzu
        jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
        wenden.
      </div>
      <div className="data-protection-text">
        <b>9) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</b>
      </div>
      <div className="data-protection-text">
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
        eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu
        widerrufen. Möchte die betroffene Person ihr Recht auf Widerruf einer
        Einwilligung geltend machen, kann sie sich hierzu jederzeit an einen
        Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
      </div>
      <div className="data-protection-text">
        <b>11. Verwendung von Bibliotheken (Webfonts)</b>
      </div>
      <div className="data-protection-text">
        Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
        darzustellen, verwenden wir auf dieser Websitebibliotheken und
        Schriftbibliotheken wie z. B. Google Webfonts
        (https://www.google.com/webfonts/). Google Webfonts werden zur
        Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen.
        Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff
        unterbindet, werden Inhalte in einer Standardschrift angezeigt.
      </div>
      <div className="data-protection-text">
        Der Aufruf von Bibliotheken oder Schriftbibliotheken löst automatisch
        eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es
        theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
        welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten
        erheben.
      </div>
      <div className="data-protection-text">
        Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
        hier: https://www.google.com/policies/privacy/
      </div>
      <div className="data-protection-text">
        Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für
        Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen
        bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
        personenbezogener Daten zur Erfüllung eines Vertrags, dessen
        Vertragspartei die betroffene Person ist, erforderlich, wie dies
        beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
        Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
        Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I
        lit. b DSGVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur
        Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in
        Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt
        unser Unternehmen einer rechtlichen Verpflichtung durch welche eine
        Verarbeitung von personenbezogenen Daten erforderlich wird, wie
        beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die
        Verarbeitung auf Art. 6 I lit. c DSGVO. In seltenen Fällen könnte die
        Verarbeitung von personenbezogenen Daten erforderlich werden, um
        lebenswichtige Interessen der betroffenen Person oder einer anderen
        natürlichen
      </div>
      <div className="data-protection-text">
        <b>12. Rechtsgrundlage der Verarbeitung</b>
      </div>
      <div className="data-protection-text">
        Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher
        in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein
        Alter, seine Krankenkassendaten oder sonstige lebenswichtige
        Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
        weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I
        lit. d DSGVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6
        I lit. f DSGVO beruhen. Auf dieser Rechtsgrundlage basieren
        Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen
        erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten
        Interesses unseres Unternehmens oder eines Dritten erforderlich ist,
        sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
        nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
        deshalb gestattet, weil sie durch den europäischen Gesetzgeber besonders
        erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein
        berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene
        Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2
        DSGVO).
      </div>
      <div className="data-protection-text">
        <b>
          13. Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder einem Dritten verfolgt werden
        </b>
      </div>
      <div className="data-protection-text">
        Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f
        DSGVO ist unser berechtigtes Interesse die Durchführung unserer
        Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter
        und unserer Anteilseigner.
      </div>
      <div className="data-protection-text">
        <b>
          14. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
          personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
          Verpflichtung der betroffenen Person, die personenbezogenen Daten
          bereitzustellen; mögliche Folgen der Nichtbereitstellung
        </b>
      </div>
      <div className="data-protection-text">
        Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener
        Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften)
        oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum
        Vertragspartner) ergeben kann. Mitunter kann es zu einem Vertragsschluss
        erforderlich sein, dass eine betroffene Person uns personenbezogene
        Daten zur Verfügung stellt, die in der Folge durch uns verarbeitet
        werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns
        personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr
        einen Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen
        Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht
        geschlossen werden könnte. Vor einer Bereitstellung personenbezogener
        Daten durch den Betroffenen muss sich der Betroffene an einen unserer
        Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
        einzelfallbezogen darüber auf, ob die Bereitstellung der
        personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder
        für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
        besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen
        die Nichtbereitstellung der personenbezogenen Daten hätte.
      </div>
      <div className="data-protection-text">
        <b>15. Bestehen einer automatisierten Entscheidungsfindung</b>
      </div>
      <div className="data-protection-text">
        Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
        automatische Entscheidungsfindung oder ein Profiling.
      </div>
      <div className="data-protection-text">
        <b>16. Umfragen und Befragungen</b>
      </div>
      <div className="data-protection-text">
        Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
        "Befragungen") werden nicht anonym ausgewertet. Eine Verarbeitung
        personenbezogener Daten erfolgt nur insoweit, als dies zu Bereitstellung
        und technischen Durchführung der Umfragen erforderlich ist (z.B.
        Verarbeitung der IP-Adresse, um die Umfrage im Browser des Nutzers
        darzustellen oder mithilfe eines temporären Cookies (Session-Cookie)
        eine Wiederaufnahme der Umfrage zu ermöglichen) oder Nutzer eingewilligt
        haben.
      </div>
      <div className="data-protection-text">
        <b>Hinweise zu Rechtsgrundlagen:</b> Sofern wir die Teilnehmer um eine
        Einwilligung in die Verarbeitung ihrer Daten bitten, ist diese
        Rechtsgrundlage der Verarbeitung, ansonsten erfolgt die Verarbeitung der
        Daten der Teilnehmer auf Grundlage unserer berechtigten Interessen an
        der Durchführung einer objektiven Umfrage.
      </div>
      <div className="data-protection-text">
        <ul>
          <li>
            <b>Verarbeitete Datenarten:</b> Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <b>Betroffene Personen:</b> Kommunikationspartner.
          </li>
          <li>
            <b>Zwecke der Verarbeitung:</b> Kontaktanfragen und Kommunikation;
            Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
          <li>
            <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
            DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
      </div>
      <div className="data-protection-text">
        <b>17. Chatfunktionen</b>
      </div>
      <div className="data-protection-text">
        Wir bieten als Kommunikationsmöglichkeit Online-Chats an (auch als
        "Chat-Dienste" bezeichnet). Bei einem Chat handelt es sich um eine mit
        gewisser Zeitnähe geführte Online-Unterhaltung. Wenn Sie unsere
        Chat-Funktionen nutzen, können wir Ihre personenbezogenen Daten
        verarbeiten.
      </div>
      <div className="data-protection-text">
        Falls Sie unsere Chat-Dienste innerhalb einer Online-Plattform nutzen,
        wird zusätzlich Ihre Identifikationsnummer innerhalb der jeweiligen
        Plattform gespeichert. Wir können zudem Informationen darüber erheben,
        welche Nutzer wann mit unseren Chat-Diensten interagieren. Ferner
        speichern wir den Inhalt Ihrer Konversationen über die Chat-Dienste und
        protokollieren Registrierungs- und Einwilligungsvorgänge, um diese nach
        gesetzlichen Vorgaben nachweisen zu können.
      </div>
      <div className="data-protection-text">
        <b>Hinweise zum Nutzen der Chatfunktion:</b> Wir weisen Nutzer darauf
        hin, dass der jeweilige Plattformanbieter in Erfahrung bringen kann,
        dass und wann Nutzer mit unseren Chat-Diensten kommunizieren sowie
        technische Informationen zum verwendeten Gerät der Nutzer und je nach
        Einstellungen ihres Gerätes auch Standortinformationen (sogenannte
        Metadaten) zu Zwecken der Optimierung der jeweiligen Dienste und Zwecken
        der Sicherheit erheben kann.
      </div>
      <div className="data-protection-text">
        <b>Hinweise zu Rechtsgrundlagen:</b> Wir setzen die Chat-Dienste auf
        Grundlage einer Einwilligung ein, wenn wir zuvor eine Erlaubnis der
        Nutzer in die Verarbeitung ihrer Daten im Rahmen unserer Chat-Dienste
        eingeholt haben (dies gilt für die Fälle, in denen Nutzer um eine
        Einwilligung gebeten werden, z.B., damit ein Chatbot ihnen regelmäßig
        Nachrichten zusendet). Sofern wir Chat-Dienste einsetzen, um Anfragen
        der Nutzer zu unseren Leistungen oder unserem Unternehmen zu
        beantworten, erfolgt dies zur vertraglichen und vorvertraglichen
        Kommunikation. Im Übrigen setzen wir Chat-Dienste auf Grundlage unserer
        berechtigten Interessen an einer Optimierung der Chat-Dienste, ihrer
        Betriebswirtschaftlichkeit sowie einer Steigerung der positiven
        Nutzererfahrung ein.
      </div>
      <div className="data-protection-text">
        <b>Widerruf, Widerspruch und Löschung:</b> Sie können jederzeit eine
        erteilte Einwilligung widerrufen oder der Verarbeitung Ihrer Daten im
        Rahmen unserer Chat-Dienste widersprechen.
      </div>

      <div className="data-protection-text" style={{ marginBottom: "5vh" }}>
        <ul>
          <li>
            <b>Verarbeitete Datenarten:</b> Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <b>Betroffene Personen:</b> Kommunikationspartner.
          </li>
          <li>
            <b>Zwecke der Verarbeitung:</b> Kontaktanfragen und Kommunikation;
            Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <b>Zwecke der Verarbeitung:</b> Kontaktanfragen und Kommunikation;
            Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
            DSGVO); Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs.
            1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1
            lit. f. DSGVO).
          </li>
        </ul>
      </div>
      <div className="footer pl-5 pr-10 pt-10 pb-10 bg-[#e6e6e6] text-[#3e3e3e]">
        <div className="flex flex-col">
          <svg
            id="Ebene_1"
            className="w-20 h-20 mr-10 -rotate-12 fill-current"
            data-name="Ebene 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 49.5 139.1"
          >
            <title>turm</title>
            <path
              d="M617.75,448.55a3.64,3.64,0,0,0-1.9,2.9l-.1,19.6v19h-41l.2-19.2c.1-12.7,0-16.7,0-19.1a4.78,4.78,0,0,0-1.4-3.2c-2.8-1-2.8-2.7-2.8-2.7l6.9.1v-17.4c-1.2-.6-3.6,0-2.8-1a11.5,11.5,0,0,1,2.8-2.4c.1-7,0-16.6,0-18.1,0-1.2,0-2.9,1.1-2.9s1.1,1.4,1.1,2.9V410h4.7c2.6-.6,1.3-1.7,1.9-4.6,1.1-1.9.6,0,0-1.9-.1-1.1,0-4.7,0-6.8.6-.4.7-.1,1.1-.4.1-1.4.1-4.4.1-6.7,0-.9-1.1-1-2.2-1.4v-2.9c0-1,0-1.4.6-1.9,1.1,0,1.7-2.9,1.7-3.8a5.59,5.59,0,0,1,3.3-5.3c1.7-.9,3.4-1.9,3.9-2.8.7,0,.7-3,.7-4.8s0-2.9-.6-2.9a2.07,2.07,0,0,1-.2-3.6,2.06,2.06,0,0,0,.8-2.1c-.1-.7.1-1.1,0-1.4s-.6,1-1.6.5c-.6-.3-.7-.5-.5-.9a1.69,1.69,0,0,1,.7-.6.44.44,0,0,0,.4-.6c-.1-.2-.9-.3-1.2-.7a.68.68,0,0,1-.1-1c.3,0,.1.4.4.6a1.34,1.34,0,0,0,.8.2c.1-.1.2-.1,0-.2-.9-.3-.6-.5.5-.5h.7V353c.1-.1-1.1,0-.6-1a6.13,6.13,0,0,0,.8-.5c.2-.1,0-.5.3-.5s.1.3.3.5c.5.3,0,0,.9.5.6,1-.6.8-.6,1v.5c1.1,0-.3-.2,1-.2h1.7c.6.5-.6.5-1.1.5a3.76,3.76,0,0,1-1.1,0c-.6.6.8.6,1.7,0,.8.1.7.5.8.6s-1.3,1,.2.5c.5-.2-.1.9-.4,1.3-.2.1-.3.2-.4.2-.4-.3-.3,0,0,0h.1c.4.3.8.5-.2.7-.3.1-1.1-.5-1.8-.4-1.6.1-.3.2-.3,1.6,0,1,.2,1.8.8,2.1a2.08,2.08,0,0,1-.3,3.6c-.6,0-.6,1-.6,2.9s0,4.8.6,4.8c.6,1,2.3,1.9,3.9,2.8,2.8,1.5,3.4,3.5,3.4,5.3,0,1,.6,3.8,1.7,3.8.6.5.6,1,.6,2v2.8c-1.1.4-2.3.5-2.2,1.5v6.6c.3.3.6,0,1.1.4v6.7c-.6,1.9-1.1,0,0,1.9.8,3-.7,3.6,1.9,4.3h3.4v-2.9c0-1.4,0-2.9,1.1-2.9s1.1,2.2,1.1,2.9V425c.8.8,1.7,1,2.9,2.3l-3.1.8-.1,17.5,7,.1C620.05,446.55,617.75,448.55,617.75,448.55Z"
              transform="translate(-570.75 -350.95)"
            />
          </svg>
          <p>SpiBi-Online</p>
        </div>

        <div className="mr-10">
          <span className="footer-title">Legales</span>
          <Link to="/Datenschutz" className="link link-hover">
            Datenschutzerklärung
          </Link>
          <a href="https://gis-wf.de/Impressum" className="link link-hover">
            Impressum
          </a>
        </div>
        <div>
          <span className="footer-title">Folgt uns auf:</span>
          <div className="grid grid-flow-col gap-4">
            <a href="https://twitter.com/spibionline">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </a>
            <a href="https://youtube.de">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
            <a href="https://www.facebook.com/people/SPIBI-Gymnasium-im-Schloss/100063587140230/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </a>
            <a href="https://instagram.com/spibi.online?igshid=YmMyMTA2M2Y=">
              <svg
                width="24"
                height="24"
                className="w-[24px] h-[24px] fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 122.88"
              >
                <title>instagram-logo-black-and-white</title>
                <path d="M61.45,0C44.76,0,42.66.07,36.11.37A45.08,45.08,0,0,0,21.2,3.23a29.86,29.86,0,0,0-10.88,7.08,30.26,30.26,0,0,0-7.1,10.88A44.92,44.92,0,0,0,.37,36.11C.08,42.66,0,44.75,0,61.44S.07,80.21.37,86.77a45.08,45.08,0,0,0,2.86,14.91,30.12,30.12,0,0,0,7.08,10.88,30.13,30.13,0,0,0,10.88,7.1,45.17,45.17,0,0,0,14.92,2.85c6.55.3,8.64.37,25.33.37s18.77-.07,25.33-.37a45.17,45.17,0,0,0,14.92-2.85,31.54,31.54,0,0,0,18-18,45.6,45.6,0,0,0,2.86-14.91c.29-6.55.37-8.64.37-25.33s-.08-18.78-.37-25.33a45.66,45.66,0,0,0-2.86-14.92,30.1,30.1,0,0,0-7.09-10.88,29.77,29.77,0,0,0-10.88-7.08A45.14,45.14,0,0,0,86.76.37C80.2.07,78.12,0,61.43,0ZM55.93,11.07h5.52c16.4,0,18.34.06,24.82.36a34,34,0,0,1,11.41,2.11,19,19,0,0,1,7.06,4.6,19.16,19.16,0,0,1,4.6,7.06,34,34,0,0,1,2.11,11.41c.3,6.47.36,8.42.36,24.82s-.06,18.34-.36,24.82a33.89,33.89,0,0,1-2.11,11.4A20.35,20.35,0,0,1,97.68,109.3a33.64,33.64,0,0,1-11.41,2.12c-6.47.3-8.42.36-24.82.36s-18.35-.06-24.83-.36a34,34,0,0,1-11.41-2.12,19,19,0,0,1-7.07-4.59,19,19,0,0,1-4.59-7.06,34,34,0,0,1-2.12-11.41c-.29-6.48-.35-8.42-.35-24.83s.06-18.34.35-24.82a33.7,33.7,0,0,1,2.12-11.41,19,19,0,0,1,4.59-7.06,19.12,19.12,0,0,1,7.07-4.6A34.22,34.22,0,0,1,36.62,11.4c5.67-.25,7.86-.33,19.31-.34Zm38.31,10.2a7.38,7.38,0,1,0,7.38,7.37,7.37,7.37,0,0,0-7.38-7.37ZM61.45,29.89A31.55,31.55,0,1,0,93,61.44,31.56,31.56,0,0,0,61.45,29.89Zm0,11.07A20.48,20.48,0,1,1,41,61.44,20.48,20.48,0,0,1,61.45,41Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
