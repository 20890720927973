import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import crypto from "crypto-js";

export default function Artikel(props) {
  const [gelesen, setgelesen] = useState(props.gelesen);

  function sha256(text) {
    // Convert the text to an array of words
    const words = crypto.enc.Utf8.parse(text);

    // Calculate the SHA-256 hash of the words
    const hash = crypto.SHA256(words);

    // Return the hash as a hexadecimal string
    return hash.toString(crypto.enc.Hex);
  }

  let history = useHistory();
  let tags = "";
  if (props.className == "first-article") {
    tags = (
      <div className=" artikel-tags">
        {props.tags.map((obj, index) => {
          return "#" + obj;
        })}
      </div>
    );
  }
  if (props.artikeltext != null) {
    if (props.id === "12345") {
      return (
        <div
          className={"artikel card bg-base-100 shadow-md " + props.className}
        >
          <figure className="card">
            <img src={props.img} className="bg-cover" alt="Tietelbild" />
          </figure>
          <div className="absolute card w-full h-1/2 bottom-0 Artikelimg"></div>
          <div className="card-body absolute bottom-0 text-white">
            <h2
              className="card-title mt-1 artikel-title"
              style={{ wordBreak: "break-word" }}
            >
              {props.title}

              {(function () {
                let istgelesen = gelesen.find(
                  (element) => element.id === props.id
                );

                if (istgelesen == null) {
                  if (
                    new Date().getTime() <
                    new Date(
                      parseInt(props.id.substring(0, 8), 16) * 1000
                    ).getTime() +
                      1000 * 60 * 60 * 24 * 3
                  ) {
                    return (
                      <div className="w-16 badge badge-secondary">NEU</div>
                    );
                  }
                }
              })()}
            </h2>
            {/* <p className="text-xl">{props.text}</p> */}
            {/* <div className="card-actions justify-end">
            {props.tags.map((obj, index) => {
              if (index < 2) {
                return (
                  <div className="badge badge-outline" key={index}>
                    {obj}
                  </div>
                );
              }
            })}
          </div>*/}
          </div>
        </div>
      );
    } else {
      return (
        <Link
          to={"/Artikel/" + sha256(props.title.split(" ").join(""))}
          className="font-serif"
        >
          <div
            className={"artikel card bg-base-100 shadow-md " + props.className}
          >
            <figure className="card">
              <img src={props.img} className="bg-cover" alt="Titelbild" />
            </figure>
            <div className="absolute card w-full h-1/2 bottom-0 Artikelimg"></div>
            <div className="card-body absolute bottom-0 text-white">
              <h2
                className="card-title mt-1 artikel-title"
                style={{ wordBreak: "break-word" }}
              >
                {props.title}

                {/* {(function () {
                  let istgelesen = gelesen.find(
                    (element) => element.id === props.id
                  );

                  if (istgelesen == null) {
                    if (
                      new Date().getTime() <
                      new Date(props.time).getTime() + 1000 * 60 * 60 * 24 * 3
                    ) {
                      return (
                        <div className="w-28 badge badge-secondary text-base-100">
                          NEU
                        </div>
                      );
                    }
                  }
                })()} */}
              </h2>
              {/* <p className="text-xl">{props.text}</p> */}
              {/* <div className="card-actions justify-end">
                {props.tags.map((obj, index) => {
                  if (index < 2) {
                    return (
                      <div className="badge badge-outline" key={index}>
                        {obj}
                      </div>
                    );
                  }
                })}
              </div>*/}
            </div>
          </div>
        </Link>
      );
    }
  }
}
