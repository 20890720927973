import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const width = window.innerWidth;
const height = window.innerWidth * 1.41333;

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});

export default function SpibiPDFViewer(props) {
  let history = useHistory();
  const cookies = new Cookies();
  const [spibiPDF, setSpibiPDF] = useState([]);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getOldSpibiPDF", {
        userId: cookies.get("User_id"),
        id: props.id,
      })
      .then((response) => {
        if (response.data.pdf != null) {
          setSpibiPDF(response.data.pdf);
          let blob = base64toBlob(response.data.pdf);
          setUrl(URL.createObjectURL(blob));
          // window.open(URL.createObjectURL(blob));
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function base64toBlob(data) {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  }

  return (
    <div className="Spibi-PDF-Viewer" key={props.id}>
      <h3>{props.title}</h3>
      <p className="spibi-PDF-Viewer-year">von {props.year}</p>
      {url && (
        <div className="pdf-container">
          <Document file={url}>
            <HTMLFlipBook width={width} height={height}>
              <Page pageNumber={1} />
              <Page pageNumber={2} />
              <Page pageNumber={3} />
            </HTMLFlipBook>
          </Document>
        </div>
      )}
    </div>
  );
}
