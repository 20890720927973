import React from "react";

const Deleteuseruser = (props) => {
  return (
    <div className="bg-[#ebebeb] collapse collapse-arrow border border-base-300 bg-base-100 rounded-box m-10 ml-0 mr-0">
      <input type="checkbox" className="peer" />
      <div className="collapse-title text-xl font-medium z-1 m-0 p-0">
        <div className="deleteuseruser !m-0">
          <div className="deleteuseruser-name">{props.name}</div>
          <div className="form-control mr-8">
            <label className="label cursor-pointer">
              <span className="label-text mr-4">Darf Kommentare schreiben</span>
              <input
                type="checkbox"
                className="toggle z-20"
                defaultChecked={props.Komentareschreiben}
                onChange={() =>
                  props.handleKommentarberechtigungenändern(props._id)
                }
              />
            </label>
          </div>
          <button
            className="deleteuseruser-btn z-20"
            onClick={() => props.handlelöschen(props._id)}
          >
            Löschen
          </button>
        </div>
      </div>
      <div className="collapse-content">
        <p>Userid: {props._id}</p>
        <p>Email: {props.email}</p>
        <p>Registertime: {new Date(props.registertime).toLocaleString()}</p>
        <p>Activated: {props.activated ? "true" : "false"}</p>
        <p>Key: {props.userkey}</p>
        <p>Theme: {props.colormode}</p>
      </div>
    </div>
  );
};

export default Deleteuseruser;
