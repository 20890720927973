import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import ArtikelPreview from "../artikelPreview.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Artikelpagebearbeiten(props) {
  const [title, settitle] = useState(props.title);
  const [img, setimg] = useState(null);
  const img2 = useRef(null);
  const [titletext, settitletext] = useState(props.titletext);
  const [textarray, settextarray] = useState(props.text);
  const [update, setupdate] = useState(null);
  const [tags, settags] = useState(props.tags);
  const [anonym, setanonym] = useState(false);
  const [time, settime] = useState(props.time);
  const imgref = useRef(null);
  const weitereAutoren = useRef(null);
  const [weitereAutorenstart, setweitereAutorenstart] = useState(
    props.weitereAutoren
  );
  const [isExplicit, setisExplicit] = useState(props.isExplicit);
  const [isIserv, setisIserv] = useState(props.isIserv);
  const [curKategorie, setKategorie] = useState(props.kategorie);
  const [datevalue, dateonChange] = useState(new Date(props.time));

  const Kategorie = useRef(null);

  const curtag = useRef(null);
  const curtime = useRef(null);

  const cookies = new Cookies();
  let history = useHistory();

  function moveup(index) {
    let array = textarray;
    let temp = array[index];
    array[index] = array[index - 1];
    array[index - 1] = temp;
    settextarray(array);
    setupdate(update + 1);
  }

  function movedown(index) {
    let array = textarray;
    let temp = array[index];
    array[index] = array[index + 1];
    array[index + 1] = temp;
    settextarray(array);
    setupdate(update + 1);
  }

  useEffect(() => {
    settitle(props.title);
    settitletext(props.titletext);
    settags(props.tags);
    setimg(props.img);
    settime(props.time);
    setKategorie(props.kategorie);
    setisExplicit(props.isExplicit);
    setisIserv(props.isIserv);
    imgref.current.innerHTML = "Titelbild hochladen: Some Image";
  }, [
    props.title,
    props.titletext,
    props.tags,
    props.text,
    props.img,
    props.isExplicit,
    props.isIserv,
  ]);

  function addimg(e) {
    settextarray([
      ...textarray,
      { type: "img", text: "", title: "", source: "", caption: "" },
    ]);
  }

  function addtext(e) {
    settextarray([...textarray, { type: "text", text: "" }]);
  }

  function addLink(e) {
    settextarray([...textarray, { type: "link", link: "", text: "" }]);
  }

  function addVideo(e) {
    settextarray([...textarray, { type: "video", link: "" }]);
  }

  function addAudio(e) {
    settextarray([...textarray, { type: "audio", text: "", title: "" }]);
  }

  function addHeadline(e) {
    settextarray([...textarray, { type: "headline", text: "" }]);
  }

  function handleheadlinechange(e) {
    let array = textarray;
    array[e.target.dataset.index].text = e.target.value;
    settextarray(array);
  }

  function handlelinkchange(e) {
    let array = textarray;
    array[e.target.dataset.index].link = e.target.value;
    settextarray(array);
  }

  function handleImgSource(e) {
    let array = textarray;
    array[e.target.dataset.index].source = e.target.value;
    setupdate(10);
    settextarray(array);
    settextarray([...textarray]);
  }

  function handlelinknamechange(e) {
    let array = textarray;
    array[e.target.dataset.index].text = e.target.value;
    settextarray(array);
  }

  function handlevideolinkchange(e) {
    let array = textarray;
    array[e.target.dataset.index].link = e.target.value;
    settextarray(array);
  }

  function handletextchange(e) {
    let array = textarray;
    array[e.target.dataset.index].text = e.target.value;
    settextarray(array);
    settextarray([...textarray]);
  }

  function handleReloading() {
    settextarray([...textarray]);
  }

  function handelimgchange(e) {
    let array = textarray;
    let index = e.target.dataset.index;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        array[index].text = upload.target.result;
        array[index].title = e.target.value;
        setupdate(10);
      } else {
        props.addnotification("Diese Datei ist zu groß #027");
        e.target.value = "";
      }
    };
    reader.readAsDataURL(file);
  }

  function handelaudiochange(e) {
    let array = textarray;
    let index = e.target.dataset.index;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        array[index].text = upload.target.result;
        console.log(array);
        array[index].title = e.target.value;
        setupdate(10);
        settextarray(array);
        settextarray([...textarray]);
      } else {
        props.addnotification("Diese Datei ist zu groß #028");
        e.target.value = "";
      }
    };
    reader.readAsDataURL(file);
  }

  function handlelöschen(e) {
    if (window.confirm("Möchtest du diesen Text / Bild wirklich löschen")) {
      let array = textarray;
      array.splice(e.target.dataset.index, 1);
      settextarray(array);
      setupdate(update + 1);
    }
  }

  function handletitle(e) {
    settitle(e.target.value);
  }

  function handleanonym(e) {
    setanonym(!anonym);
  }

  function handleisExplicit(e) {
    setisExplicit(!isExplicit);
  }

  function handleisIserv(e) {
    setisIserv(!isIserv);
  }

  function handleimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        setimg(upload.target.result);
        imgref.current.innerHTML = "Titelbild hochladen: " + img2.current.value;
      } else {
        props.addnotification("Diese Datei ist zu groß #029");
        img2.current.value = "";
      }
    };
    reader.readAsDataURL(file);
  }

  function handletitletext(e) {
    settitletext(e.target.value);
  }

  function handleImgTitle(e) {
    let array = textarray;
    array[e.target.dataset.index].caption = e.target.value;
    setupdate(10);
    settextarray(array);
    settextarray([...textarray]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (
      title === null ||
      titletext === null ||
      textarray[0].text.length < 1 ||
      img === null ||
      tags === null
    ) {
      props.addnotification("Du hast vergessen etwas einzutragen");
      return;
    } else {
      axios
        .post("https://spibi-online.de/bearbeitevorArtikel", {
          userid: cookies.get("User_id"),
          id: props.id,
          img: img,
          title: title,
          text: titletext,
          artikeltext: textarray,
          tags: curtag.current.value.split(" "),
          anonym: anonym,
          time: new Date(datevalue).getTime(),
          isExplicit: isExplicit,
          isIserv: isIserv,
          Kategorie: Kategorie.current.value,
          weitereAutoren: weitereAutoren.current.value,
        })
        .then((response) => {
          if (response.data.msg != null) {
            props.addnotification(response.data.msg);
          } else {
            props.addnotification("Artikel wurde bearbeitet!", "green");
          }
        })
        .catch((error) => {
          console.log(error, "Das hat nicht geklappt #378");
        });
    }
  }

  function handleLöschen(e) {
    e.preventDefault();
    if (window.confirm("Möchtest du diesen Artikel wirklich löschen?")) {
      axios
        .post("https://spibi-online.de/deleatevorArtikel", {
          id: props.id,
          userid: cookies.get("User_id"),
        })
        .then((response) => {
          if (response.data == true) {
            history.replace("/Konto");
          } else {
            props.addnotification("Etwas ist schief gelaufen #603");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      window.location.reload();
    }
  }

  return (
    <div className="Artikelerstellen-wrapper flex flex-row w-full text-primary-content only-desktop">
      <div className="grid flex-grow bg-base-200 w-[100vw] sm:w-[50vw] flex flex-row border-r-2 border-solid border-neutral overflow-y-scroll">
        <h1 className="text-2xl pl-10">
          <b>So erstellst du einen Artikel:</b>
        </h1>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>1.</b> Hier dein Titel:
            </span>
          </label>
          <input
            maxLength={34}
            type="text"
            name="title"
            placeholder="Der Notenschlüssel"
            className="input input-bordered w-full w-[40vw]"
            onChange={handletitle}
            defaultValue={title}
          />
        </div>
        <div className="form-control pl-10 relative">
          <label className="label">
            <span className="label-text">
              <b>2.</b> Wähle ein Titelbild aus:
            </span>
          </label>
          <button
            className="btn btn-wide pointer-events-none cursor-pointer absolute bottom-0 z-3 bg-base-100 border-base-200 text-primary-content w-[40vw]"
            ref={imgref}
          >
            Titelbild hochladen
          </button>
          <input
            ref={img2}
            type="file"
            name="file"
            className="h-11 rounded-lg z-2 cursor-pointer w-[40vw] opacity-0"
            id="file"
            onChange={handleimg}
            encType="multipart/form-data"
            required
          />
        </div>
        <p className="pl-10">
          Das Bild muss im Querformat sein und ungefähr ein Seitenverhältnis von
          4:3 haben.
        </p>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>3.</b> Wer hat das Titelbild gemacht?
            </span>
          </label>
          <input
            type="text"
            name="titletext"
            placeholder="Von Max Mustermann"
            className="input input-bordered w-full w-[40vw]"
            maxLength="50"
            defaultValue={titletext}
            onChange={handletitletext}
          />
        </div>
        <label maxLength={200} htmlFor="titletext"></label>

        <p className="pl-10">Maximal 8 Wörter</p>
        <label className="label pl-10">
          <span className="label-text">
            <b>4.</b> Artikel:
          </span>
        </label>
        {textarray &&
          textarray.map((obj, index) => {
            if (obj.type === "img") {
              return (
                <div key={index} className="relative ml-10 w-[40vw]">
                  <div className="form-control relative mt-5">
                    <button className="text-primary-content btn btn-wide pointer-events-none absolute w-80 bottom-0 z-3 rounded-b-none bg-base-100 border-base-100 w-[40vw]">
                      {obj.title == "" && <p>Bild Hochladen</p>}
                      {obj.title != "" && <p>Bild Hochladen: {obj.title}</p>}
                    </button>
                    <input
                      type="file"
                      name="file"
                      className="h-11 rounded-lg w-80 z-2 cursor-pointer rounded-b-none"
                      id="file"
                      data-index={index}
                      onChange={handelimgchange}
                      encType="multipart/form-data"
                      defaultValue=""
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      type="text"
                      name="img_title"
                      data-index={index}
                      placeholder="Untertitel"
                      className="input rounded-t-none rounded-bl-none border-base-100 focus:outline-none w-[40vw]"
                      onChange={handleImgTitle}
                      defaultValue={obj.caption}
                    />
                  </div>
                  <div className="form-control">
                    <input
                      type="text"
                      name="img_title"
                      data-index={index}
                      placeholder="Wer hat das Bild gemacht?"
                      className="input w-full rounded-t-none rounded-bl-none rounded-br-none border-base-100 focus:outline-none w-[40vw]"
                      onChange={handleImgSource}
                      defaultValue={obj.source}
                    />
                  </div>
                  <button
                    className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    data-index={index}
                    onClick={handlelöschen}
                  >
                    Löschen
                  </button>
                  <button
                    className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    data-index={index}
                    onClick={() => moveup(index)}
                  >
                    ↑
                  </button>
                  <button
                    className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    data-index={index}
                    onClick={() => movedown(index)}
                  >
                    ↓
                  </button>
                </div>
              );
            } else {
              if (obj.type === "text") {
                return (
                  <div key={index} className="relative ml-10 w-[40vw]">
                    <div className="form-control mt-5">
                      <textarea
                        name="text"
                        className="textarea textarea-bordered h-60 w-80 rounded-bl-none mb-0 focus:outline-none focus:bg-base-100 w-[40vw]"
                        placeholder="Ein Notenschlüssel gibt immer an wo ein bestimmter Ton auf den Notenlinien liegt. Der Violinschlüssel zeigt uns, dass der Ton „g“ auf der 2. Linie von unten liegt. Der Bass-Schlüssel gibt an, dass der Ton „f“ auf der 2. Linie (von oben) liegt."
                        data-index={index}
                        onChange={handletextchange}
                        value={obj.text}
                        required
                      ></textarea>
                    </div>

                    <button
                      data-index={index}
                      onClick={handlelöschen}
                      className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    >
                      Löschen
                    </button>
                    <button
                      className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => moveup(index)}
                    >
                      ↑
                    </button>
                    <button
                      className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => movedown(index)}
                    >
                      ↓
                    </button>
                  </div>
                );
              } else if (obj.type === "link") {
                return (
                  <div key={index} className="relative ml-10 w-[40vw]">
                    <div className="form-control mt-5">
                      <input
                        type="text"
                        name="Link"
                        placeholder="Den link welchen du einbauen möchtest"
                        className="input rounded-b-none border-base-100 focus:outline-none w-[40vw]"
                        maxLength="200"
                        data-index={index}
                        onChange={handlelinkchange}
                        defaultValue={obj.link}
                      />
                    </div>
                    <div className="form-control">
                      <input
                        type="text"
                        name="text"
                        placeholder="Was stat dem Link dastehen soll"
                        className="input rounded-t-none rounded-bl-none border-base-100 focus:outline-none w-[40vw]"
                        maxLength="50"
                        data-index={index}
                        defaultValue={obj.text}
                        onChange={handlelinknamechange}
                      />
                    </div>

                    <button
                      data-index={index}
                      onClick={handlelöschen}
                      className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    >
                      Löschen
                    </button>
                    <button
                      className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => moveup(index)}
                    >
                      ↑
                    </button>
                    <button
                      className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => movedown(index)}
                    >
                      ↓
                    </button>
                  </div>
                );
              } else if (obj.type === "audio") {
                return (
                  <div key={index} className="relative ml-10 w-[40vw]">
                    <div className="form-control relative mt-5">
                      <button className="text-primary-content btn btn-wide pointer-events-none absolute w-80 bottom-0 z-3 rounded-b-none bg-base-100 border-base-100 w-[40vw]">
                        {obj.title == "" && <p>Bild Hochladen</p>}
                        {obj.title != "" && <p>Bild Hochladen: {obj.title}</p>}
                      </button>
                      <input
                        type="file"
                        name="file"
                        className="h-11 rounded-lg w-80 z-2 cursor-pointer rounded-b-none"
                        id="file"
                        data-index={index}
                        onChange={handelaudiochange}
                        encType="multipart/form-data"
                        defaultValue=""
                        required
                      />
                    </div>
                    <button
                      className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={handlelöschen}
                    >
                      Löschen
                    </button>
                    <button
                      className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => moveup(index)}
                    >
                      ↑
                    </button>
                    <button
                      className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => movedown(index)}
                    >
                      ↓
                    </button>
                  </div>
                );
              } else if (obj.type === "headline") {
                return (
                  <div key={index} className="relative ml-10 w-[40vw]">
                    <div className="form-control mt-5">
                      <input
                        type="text"
                        name="Link"
                        placeholder="Link zum Video"
                        className="input rounded-bl-none rounded-br-none border-base-100 focus:outline-none w-[40vw]"
                        maxLength="200"
                        data-index={index}
                        onChange={handleheadlinechange}
                      />
                    </div>
                    <button
                      data-index={index}
                      onClick={handlelöschen}
                      className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    >
                      Löschen
                    </button>
                    <button
                      className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => moveup(index)}
                    >
                      ↑
                    </button>
                    <button
                      className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => movedown(index)}
                    >
                      ↓
                    </button>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="relative ml-10 w-[40vw]">
                    <div className="form-control mt-5">
                      <input
                        type="text"
                        name="Link"
                        placeholder="Link zum Video"
                        className="input rounded-bl-none border-base-100 focus:outline-none w-[40vw]"
                        maxLength="200"
                        data-index={index}
                        onChange={handlevideolinkchange}
                        defaultValue={obj.link}
                      />
                    </div>

                    <button
                      data-index={index}
                      onClick={handlelöschen}
                      className="btn z-3 rounded-t-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                    >
                      Löschen
                    </button>
                    <button
                      className="btn z-3 absolute right-0 w-12 rounded-t-none rounded-bl-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => moveup(index)}
                    >
                      ↑
                    </button>
                    <button
                      className="btn z-3 absolute w-12 right-12 rounded-t-none rounded-br-none bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
                      data-index={index}
                      onClick={() => movedown(index)}
                    >
                      ↓
                    </button>
                  </div>
                );
              }
            }
          })}
        <div className="add-buttons mt-5 ml-10 text-primary-content w-[40vw]">
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addtext}
          >
            Absatz einfügen
          </button>
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addHeadline}
          >
            Überschrift einfügen
          </button>
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addimg}
          >
            Bild einfügen
          </button>
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addLink}
          >
            Link einfügen
          </button>
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addVideo}
          >
            Video einfügen
          </button>
          <button
            className="btn btm-wide mr-4 bg-base-100 border-base-100 cursor-pointer hover:bg-base-100 text-primary-content"
            onClick={addAudio}
          >
            Audioclip einfügen
          </button>
        </div>
        {/* Tags */}
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>5.</b> Füge Tags hinzu:
            </span>
          </label>
        </div>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              Zwischen einzelnen tags ein lehrzeichen setzen
            </span>
          </label>
        </div>

        <div className="bg-base-100 rounded w-80 ml-10">
          <input
            placeholder="Geschichte"
            type="text"
            className="bg-base-100 pl-2 h-10 rounded focus-visible:border-base-100 focus:outline-none w-[40vw]"
            ref={curtag}
            defaultValue={tags}
          />
        </div>
        {/* Uploaddatum */}
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>6.</b> Ändere das Uploaddatum:
            </span>
          </label>
        </div>

        <div className="bg-base-100 rounded w-80 ml-10">
          <DatePicker
            selected={datevalue}
            onChange={(date) => dateonChange(date)}
          />
        </div>

        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>7.</b> Lege fest ob du anonym bleiben möchtest:
            </span>
          </label>
          <input
            type="checkbox"
            className="toggle"
            checked={anonym}
            onChange={handleanonym}
          />
        </div>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>8.</b> Lege fest ob der Artikel ein Banner bekommen soll:
            </span>
          </label>
          <input
            type="checkbox"
            className="toggle"
            checked={isExplicit}
            onChange={handleisExplicit}
          />
        </div>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>9.</b> Lege fest ob der Artikel für nicht Iserv Accounts
              gesperrt sein soll:
            </span>
          </label>
          <input
            type="checkbox"
            className="toggle"
            checked={isIserv}
            onChange={handleisIserv}
          />
        </div>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>10.</b> Lege fest zu welcher Kategorie dieser Artikel zählen
              soll (Achtung Aktuelle Kategorie wird nicht angezeigt):
            </span>
          </label>
          <select
            ref={Kategorie}
            className="select select-bordered w-full max-w-xs"
          >
            <option disabled selected>
              {curKategorie && curKategorie}
              {curKategorie === undefined && "Welche Kategorie?"}
            </option>
            <option>Giswf</option>
            <option>Gisinside</option>
            <option>Lehrersprüche</option>
            <option>Schülersprüche</option>
            <option>Homestorys</option>
            <option>SRV</option>
          </select>
        </div>
        <div className="form-control pl-10">
          <label className="label">
            <span className="label-text">
              <b>11.</b> Füge optional weiter Autoren hinzu (String):
            </span>
          </label>
        </div>
        <div className="bg-base-100 rounded w-80 ml-10">
          <input
            placeholder="Autoren"
            type="text"
            className="bg-base-100 pl-2 h-10 rounded focus-visible:border-base-100 focus:outline-none w-[40vw]"
            ref={weitereAutoren}
            defaultValue={weitereAutorenstart}
          />
        </div>
        <br />
        <br />
        <button
          onClick={handleSubmit}
          className="btn btn-primary btn-block text-black ml-10 w-60"
        >
          Speichern
        </button>
        <button
          onClick={handleLöschen}
          className="btn btn-primary btn-block text-black ml-10 w-60"
        >
          Löschen
        </button>
        <div className="h-10 bg-transparent"></div>
      </div>
      <div className="Artikelpreviw-wrapper grid flex-grow bg-base-300 only-dektop w-[50vw]">
        <ArtikelPreview
          img={img}
          title={title}
          text={textarray}
          autor={props.username}
          titletext={titletext}
          anonym={anonym}
        />
        <button
          className="only-desktop btn btn-primary absolute w-20 top-4 text-black right-[2vw]"
          onClick={handleReloading}
        >
          Neu Laden
        </button>
      </div>
    </div>
  );
}
