import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import crypto from "crypto-js";

const Zufaellig = (props) => {
  function sha256(text) {
    // Convert the text to an array of words
    const words = crypto.enc.Utf8.parse(text);

    // Calculate the SHA-256 hash of the words
    const hash = crypto.SHA256(words);

    // Return the hash as a hexadecimal string
    return hash.toString(crypto.enc.Hex);
  }

  console.log(props);

  return (
    <div>
      {props.Artikels.length > 0 && (
        <Redirect
          to={
            "/Artikel/" +
            sha256(
              props.Artikels[
                Math.round(Math.random() * props.Artikels.length)
              ].name
                .split(" ")
                .join("")
            )
          }
        />
      )}
    </div>
  );
};

export default Zufaellig;
