import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Banner from "./../banner";

const Dealeatebanner = (props) => {
  const [banners, setbanners] = useState(props.banners);
  const cookies = new Cookies();
  let history = useHistory();
  function dealeatebanner(id) {
    axios
      .post("https://spibi-online.de/removebanner", {
        userid: cookies.get("User_id"),
        bannerid: id,
      })
      .then((response) => {
        if (response.data.msg) {
          props.addnotification(response.data.msg);
        } else {
          setbanners(response.data.banners);
          props.updatebanner(response.data.banners);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }

  useEffect(() => {
    setbanners(banners);
  }, [props.banners]);

  return (
    <div className="Dealeatebanner">
      {banners.map((obj, index) => {
        return (
          <div key={index}>
            <Banner
              svg={obj.svg}
              link={obj.link}
              colors={props.colors}
              colormode={props.colormode}
            />
            <button
              onClick={() => dealeatebanner(obj._id)}
              className="Dealeatebanner-btn"
            >
              Löschen
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Dealeatebanner;
