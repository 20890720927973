import React, { useRef } from "react";

export default function Fragenbearbeitenfrage(props) {
  const frage = useRef(null);
  const mode = useRef(null);

  return (
    <div className="Fragenbearbeitenfrage" key={props.key2}>
      <input
        className="Fragenbearbeitenfrage-frage"
        ref={frage}
        defaultValue={props.frage.questions}
      />
      <div className="Fragenbearbeitenfrage-antworten">
        {props.mode == "Question" &&
          props.frage.antworten.map((antwort, index) => {
            return (
              <div className="Fragenbearbeitenfrage-antworten-antwort">
                <div className="Fragenbearbeitenfrage-antworten-antwort-name">
                  {antwort.name}
                </div>
                <div className="Fragenbearbeitenfrage-antworten-antwort-text">
                  {antwort.text}
                </div>
                <button
                  className="Fragenbearbeitenfrage-antworten-antwort-loeschen"
                  onClick={() =>
                    props.handleloeschen(
                      props.frage._id,
                      antwort.text,
                      antwort.id
                    )
                  }
                >
                  Löschen
                </button>
              </div>
            );
          })}
      </div>
      <select
        className="Frageerstellen-input"
        ref={mode}
        defaultValue={props.mode}
      >
        <option value="Pull">Pull</option>
        <option value="Question">Question</option>
        <option value="Multiple">Multiple</option>
      </select>
      <button
        className="Fragenbearbeitenfrage-save"
        onClick={() =>
          props.handlesave(
            props.frage._id,
            frage.current.value,
            mode.current.value
          )
        }
      >
        Save
      </button>
      <button
        className="Fragenbearbeitenfrage-deleat"
        onClick={() => props.deleat(props.frage._id)}
      >
        Löschen
      </button>
    </div>
  );
}
