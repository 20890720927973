import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const Dealeatebanner = (props) => {
  const [event, setevent] = useState(props.Event);
  const cookies = new Cookies();
  let history = useHistory();
  function dealeateevent(id) {
    axios
      .post("https://spibi-online.de/removeevent", {
        userid: cookies.get("User_id"),
        eventid: id,
      })
      .then((response) => {
        if (response.data.msg) {
          props.addnotification(response.data.msg);
        } else {
          setevent(response.data.banners);
          props.updateevents(response.data.events);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }

  useEffect(() => {
    setevent(props.Event);
  }, [props.Event]);

  return (
    <div className="Dealeatebanner">
      {event &&
        event.map((obj, index) => {
          return (
            <div key={index}>
              <div key={index} className="Events-Event">
                <div className="Events-Event-picture">
                  <img src={obj.picture} className="Events-Event-picture-img" />
                </div>
                <div className="Events-Event-title">{obj.title}</div>
                <div className="Events-Event-date">
                  {obj.date.split("-").reverse().join(".")}
                </div>
                <div className="Events-Event-text">{obj.text}</div>
              </div>
              <button
                onClick={() => dealeateevent(obj._id)}
                className="Dealeatebanner-btn"
              >
                Löschen
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default Dealeatebanner;
