import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Changepassworduser from "./Changepassworduser";

const Deleteusers = (props) => {
  const [users, setusers] = useState(null);
  const [Search, setSearch] = useState("");
  const [update, setupdate] = useState(0);

  const cookies = new Cookies();
  let history = useHistory();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getalluser", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          setusers(response.data.users);
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }, []);

  function updatesearch(e) {
    setSearch(e.target.value);
  }

  function handlepasswordlöschen(id, password) {
    axios
      .post("https://spibi-online.de/changepasswordasadmin", {
        userid: cookies.get("User_id"),
        id: id,
        password: password,
      })
      .then((response) => {
        if (response.data.msg == null) {
          props.addnotification("Fertig!");
          setupdate(update + 1);
          setusers(response.data.users);
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }

  return (
    <div className="deleteusers mt-16">
      <input
        type="text"
        placeholder="Nutzer Suche"
        className="Plusfreischalten-search"
        onChange={updatesearch}
      />
      {users != null &&
        users.map((obj, index) => {
          if (obj.name.toLowerCase().includes(Search.toLowerCase())) {
            return (
              <div key={index}>
                <Changepassworduser
                  name={obj.name}
                  _id={obj._id}
                  handlepasswordlöschen={handlepasswordlöschen}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

export default Deleteusers;
