import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Replys from "./kommentarreply";
import Spibi_logo from "./img/Logo.svg";

export default function Kommentare(props) {
  const [kommentare, setkommentare] = useState(props.kommentare);
  const inputKommentar = useRef(null);
  const [name, setname] = useState(props.username);
  const [update, setupdate] = useState(0);
  const [replys, setreplys] = useState([]);
  const [selectedForAnswer, setSelectedForAnswer] = useState(null);
  const [schreibtkommentar, setschreibtkommentar] = useState(false);
  const [Kommentaregeschrieben, setKommentaregeschrieben] = useState(0);

  const cookies = new Cookies();
  let history = useHistory();

  function changereply(e, number) {
    let newreply = replys.map((obj) => obj);
    newreply[number] = e.target.value;
    setreplys(newreply);
  }

  function changeReplyOnAnswer(id, text) {
    setSelectedForAnswer({ id: id, text: text });
  }

  useEffect(() => {
    getKommentare();
  }, []);

  function Kommentarhinzufügen(text) {
    if (text !== "") {
      if (Kommentaregeschrieben < 1) {
        axios
          .post("https://spibi-online.de/Kommentieren", {
            artikelid: props.id,
            text: text,
            id: cookies.get("User_id"),
          })
          .then((response) => {
            if (response.data.msg !== null) {
              props.addnotification(response.data.msg, "red");
            } else {
              setKommentaregeschrieben(1);
              props.addnotification("Kommentar geschrieben", "green");
            }
            getKommentare();
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } else {
        props.addnotification(
          "Du hast schon einen Kommentar geschrieben #787",
          "red"
        );
      }
    }
  }

  function handlereply(text, id) {
    axios
      .post("https://spibi-online.de/aufkommentareantworten", {
        artikelid: props.id,
        text: text,
        kommentarid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data == true) {
          getKommentare();
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function getKommentare() {
    //console.log("kommentare anfordern");
    axios
      .post("https://spibi-online.de/getKommentare", {
        artikelid: props.id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        //console.log("Kommentare bekommen", response.data.kommentare);
        setkommentare(response.data.kommentare);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function handlelöschen(uuid, id) {
    axios
      .post("https://spibi-online.de/Kommentarloeschen", {
        artikelid: props.id,
        uuid: uuid,
        kommentarid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data == true) {
          props.addnotification("Gelöscht");
          getKommentare();
        } else {
        }
      })
      .catch((error) => {
        console.log(error, "Das hat nicht geklappt #872");
      });
  }

  function Kommentarstarten() {
    if (inputKommentar.current.value.length > 0) {
      if (selectedForAnswer == null) {
        Kommentarhinzufügen(inputKommentar.current.value);
      } else {
        handlereply(inputKommentar.current.value, selectedForAnswer.id);
      }
      setSelectedForAnswer(null);
      inputKommentar.current.value = "";
    }
  }

  /*setInterval(() => {
    setupdate(update + 1);
  }, 100);*/

  function Profilepicture(obj) {
    if (
      obj.profilepic == null ||
      obj.profilepic == "" ||
      obj.profilepic == undefined ||
      obj.profilepic == "Unknown"
    ) {
      return (
        <div className="avatar placeholder">
          <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
            <span>{obj.name.substring(0, 1).toUpperCase()}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="avatar">
          <div className="w-12 rounded-full">
            <img src={obj.profilepic} />
          </div>
        </div>
      );
    }
  }

  function changeschreibtkommentar() {
    if (inputKommentar.current.value != "") {
      setschreibtkommentar(true);
    } else {
      setschreibtkommentar(false);
    }
  }

  return (
    <div className="comments border-base-200 border-t-4 overflow-x-hidden">
      <div className="Kommentare relative">
        {kommentare &&
          kommentare.map((obj, index) => {
            if (
              obj != null &&
              obj.name !== undefined &&
              obj.name !== "unknown"
            ) {
              return (
                <div key={index} className="Kommentare-Kommentar">
                  <div className="Kommentare-Kommentar-hedline relative">
                    {Profilepicture(obj)}
                    <div className="Kommentare-Kommentar-name ml-3">
                      <div>
                        {obj.name} vor{" "}
                        {(function () {
                          if (Date.now() - obj.time > 1000 * 60 * 60 * 24) {
                            let Tage =
                              (Date.now() - obj.time) / (1000 * 60 * 60 * 24);
                            Tage = Math.floor(Tage);
                            if (Tage > 1) {
                              return Tage + " Tagen";
                            } else {
                              return "einem Tag";
                            }
                          } else if (Date.now() - obj.time > 1000 * 60 * 60) {
                            let Stunden =
                              (Date.now() - obj.time) / (1000 * 60 * 60);
                            Stunden = Math.floor(Stunden);
                            if (Stunden > 1) {
                              return Stunden + " Stunden";
                            } else {
                              return "einer Stunde";
                            }
                          } else if (Date.now() - obj.time > 1000 * 60) {
                            let Minuten = (Date.now() - obj.time) / (1000 * 60);
                            Minuten = Math.floor(Minuten);
                            if (Minuten > 1) {
                              return Minuten + " Minuten";
                            } else {
                              return "einer Minute";
                            }
                          } else {
                            return "weniger als einer Minute";
                          }
                        })()}
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        changeReplyOnAnswer(obj.uuid, obj.text);
                      }}
                      className=" absolute right-0"
                    >
                      <span className="material-symbols-outlined text-secondary">
                        reply
                      </span>
                    </button>
                    {obj != null &&
                      obj.name !== undefined &&
                      (obj.name === name || props.isAdmin) && (
                        <button
                          onClick={() => handlelöschen(obj.uuid, obj.id)}
                          className=" absolute right-14"
                        >
                          <span className="material-symbols-outlined text-secondary">
                            delete
                          </span>
                        </button>
                      )}
                  </div>

                  <div className="Kommentare-Kommentar-text">{obj.text}</div>

                  <Replys
                    replys={obj.replys}
                    username={props.username}
                    id={props.id}
                    colors={props.colors}
                    colormode={props.color}
                    isAdmin={props.isAdmin}
                    getKommentare={getKommentare}
                    addnotification={props.addnotification}
                  />
                </div>
              );
            }
          })}
      </div>
      <div className="mb-10 mt-10 flex flex-col justify-center w-full">
        {selectedForAnswer !== null && (
          <div className="flex justify-center w-[70%] ml-[15%] h-12">
            <div className="input-group">
              <p className="input input-bordered flex items-center w-[60vw] !h-full !rounded-b-none ">
                {selectedForAnswer.text.substring(0, 60) + "..."}
              </p>
              <button
                className={
                  "btn w-[10vw] h-full " +
                  (selectedForAnswer !== null ? "!rounded-b-none" : "")
                }
                onClick={() => {
                  setSelectedForAnswer(null);
                }}
              >
                X
              </button>
            </div>
          </div>
        )}
        <div className="flex justify-center ml-[15%] w-[70%] !h-12">
          <div className="input-group">
            <input
              type="text"
              placeholder="Dein Kommentar"
              ref={inputKommentar}
              onChange={changeschreibtkommentar}
              id="Kommentar"
              maxLength="1000"
              className={
                "input input-bordered w-[60vw] !h-12 " +
                (selectedForAnswer !== null ? "!rounded-t-none" : "")
              }
            />
            <button
              className={
                "btn w-[10vw] !h-12 " +
                (selectedForAnswer !== null ? "!rounded-t-none" : "")
              }
              onClick={Kommentarstarten}
            >
              <span className="material-symbols-sharp bg-transparent">
                send
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
