import React from "react";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";

const Cookiebanner = (props) => {
  const cookies = new Cookies();

  function handleclick() {
    props.setcookiesallowed(true);
    cookies.set("Cookieallowed", true, { path: "/" });
  }

  return (
    <div className="Cookiebanner rounded-tl-lg rounded-tr-lg">
      <div className="Cookiebanner-text">
        SpiBi-Online nutzt Cookies, um sich zu merken, wer du bist und um deine
        Präferenzen zu speichern. In unserer GDPR erfährst du mehr. Durch
        Klicken auf "Akzeptieren" stimmst du den Cookies und der
        Datenschutzerklärung zu.
      </div>
      <br />
      <button className="Cookiebanner-button" onClick={handleclick}>
        🍪 Akzeptieren
      </button>
      <Link className="Profilsettings-element DataProtection" to="/Datenschutz">
        Datenschutzerklärung
      </Link>
    </div>
  );
};

export default Cookiebanner;
