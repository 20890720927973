import React from "react";
import { Link } from "react-router-dom";

export default function Artikel(props) {
  return (
    <div className="MyArticle" style={{ display: "block" }}>
      <div className="MyArticle-img-div">
        <img src={props.img} className="MyArticle-img"></img>
      </div>
      <div className="MyArticle-content">
        <div className="MyArticle-Headline">{props.title}</div>
        <div className="MyArticle-time">{props.time}</div>
        <div className="MyArticle-views">{props.views} Views</div>
      </div>
    </div>
  );
}
