import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Artikel(props) {
  console.log(props.type);
  return (
    <div>
      {(props.type !== "Werbung" || props.isPlus !== true) && (
        <a href={props.link} className="font-serif">
          <span className="banner">
            <div
              className="banner-svg"
              dangerouslySetInnerHTML={{ __html: props.svg }}
            />
          </span>
        </a>
      )}
    </div>
  );
}
