import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const Mitglieder = (props) => {
  const [mitglieder, setMitglieder] = useState([]);
  const [img, setimg] = useState(null);

  const name = useRef(null);
  const beschreibung = useRef(null);
  const twitter = useRef(null);
  const instagram = useRef(null);
  const youtube = useRef(null);
  const tiktok = useRef(null);
  const github = useRef(null);
  const spotify = useRef(null);

  const cookies = new Cookies();
  let history = useHistory();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getmitglieder", {
        userid: cookies.get("User_id"),
      })
      .then((response) => {
        setMitglieder(response.data.mitglieder);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function addMitglieder() {
    axios
      .post("https://spibi-online.de/addmitglieder", {
        userid: cookies.get("User_id"),
        name: name.current.value,
        beschreibung: beschreibung.current.value,
        bild: img,
        twitter: twitter.current.value,
        instagram: instagram.current.value,
        github: github.current.value,
        youtube: youtube.current.value,
        tiktok: tiktok.current.value,
        spotify: spotify.current.value,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
          props.addnotification("Mitglied wurde hinzugefügt", "green");
        }
      });
  }

  function removeMitglieder(id) {
    props.addnotification(
      "Mitglied wird entfernt. Neuladen um aktuellen stand zu sehen",
      "red"
    );
    axios
      .post("https://spibi-online.de/deletemitglieder", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
          props.addnotification("Mitglied wurde entfernt", "green");
        }
      });
  }

  function handleimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        setimg(upload.target.result);
      } else {
        props.addnotification("Diese Datei ist zu groß #011", "red");
      }
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className="Mitglieder">
      <div className="Mitglieder-container pl-20 mr-20">
        {mitglieder.map((element, index) => {
          return (
            <div
              key={index}
              className="Mitglieder-element border-2 rounded-md border-black p-2 mt-4"
            >
              {element.name}
              <button
                className="mitglieder-element-remove float-right text-red-700"
                onClick={() => removeMitglieder(element._id)}
              >
                Löschen
              </button>
            </div>
          );
        })}
      </div>
      <div className="Mitglieder-add ml-[35vw] mt-10 flex-col mr-20">
        Add Mitglied
        <div className="form-control mt-4">
          <label className="input-group">
            <span>name</span>
            <input
              ref={name}
              type="text"
              placeholder="name"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>beschreibung</span>
            <input
              ref={beschreibung}
              type="text"
              placeholder="beschreibung"
              className="input input-bordered"
            />
          </label>
        </div>
        <input
          onChange={handleimg}
          encType="multipart/form-data"
          type="file"
          className="mt-4 file-input w-full max-w-xs"
        />
        <div className="form-control mt-4">
          <label className="input-group">
            <span>twitter</span>
            <input
              ref={twitter}
              type="text"
              placeholder="twitter"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>instagram</span>
            <input
              ref={instagram}
              type="text"
              placeholder="instagram"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>youtube</span>
            <input
              ref={youtube}
              type="text"
              placeholder="youtube"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>tiktok</span>
            <input
              ref={tiktok}
              type="text"
              placeholder="youtube"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>Spotify</span>
            <input
              ref={spotify}
              type="text"
              placeholder="Spotify"
              className="input input-bordered"
            />
          </label>
        </div>
        <div className="form-control mt-4">
          <label className="input-group">
            <span>Github</span>
            <input
              ref={github}
              type="text"
              placeholder="github"
              className="input input-bordered"
            />
          </label>
        </div>
      </div>
      <button
        onClick={addMitglieder}
        className="mb-12 btn btn-primary ml-[35vw] mt-4"
      >
        Add Mitglied
      </button>
    </div>
  );
};

export default Mitglieder;
