import React, { useState, useEffect, useContext } from "react";
import { themeChange } from "theme-change";
import useSwipeable from "react-swipeable";
import axios from "axios";
import Cookies from "universal-cookie";
import crypto from "crypto-js";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import Home from "./Home";
import Passwortzurücksetzen from "./Konto/Passwordzurück";
import Shop from "./Shop/Shop";
import Passwortzurücksetzenkey from "./Konto/Passwordzurücksetzen";
import Dealeatebanner from "./Admintools/dealeatebanner";
import Createbanner from "./Admintools/createbanner";
import Artikelpage from "./Artikelpage";
import Artikelerstellen from "./Artikelerstellen";
import KontoVerwalten from "./Konto/KontoVerwalten";
import Footer from "./footer";
import Anmelden from "./Konto/Anmelden";
import Register from "./Konto/Registrieren";
import SelectArticle from "./Admintools/SelectArticle";
import Feedback from "./Feedback";
import Profilsettings from "./Konto/Profilsettings";
import Bearbeiten from "./Admintools/Bearbeiten";
import Frageerstellen from "./Admintools/Fragen/Frageerstellen";
import Fragenbearbeiten from "./Admintools/Fragen/Fragenbearbeiten";
import Activate from "./Konto/Activate";
import MyArticles from "./MyArticles";
import Loading from "./Loading";
import Plusfreischalten from "./Admintools/Plusfreischalten";
import AddOldSpibis from "./Admintools/AddOldSpibis";
import SpibiPDFViewer from "./SpibiPDFViewer";
import Codeerstellen from "./Admintools/Codeserstellen";
import Deleteuser from "./Admintools/deleteusers";
import CookieBanner from "./CookieBanner";
import Überuns from "./Überuns";
import Datenschutz from "./Datenschutz";
import Events from "./Events";
import CreateEvent from "./Admintools/createevent";
import DealeateEvent from "./Admintools/dealeateevent";
import Mitglieder from "./Admintools/Mitglieder";
import Artikelpagebearbeiten from "./Artikelpagebearbeiten";
import Artikelpagebearbeitenvorschlag from "./Admintools/Artikelpagebearbeitenvorschlag";
import Changepassword from "./Admintools/Changepassword";
import Kategorien from "./Kategorien/Kategorien";
import Zufaellig from "./Kategorien/Zufällig";
import Gisinside from "./Kategorien/Gisinside";
import Giswf from "./Kategorien/Giswf";
import Verschiedenes from "./Kategorien/Verschiedenes";
import EineKategorie from "./Kategorien/eineKategorie";
import Startseite from "./Startseite";
import Tagderoffenentür from "./Tagderoffenentür";
import NoSideFound from "./NoSideFound";
import { ToastContainer, toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";

function App(props) {
  const [Artikellist, setArtikellist] = useState([]);
  const [Autorised, setAutorised] = useState(null);
  const [Userid, setUserid] = useState("");
  const [email, setEmail] = useState("");
  const [img, setimg] = useState("");
  const [registertime, setRegistertime] = useState("");
  const [Username, setUsername] = useState("");
  const [Fragen, setFragen] = useState(null);
  const [Fragen2, setFragen2] = useState(null);
  const [MyAnswers, setMyAnswers] = useState(null);
  const [curpage, setcurpage] = useState(0);
  const [isAdmin, setisadmin] = useState(false);
  const [artikelVorschlageList, setArtikelVorschlageList] = useState([]);
  const [spibiPDFIds, setSpibiPDFIds] = useState([]);
  const [cookiesallowed, setcookiesallowed] = useState(null);
  const [redirect2, setredirect] = useState(null);
  const [AnswersArray, setAnswersArray] = useState(null);
  const [newIndex, setnewIndex] = useState(null);
  const [colormode, setcolormode] = useState("cmyk");
  const [banner, setbanner] = useState(null);
  const [Event, setEvent] = useState(null);
  const [startloadingagain, setstartloadingagain] = useState(null);
  const [Spibis, setSpibis] = useState(null);
  const [Warenkorb, setWarenkorb] = useState(null);
  const [gelesen, setgelesen] = useState(null);
  const [search, setsearch] = useState("");
  const [isiserv, setisiserv] = useState(null);

  // Farben festlegen
  let colors = {
    light: { color: "#000000", backgroundcolor: "#FFFFFF" },
    dim: {
      color: "#ebebeb",
      backgroundcolor: "#2f3437",
      backgroundcolor2: "#373c3f",
    },
    dark: {
      color: "#ffffff",
      backgroundcolor: "#000",
      backgroundcolor2: "#1b1b1b",
    },
  };

  const cookies = new Cookies();
  let location = useLocation();
  let history = useHistory();

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getTouches(evt) {
    return evt.touches;
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 9) {
        // if (foo > Number(location.pathname.split("/")[1]) + 1) {
        //   history.push(
        //     // "/" + String(Number(location.pathname.split("/")[1]) + 1)
        //     "/" + String(Number(location.pathname.split("/")[1]) + 1)
        //   );
        // } else {
        //   setredirect("home");
        // }
      } else if (xDiff < -9) {
        setredirect("home");
        setTimeout(function () {
          setredirect(null);
        }, 100);
      }
    } else {
      if (yDiff > 0) {
        /* down swipe */
      } else {
        /* up swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  function sha256(text) {
    // Convert the text to an array of words
    const words = crypto.enc.Utf8.parse(text);

    // Calculate the SHA-256 hash of the words
    const hash = crypto.SHA256(words);

    // Return the hash as a hexadecimal string
    return hash.toString(crypto.enc.Hex);
  }

  useEffect(() => {
    if (MyAnswers != null) {
      getAnswersInArray();
    }
  }, [MyAnswers]);

  function getAnswersInArray() {
    let answers2 = [];
    MyAnswers.map((obj, index) => {
      answers2[obj.Id] = obj.answers;
    });
    setAnswersArray(answers2);
  }

  function convertAnswersArray() {
    let answers3 = [];
    MyAnswers.map((obj, index) => {
      answers3[obj.Id] = obj.answers;
    });
    return answers3;
  }

  useEffect(() => {
    if (MyAnswers != null) {
      const answersArray3 = convertAnswersArray();
      if (Fragen != null && answersArray3 != null) {
        let answeredQuestions = Fragen.filter((x) => {
          if (answersArray3[x._id] != null) {
            return true;
          } else {
            return false;
          }
        });
        let unansweredQuestions = Fragen.filter((x) => {
          if (answersArray3[x._id] != null) {
            return false;
          } else {
            return true;
          }
        });
        setnewIndex(unansweredQuestions.length);
        setFragen2(unansweredQuestions.concat(answeredQuestions));
      }
    }
  }, [Fragen]);

  useEffect(() => {
    if (Autorised) {
      setTimeout(() => {
        loadartikel(cookies.get("User_id"));
      }, 1000);
    }
  }, [curpage]);

  useEffect(() => {
    themeChange(false);
    loadrest(cookies.get("User_id"));
  }, []);

  function loadartikel(id) {
    console.log("test");
    let temp = curpage + 1;
    axios
      .post("https://spibi-online.de/getpage", {
        id: id,
        page: temp,
        headers: {
          "Content-Encoding": "gzip",
        },
      })
      .then((response) => {
        if (response.data.msg == null) {
          setArtikellist([...Artikellist, ...response.data.artikel]);
          let curcurpage = Number(String(temp));

          if (Artikellist.length < (curcurpage - 1) * 5) {
            getArtikelVorschlaege(id);
          } else {
            setcurpage((prevcurpage) => prevcurpage + 1);
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function getArtikelVorschlaege(id) {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: id,
        headers: {
          "Content-Encoding": "gzip",
        },
      })
      .then((response) => {
        if (response.data == true) {
          setisadmin(true);
          console.log("isAdmin");
          axios
            .post("https://spibi-online.de/Artikelvorschlage", {
              id: id,
            })
            .then((response) => {
              if (response.data.artikel != null) {
                console.log(response.data.artikel);
                setArtikelVorschlageList(response.data.artikel);
              }
            })
            .catch((error) => {
              console.log(error, "error");
            });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function loadrest(id) {
    console.log("startloadrest");
    if (id == null) {
      setAutorised(false);
      return;
    }
    if (
      (cookies.get("Cookieallowed") || cookiesallowed === true) &&
      id.length > 0
    ) {
      axios
        .post("https://spibi-online.de/Authenticate", {
          id: id,
          headers: {
            "Content-Encoding": "gzip",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.msg == null) {
            //setArtikellist(response.data.artikel);
            console.log(response.data.isactivated);
            if (!response.data.isactivated) {
              addnotification(
                "Bitte aktiviere dein Konto mithilfe des Links in der Email",
                "red"
              );
              setAutorised(false);
              if (history.location.pathname === "/") {
                logout();
              }
            } else {
              id = response.data.id;
              setisiserv(response.data.isiserv);
              console.log(response.data.isiserv);
              setAutorised(true);
              setgelesen(response.data.gelesen);
              console.log(response);
              setcolormode(response.data.colormode);
              setEmail(response.data.email);
              setRegistertime(response.data.registertime);
              setUsername(capitalizeFirstLetter(response.data.name));
              cookies.set("User_id", response.data.id, { path: "/" });

              //Load alot of stuff
              //First Artikels
              console.log("test");
              let temp = curpage + 1;
              axios
                .post("https://spibi-online.de/getpage", {
                  id: id,
                  page: temp,
                  headers: {
                    "Content-Encoding": "gzip",
                  },
                })
                .then((response) => {
                  if (response.data.msg == null) {
                    setArtikellist([...Artikellist, ...response.data.artikel]);
                    let curcurpage = Number(String(temp));

                    if (Artikellist.length < (curcurpage - 1) * 5) {
                      getArtikelVorschlaege(id);
                    } else {
                      setcurpage((prevcurpage) => prevcurpage + 1);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error, "error");
                });

              axios
                .post("https://spibi-online.de/getProfilepicture", {
                  id: cookies.get("User_id"),
                  headers: {
                    "Content-Encoding": "gzip",
                  },
                })
                .then((response) => {
                  console.log(response);
                  setimg(response.data);
                })
                .catch((error) => {
                  console.log(error, "error");
                });
              axios
                .post("https://spibi-online.de/getbanner", {
                  userid: id,
                  headers: {
                    "Content-Encoding": "gzip",
                  },
                })
                .then((response) => {
                  if (response.data.msg == null) {
                    setbanner(response.data.banners);
                  }
                })
                .catch((error) => {
                  console.log(error, "error");
                });
              // axios
              //   .post("https://spibi-online.de/getFragen", {
              //     id: id,
              //   })
              //   .then((response) => {
              //     if (response.data.msg == null) {
              //       console.log(response.data.fragen);
              //       setMyAnswers(response.data.answers);
              //       setFragen(response.data.fragen);
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error, "error");
              //   });
              // axios
              //   .post("https://spibi-online.de/getevent", {
              //     userid: id,
              //   })
              //   .then((response) => {
              //     if (response.data.msg == null) {
              //       console.log(response.data.events);
              //       setEvent(response.data.events);
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error, "error");
              //   });
              // axios
              //   .post("https://spibi-online.de/getOldSpibisLinks", {
              //     id: id,
              //   })
              //   .then((response) => {
              //     if (response.data.msg == null) {
              //       setSpibiPDFIds(response.data.spibiInfos);
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error, "error");
              //   });
              // axios
              //   .post("https://spibi-online.de/getspibiausgaben", {
              //     id: id,
              //   })
              //   .then((response) => {
              //     if (response.data.msg == null) {
              //       setSpibis(response.data.oldspibi);
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error, "error");
              //   });
            }
          } else {
            setAutorised(false);
          }
          console.log(Autorised);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      setAutorised(false);
      console.log(Autorised);
    }
  }

  function addnotification(text, type) {
    if (type == "green") {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else if (type == "red") {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function changecolormode(color) {
    axios
      .post("https://spibi-online.de/changecolormode", {
        userid: cookies.get("User_id"),
        mode: color,
        headers: {
          "Content-Encoding": "gzip",
        },
      })
      .then((response) => {
        if (response.data.msg == null) {
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    setcolormode(color);
  }

  function updatebanner(banners) {
    setbanner(banners);
  }
  function updateevents(events) {
    setEvent(events);
  }

  function setstartloadingagainupdate() {
    loadrest(cookies.get("User_id"));
  }

  function logout() {
    cookies.set("User_id", "", { path: "/" });
    history.push("/Anmelden");
    setAutorised(false);
    window.location.reload();
  }

  function Searchonchange(e) {
    setsearch(e.target.value);
  }

  return (
    <div
      className="container bg-base-200 primary-content drawer fixed w-full h-full"
      data-theme={colormode}
    >
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <ToastContainer
        className="z-[9999999999]"
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <div className="drawer-content leftdrawer-content fixed w-full h-full">
        {redirect2 === "home" && <Redirect to="/" />}
        {redirect2 !== null && redirect2 !== "home" && (
          <Redirect to={redirect2} />
        )}
        {!cookies.get("Cookieallowed") && location.pathname !== "/" && (
          <CookieBanner
            colormode={colormode}
            colors={colors}
            setcookiesallowed={setcookiesallowed}
          />
        )}
        {Autorised === null && <Loading />}
        {location.pathname !== "/Datenschutz" &&
          location.pathname !== "/Passwortzurücksetzen" &&
          location.pathname !== "/Passwortzurücksetzenkey" &&
          location.pathname !== "/Anmelden" &&
          location.pathname !== "/Registrieren" &&
          location.pathname !== "/Activate" &&
          location.pathname !== "/Artikel/TagderoffenenTuer" &&
          Autorised === false &&
          !cookies.get("Hasaccount") && <Startseite />}
        {location.pathname !== "/Datenschutz" &&
          location.pathname !== "/Passwortzurücksetzen" &&
          location.pathname !== "/Passwortzurücksetzenkey" &&
          location.pathname !== "/Anmelden" &&
          location.pathname !== "/Registrieren" &&
          location.pathname !== "/Activate" &&
          location.pathname !== "/Artikel/TagderoffenenTuer" &&
          Autorised === false &&
          cookies.get("Hasaccount") && <Redirect to={"/Anmelden"} />}
        <Switch location={location}>
          {Autorised &&
            Artikellist.map((obj, index) => {
              if (obj.artikel != null) {
                return (
                  <Route
                    key={String(index)}
                    exact
                    path={"/Artikel/" + sha256(obj.name.split(" ").join(""))}
                  >
                    <Artikelpage
                      username={Username}
                      key={String(index)}
                      id={obj._id}
                      weitereAutoren={obj.weitereAutoren}
                      isAdmin={isAdmin}
                      img={obj.img}
                      title={obj.name}
                      titletext={obj.titletext}
                      color={obj.color}
                      text={obj.artikel}
                      colors={colors}
                      kommentare={obj.Kommentare}
                      autor={obj.autor}
                      time={obj.time}
                      addnotification={addnotification}
                      colormode={colormode}
                    />
                  </Route>
                );
              }
            })}
          {isAdmin &&
            Artikellist.map((obj, index) => {
              return (
                <Route
                  key={String(index)}
                  exact
                  path={
                    "/" + sha256(obj.name.split(" ").join("")) + "bearbeiten"
                  }
                >
                  <Navbar
                    img={img}
                    color={"rgb(0, 0, 0, 0)"}
                    logo={false}
                    colors={colors}
                    addnotification={addnotification}
                    colormode={colormode}
                  />
                  <Artikelpagebearbeiten
                    username={Username}
                    key={String(index)}
                    id={obj._id}
                    isIserv={obj.isIserv}
                    isExplicit={obj.isExplicit}
                    img={obj.img}
                    isAdmin={isAdmin}
                    title={obj.name}
                    titletext={obj.titletext}
                    color={obj.color}
                    text={obj.artikel}
                    weitereAutoren={obj.weitereAutoren}
                    colors={colors}
                    kommentare={obj.Kommentare}
                    topics={obj.topics}
                    autor={obj.autor}
                    time={obj.time}
                    addnotification={addnotification}
                    colormode={colormode}
                    tags={obj.tags}
                    kategorie={obj.Kategorie}
                  />
                </Route>
              );
            })}
          {isAdmin &&
            artikelVorschlageList.map((obj, index) => {
              return (
                <Route
                  key={String(index)}
                  exact
                  path={
                    "/" + sha256(obj.name.split(" ").join("")) + "bearbeiten"
                  }
                >
                  <Navbar
                    img={img}
                    color={"rgb(0, 0, 0, 0)"}
                    logo={false}
                    colors={colors}
                    addnotification={addnotification}
                    colormode={colormode}
                  />
                  <Artikelpagebearbeitenvorschlag
                    username={Username}
                    key={String(index)}
                    weitereAutoren={obj.weitereAutoren}
                    id={obj._id}
                    isIserv={obj.isIserv}
                    isExplicit={obj.isExplicit}
                    img={obj.img}
                    isAdmin={isAdmin}
                    title={obj.name}
                    titletext={obj.titletext}
                    color={obj.color}
                    text={obj.artikel}
                    colors={colors}
                    kommentare={obj.Kommentare}
                    autor={obj.autor}
                    time={obj.time}
                    addnotification={addnotification}
                    colormode={colormode}
                    tags={obj.tags}
                    kategorie={obj.Kategorie}
                  />
                </Route>
              );
            })}
          {Autorised &&
            spibiPDFIds.map((obj, index) => {
              return (
                <Route key={String(index)} exact path={"/" + obj.id}>
                  <Navbar
                    img={img}
                    color={"#fff"}
                    colors={colors}
                    logo={false}
                    addnotification={addnotification}
                    colormode={colormode}
                  />
                  <SpibiPDFViewer
                    key={String(index) + String(obj.id)}
                    id={obj.id}
                    colors={colors}
                    title={obj.title}
                    year={obj.year}
                    colormode={colormode}
                  />
                </Route>
              );
            })}
          <Route exact path="/umfragen">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  isfeedback={true}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Feedback
                  name={Username}
                  fragen={Fragen2}
                  myAnswers={MyAnswers}
                  colors={colors}
                  answersArray={AnswersArray}
                  newIndex={newIndex}
                  addnotification={addnotification}
                  colormode={colormode}
                />
              </div>
            )}
          </Route>
          <Route exact path="/shop">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  isfeedback={false}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Shop
                  colors={colors}
                  oldSpibis={Spibis}
                  addnotification={addnotification}
                  colormode={colormode}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Anmelden">
            {!Autorised && (
              <Anmelden
                setUserid={setUserid}
                addnotification={addnotification}
                colormode={colormode}
                colors={colors}
                setstartloadingagainupdate={setstartloadingagainupdate}
              />
            )}
            {Autorised && <Redirect to={"/"} />}
          </Route>
          <Route exact path="/createevents">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  isfeedback={false}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <CreateEvent
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Events">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Events
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                  Event={Event}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Überuns">
            {Autorised && (
              <div>
                <Überuns colormode={colormode} colors={colors} />
              </div>
            )}
          </Route>
          <Route exact path="/Datenschutz">
            {Autorised && (
              <Navbar
                img={img}
                color={"rgba(30, 30, 30, 0.96)"}
                logo={false}
                colors={colors}
                addnotification={addnotification}
                colormode={colormode}
              />
            )}
            <Datenschutz colormode={colormode} colors={colors} />
          </Route>
          <Route exact path="/createbanner">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#FFFFFF"}
                  logo={false}
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Createbanner
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/bearbeiten">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Bearbeiten
                  artikel={Artikellist}
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/frageerstellen">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
                <Frageerstellen
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/myArticles">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#ffffff"}
                  logo={false}
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <MyArticles
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/fragenbearbeiten">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Fragenbearbeiten
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/selectArtikel">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  logo={false}
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <SelectArticle
                  addnotification={addnotification}
                  ArtikelVorschlaege={artikelVorschlageList}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Deleteuser">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  logo={false}
                  colors={colors}
                  colormode={colormode}
                  addnotification={addnotification}
                />
                <Deleteuser
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Plusfreischlten">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Plusfreischalten
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/dealeatebanner">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Dealeatebanner
                  updatebanner={updatebanner}
                  banners={banner}
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Mitgliederverwalten">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Mitglieder
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/dealeateevent">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <DealeateEvent
                  updateevents={updateevents}
                  Event={Event}
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/CodesErstellen">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Codeerstellen
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Registrieren">
            {!Autorised && (
              <div>
                <Register
                  setUserid={setUserid}
                  addnotification={addnotification}
                  cookiesallowed={cookiesallowed}
                  colormode={colormode}
                  colors={colors}
                  setstartloadingagainupdate={setstartloadingagainupdate}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Activate">
            <Activate
              addnotification={addnotification}
              colors={colors}
              colormode={colormode}
            />
          </Route>
          <Route exact path="/Passwortzurücksetzen">
            <Passwortzurücksetzen
              addnotification={addnotification}
              colors={colors}
              colormode={colormode}
            />
          </Route>
          <Route exact path="/Passwortzurücksetzenkey">
            <Passwortzurücksetzenkey
              addnotification={addnotification}
              colors={colors}
              colormode={colormode}
            />
          </Route>
          <Route exact path="/Konto">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"#fcfcfc"}
                  colors={colors}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                />
                <Profilsettings
                  name={Username}
                  email={email}
                  registertime={registertime}
                  addnotification={addnotification}
                  changecolormode={changecolormode}
                  ArtikelVorschlaege={artikelVorschlageList.length}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/createArtikel">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"rgb(0, 0, 0, 0)"}
                  logo={false}
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                  requireConfermation={true}
                />
                <Artikelerstellen
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                  username={Username}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Changepasswordadmin">
            {Autorised && (
              <div>
                <Navbar
                  img={img}
                  color={"rgb(0, 0, 0, 0)"}
                  logo={false}
                  colors={colors}
                  addnotification={addnotification}
                  colormode={colormode}
                  requireConfermation={true}
                />
                <Changepassword
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                  username={Username}
                />
              </div>
            )}
          </Route>
          <Route exact path="/addOldSpibis">
            {isAdmin && (
              <div>
                <Navbar
                  img={img}
                  color={"#fff"}
                  logo={false}
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
                <AddOldSpibis
                  addnotification={addnotification}
                  colormode={colormode}
                  colors={colors}
                />
              </div>
            )}
          </Route>
          <Route exact path="/">
            {Autorised && (
              <div>
                <Home
                  artikel={Artikellist}
                  userid={Userid}
                  colors={colors}
                  addnotification={addnotification}
                  oldSpibis={spibiPDFIds}
                  name={Username}
                  fragen={Fragen2}
                  myAnswers={MyAnswers}
                  answersArray={AnswersArray}
                  newIndex={newIndex}
                  colormode={colormode}
                  banners={banner}
                  isAdmin={isAdmin}
                  gelesen={gelesen}
                  search={search}
                />
              </div>
            )}
          </Route>
          <Route exact path="/Kategorien/Zufällig">
            {Autorised && <Zufaellig Artikels={Artikellist} />}
          </Route>
          <Route exact path="/Kategorien/gisinside">
            {Autorised && (
              <Gisinside
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/giswf">
            {Autorised && (
              <Giswf
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/Verschiedenes">
            {Autorised && (
              <Verschiedenes
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/Lehrersprüche">
            {Autorised && (
              <EineKategorie
                thisKategorie={"Lehrersprüche"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/Schülersprüche">
            {Autorised && (
              <EineKategorie
                thisKategorie={"Schülersprüche"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/Homestorys">
            {Autorised && (
              <EineKategorie
                thisKategorie={"Homestorys"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/SRV">
            {Autorised && (
              <EineKategorie
                thisKategorie={"SRV"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/Lehrersprueche">
            {Autorised && (
              <EineKategorie
                thisKategorie={"Lehrersprüche"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien/gisinside/Schülersprueche">
            {Autorised && (
              <EineKategorie
                thisKategorie={"Schülersprüche"}
                artikel={Artikellist}
                userid={Userid}
                colors={colors}
                addnotification={addnotification}
                oldSpibis={spibiPDFIds}
                name={Username}
                fragen={Fragen2}
                myAnswers={MyAnswers}
                answersArray={AnswersArray}
                newIndex={newIndex}
                colormode={colormode}
                banners={banner}
                isAdmin={isAdmin}
                gelesen={gelesen}
                search={search}
              />
            )}
          </Route>
          <Route exact path="/Kategorien">
            {Autorised && (
              <div>
                <Kategorien
                  artikel={Artikellist}
                  userid={Userid}
                  colors={colors}
                  addnotification={addnotification}
                  oldSpibis={spibiPDFIds}
                  name={Username}
                  fragen={Fragen2}
                  myAnswers={MyAnswers}
                  answersArray={AnswersArray}
                  newIndex={newIndex}
                  colormode={colormode}
                  banners={banner}
                  isAdmin={isAdmin}
                  gelesen={gelesen}
                  search={search}
                />
              </div>
            )}
          </Route>

          {!history.location.pathname.includes("/Artikel") &&
            Autorised == true && (
              <Route path="/*">
                <NoSideFound />
              </Route>
            )}
          {/* <Route exact path="/Artikel/TagderoffenenTuer">
            <Tagderoffenentür />
          </Route> */}
        </Switch>
      </div>
      <div className="drawer-side min-h-[100vh]">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu p-4 overflow-y-auto w-80 bg-base-100">
          <input
            type="text"
            placeholder="Search"
            className="input input-bordered"
            onChange={Searchonchange}
          />
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/Kategorien">Kategorien</Link>
          </li>
          <li>
            <Link to="/createartikel">Artikel erstellen</Link>
          </li>
          <li className="text-base-300 hover:bg-base-100">
            {/*to="/Events"*/}
            <div className="hover:bg-base-100">Events</div>
          </li>

          <li className="text-base-300 hover:bg-base-100">
            <div className="hover:bg-base-100">Umfragen</div>
          </li>
          <hr />
          <li>
            <Link to="/Konto">Einstellungen</Link>
          </li>
          {/* <li>
                <Link to="/myArticles">Meine Artikel</Link>
              </li> */}

          <li>
            <div onClick={() => logout()}>Abmelden</div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
