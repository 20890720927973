import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axios from "axios";

import parse from "html-react-parser";
import LogoB from "./icons/spibiLogo.svg";
import LogoW from "./icons/spibiLogo_weiß.svg";

export default function Navbar(props) {
  const cookies = new Cookies();
  let history = useHistory();
  const location = useLocation();
  const [img, setimg] = useState(null);
  const requireConfermation = props.requireConfermation;

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getProfilepicture", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response);
        setimg(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function logout() {
    cookies.set("User_id", "", { path: "/" });
    history.push("/Anmelden");
    window.location.reload();
  }

  return (
    <div className="nav">
      <div className="navbar bg-base-100 rounded-lg !w-[96vw]">
        <div className="navbar-start">
          <div className="only-phone">
            <label htmlFor="my-drawer-3" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
          </div>
          <div className="dropdown only-desktop">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <div
                  onClick={() => {
                    if (requireConfermation !== true) {
                      history.push("/");
                    } else {
                      if (
                        window.confirm(
                          "Möchten Sie die Seite wirklich verlassen?"
                        )
                      ) {
                        history.push("/");
                      }
                    }
                  }}
                >
                  Home
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (requireConfermation !== true) {
                      history.push("/Kategorien");
                    } else {
                      if (
                        window.confirm(
                          "Möchten Sie die Seite wirklich verlassen?"
                        )
                      ) {
                        history.push("/Kategorien");
                      }
                    }
                  }}
                >
                  Kategorien
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (requireConfermation !== true) {
                      history.push("/createartikel");
                    } else {
                      if (
                        window.confirm(
                          "Möchten Sie die Seite wirklich verlassen?"
                        )
                      ) {
                        history.push("/createartikel");
                      }
                    }
                  }}
                >
                  Artikel erstellen
                </div>
              </li>
              <li className="text-base-300 hover:bg-base-100">
                {/*to="/Events"*/}
                <div className="hover:bg-base-100">Events</div>
              </li>

              <li className="text-base-300 hover:bg-base-100">
                <div className="hover:bg-base-100">Umfragen</div>
              </li>
              <hr />
              <li>
                <div
                  onClick={() => {
                    if (requireConfermation !== true) {
                      history.push("/Konto");
                    } else {
                      if (
                        window.confirm(
                          "Möchten Sie die Seite wirklich verlassen?"
                        )
                      ) {
                        history.push("/Konto");
                      }
                    }
                  }}
                >
                  Einstellungen
                </div>
              </li>
              {/* <li>
                <Link to="/myArticles">Meine Artikel</Link>
              </li> */}
              {/* <li>
                <Link to="/createArtikel">Artikel erstellen</Link>
              </li> */}
              <li>
                <div
                  onClick={() => {
                    if (requireConfermation !== true) {
                      logout();
                    } else {
                      if (
                        window.confirm(
                          "Möchten Sie die Seite wirklich verlassen?"
                        )
                      ) {
                        logout();
                      }
                    }
                  }}
                >
                  Abmelden
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <div
            className="btn btn-ghost normal-case text-xl"
            onClick={() => {
              if (requireConfermation !== true) {
                history.push("/");
              } else {
                if (
                  window.confirm("Möchten Sie die Seite wirklich verlassen?")
                ) {
                  history.push("/");
                }
              }
            }}
          >
            <img
              src={props.colormode == "cmyk" ? LogoB : LogoW}
              className="navbar-top-text-logo"
              alt="logo"
            />
          </div>
        </div>
        <div className="navbar-end">
          <div
            onClick={() => {
              if (requireConfermation !== true) {
                history.push("/Konto");
              } else {
                if (
                  window.confirm("Möchten Sie die Seite wirklich verlassen?")
                ) {
                  history.push("/Konto");
                }
              }
            }}
            className="max-h-[48px]"
          >
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <div className="w-[48px] rounded-full">
                {img != "Unknown" && img != " " && img != "" ? (
                  <img
                    src={img}
                    loading="lazy"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                ) : (
                  <span className="material-symbols-outlined text-[48px] relative top-[-1px]">
                    account_circle
                  </span>
                )}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
