import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

export default function Frageerstellen(props) {
  const title = useRef();
  const [questions, setquestions] = useState([]);
  const cookies = new Cookies();
  let history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
  }

  function addquestion() {
    setquestions([
      ...questions,
      { question: "", mode: "", answers: [], useranswers: [] },
    ]);
  }

  function addanswer(index) {
    let temp = questions;
    temp[index].answers.push("");
    setquestions([...temp]);
  }

  function deleteAnswer(index, index2) {
    let temp = questions;
    temp[index].answers.splice(index2, 1);
    setquestions([...temp]);
  }

  function deleteQuestion(index) {
    let temp = questions;
    temp.splice(index, 1);
    setquestions([...temp]);
  }

  function changeQuestionQuestion(index, e) {
    let temp = questions;
    temp[index].question = e.target.value;
    setquestions([...temp]);
  }

  function changeQuestionMode(index, e) {
    let temp = questions;
    temp[index].mode = e.target.value;
    setquestions([...temp]);
  }

  function changeQuestionAnserText(index, index2, e) {
    let temp = questions;
    temp[index].answers[index2] = e.target.value;
    setquestions([...temp]);
  }

  function submit() {
    axios
      .post("https://spibi-online.de/createSurvey", {
        userid: cookies.get("User_id"),
        title: title.current.value,
        questions: questions,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
          props.addnotification("Umfrage wurde erstellt");
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  });

  return (
    <div className="Fragenerstellen bg-base-100 rounded-lg !w-[96vw] h-auto m-[2vw] pb-8 mb-32">
      <div className="w-full text-center text-3xl relative top-2 ">
        Fragenerstellen
      </div>
      <div className="m-8">
        <div className="m-4 h-16 relative flex items-center">
          <div className="absolute left-0">
            Überschrift der Umfrage (optional):
          </div>
          <input
            type="text"
            ref={title}
            placeholder="Type here"
            className="input input-bordered input-primary w-full max-w-xs absolute right-0"
          />
        </div>
        <div className="m-4 h-16 relative flex items-center justify-center">
          <button className="btn btn-primary" onClick={addquestion}>
            Frage Hinzufügen
          </button>
        </div>
        {questions.map((question, index) => {
          return (
            <div className="m-4 h-auto">
              <div className="m-4 h-16 relative flex items-center">
                <div className="absolute left-0">Frage</div>
                <input
                  type="text"
                  onChange={(e) => {
                    changeQuestionQuestion(index, e);
                  }}
                  placeholder="Type here"
                  className="input input-bordered input-primary w-full max-w-xs absolute right-0"
                />
              </div>
              <div className="m-4 h-16 relative flex items-center">
                <div className="absolute left-0">Wähle einen Fragetyp aus</div>
                <select
                  className="select select-primary w-full max-w-xs absolute right-0"
                  onChange={(e) => {
                    changeQuestionMode(index, e);
                  }}
                >
                  <option disabled selected>
                    Wähle einen Fragentyp aus:
                  </option>
                  <option>simple</option>
                  <option>yesno</option>
                  <option>choose</option>
                  <option>rate</option>
                </select>
              </div>
              <div className="m-4 h-auto relative flex flex-col">
                <div className="h-16">
                  Antwortmöglichkeiten (Nur bei "choose")
                </div>
                <button
                  className="btn btn-primary w-64 absolute right-0"
                  onClick={() => addanswer(index)}
                >
                  Antwort Hinzufügen
                </button>
                {question.answers.map((answer, index2) => {
                  return (
                    <div>
                      <input
                        type="text"
                        placeholder="Type here"
                        className="input input-bordered input-primary w-full max-w-xs m-4"
                        onChange={(e) => {
                          changeQuestionAnserText(index, index2, e);
                        }}
                      />
                      <button
                        className="btn btn-error"
                        onClick={() => deleteAnswer(index, index2)}
                      >
                        Löschen
                      </button>
                    </div>
                  );
                })}
              </div>
              <button
                className="btn btn-error"
                onClick={() => deleteQuestion(index)}
              >
                Frage Löschen
              </button>
            </div>
          );
        })}{" "}
        <button className="btn btn-active btn-primary w-full" onClick={submit}>
          Submit
        </button>
      </div>
    </div>
  );
}
