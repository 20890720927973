import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Artikel from "./../Artikel";
import Navbar from "./../Navbar";
import Banner from "./../banner";
import { useLocation } from "react-router-dom";

import Zufällig from "./Kategorienimgs/466A9C90-B925-45F9-95AA-4C1AE3568DC9.webp";
import Gisinside from "./Kategorienimgs/SCR-20230301-kch.webp";
import GisLogo from "./Kategorienimgs/gis-logo_merge.webp";
import verschiedenes from "./Kategorienimgs/verschiedenes.webp";
import LogoB from "./../icons/spibiLogo.svg";
import LogoW from "./../icons/spibiLogo_weiß.svg";

export default function Kategorien(props) {
  const location = useLocation();
  const cookies = new Cookies();
  const [search, setsearch] = useState("");
  const [filterHidden, setFilterHidden] = useState(false);
  const [category, setcategory] = useState("");
  const [sorting, setsorting] = useState("1");
  const [artikel, setartikel] = useState(props.artikel);
  const [sortedartikel, setsortedartikel] = useState([]);
  const [Name, setname] = useState(props.name);
  const [artikelids, setartikelids] = useState(null);
  const [banners, setbanners] = useState(props.banners);
  const [Plus, setPlus] = useState(false);
  const [gelesen, setgelesen] = useState(props.gelesen);
  const homeScroll = useRef(null);
  const [toggleLeft, settoggleLeft] = useState(false);
  const [img, setimg] = useState(null);
  let history = useHistory();
  let something = 0;

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getProfilepicture", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response);
        setimg(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  useEffect(() => {
    setsearch(props.search);
  }, [props.search]);

  function logout() {
    cookies.set("User_id", "", { path: "/" });
    history.push("/Anmelden");
    window.location.reload();
  }

  return (
    <div>
      <div className="navbar bg-base-100 rounded-lg !w-[96vw]">
        <div className="navbar-start">
          <div className="only-phone">
            <label htmlFor="my-drawer-3" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
          </div>
          <div className="dropdown only-desktop">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Kategorien">Kategorien</Link>
              </li>
              <li>
                <Link to="/createartikel">Artikel erstellen</Link>
              </li>
              <li className="text-base-300 hover:bg-base-100">
                {/*to="/Events"*/}
                <div className="hover:bg-base-100">Events</div>
              </li>

              <li className="text-base-300 hover:bg-base-100">
                <div className="hover:bg-base-100">Umfragen</div>
              </li>
              <hr />
              <li>
                <Link to="/Konto">Einstellungen</Link>
              </li>
              {/* <li>
                <Link to="/myArticles">Meine Artikel</Link>
              </li> */}
              {/* <li>
                <Link to="/createArtikel">Artikel erstellen</Link>
              </li> */}
              <li>
                <div onClick={() => logout()}>Abmelden</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <Link className="btn btn-ghost normal-case text-xl" to="/">
            <img
              src={props.colormode == "cmyk" ? LogoB : LogoW}
              className="navbar-top-text-logo"
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-end">
          <Link to="/Konto" className="max-h-[48px]">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <div className="w-[48px] rounded-full">
                {img != "Unknown" && img != " " && img != "" ? (
                  <img
                    src={img}
                    loading="lazy"
                    className="rounded-full w-[48px] h-[48px]"
                  />
                ) : (
                  <span className="material-symbols-outlined text-[48px] relative top-[-1px]">
                    account_circle
                  </span>
                )}
              </div>
            </label>
          </Link>
        </div>
      </div>
      <div className="Home pb-60">
        <div className="Home-inner" ref={homeScroll}>
          <div className="Home-Artikel relative">
            <div className="artikel-list-normal only-phone object-cover">
              <Link to="/Kategorien/gisinside" key={0}>
                <Artikel
                  key2={0}
                  key={String(0)}
                  id={"12345"}
                  img={Gisinside}
                  title={"GiS Inside"}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/Giswf" key={1}>
                <Artikel
                  key2={1}
                  key={String(1)}
                  id={"12345"}
                  img={GisLogo}
                  title={""}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/Verschiedenes" key={2}>
                <Artikel
                  key2={2}
                  key={String(2)}
                  id={"12345"}
                  img={verschiedenes}
                  title={""}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
              <Link to="/Kategorien/Zufällig" key={3}>
                <Artikel
                  key2={3}
                  key={String(3)}
                  id={"12345"}
                  img={Zufällig}
                  title={"Zufällig"}
                  color={null}
                  text={null}
                  autor={null}
                  artikeltext={"test"}
                  className={"normal-article"}
                  plus={false}
                  colormode={null}
                  tags={[]}
                  colors={null}
                  gelesen={[]}
                />
              </Link>
            </div>

            <div
              className={
                "spibi-artikel only-desktop relative flex flex-row justify-center"
              }
            >
              <div className="">
                <Link to="/Kategorien/gisinside" key={0}>
                  <Artikel
                    key2={0}
                    key={String(0)}
                    id={"12345"}
                    img={Gisinside}
                    title={"GiS Inside"}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                <Link to="/Kategorien/Verschiedenes" key={2}>
                  <Artikel
                    key2={2}
                    key={String(2)}
                    id={"12345"}
                    img={verschiedenes}
                    title={""}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
              </div>
              <div className="ml-12">
                <Link to="/Kategorien/Giswf" key={1}>
                  <Artikel
                    key2={1}
                    key={String(1)}
                    id={"12345"}
                    img={GisLogo}
                    title={""}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
                <Link to="/Kategorien/Zufällig" key={3}>
                  <Artikel
                    key2={3}
                    key={String(3)}
                    id={"12345"}
                    img={Zufällig}
                    title={"Zufällig"}
                    color={null}
                    text={null}
                    autor={null}
                    artikeltext={"test"}
                    className={"normal-article"}
                    plus={false}
                    colormode={null}
                    tags={[]}
                    colors={null}
                    gelesen={[]}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
