import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Link } from "react-router-dom";

export default function Feedback(props) {
  const cookies = new Cookies();
  const [name, setname] = useState(null);
  let history = useHistory();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getname", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        setname(response.data.name);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return <div className="Feedback"></div>;
}

//cd Server
//npm i
//npm i CORS
//npm run start
//new terminal
//cd Web
//npm i
//npm run start
