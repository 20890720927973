import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import PlusfreischaltenUser from "./Plusfreischalten-user";

export default function Plusfreischalten(props) {
  const [Userlist, setUserlist] = useState(null);
  const [Search, setSearch] = useState("");

  const cookies = new Cookies();
  let history = useHistory();

  function handleAdd(id) {
    axios
      .post("https://spibi-online.de/makeuserplus", {
        userid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    window.location.reload();
  }

  function handleDelete(id) {
    axios
      .post("https://spibi-online.de/deleteuserplus", {
        userid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    window.location.reload();
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });

    axios
      .post("https://spibi-online.de/getalluser", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.users != null) {
          setUserlist(response.data.users);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function updatesearch(e) {
    setSearch(e.target.value);
  }

  return (
    <div className="Plusfreischalten">
      <input
        type="text"
        placeholder="Nutzer Suche"
        className="Plusfreischalten-search"
        onChange={updatesearch}
      />
      {Userlist != null &&
        Userlist.map((obj, index) => {
          if (
            obj.name.toLowerCase().includes(Search.toLowerCase()) &&
            obj.plus != true
          ) {
            return (
              <PlusfreischaltenUser
                name={obj.name}
                _id={obj._id}
                handleAdd={handleAdd}
                buttonText={"+"}
              />
            );
          } else if (
            obj.name.toLowerCase().includes(Search.toLowerCase()) &&
            obj.plus != false
          ) {
            return (
              <PlusfreischaltenUser
                name={obj.name}
                _id={obj._id}
                handleAdd={handleDelete}
                buttonText={"-"}
              />
            );
          }
        })}
    </div>
  );
}
