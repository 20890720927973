import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Artikelselect from "./Artikelselect";
import { attributesToProps } from "html-react-parser";
import crypto from "crypto-js";

export default function SelectArticle(props) {
  const [Artikellist, setArtikellist] = useState(null);

  const cookies = new Cookies();
  let history = useHistory();

  function handleAdd(id, isPlus) {
    axios
      .post("https://spibi-online.de/ZuArtikelmachen", {
        id: id,
        userid: cookies.get("User_id"),
        plus: isPlus,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error, "error");
      });

    window.location.reload();
  }

  function deleatArtikel(id) {
    ///deleatevorArtikel
    axios
      .post("https://spibi-online.de/deleatevorArtikel", {
        id: id,
        userid: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data == true) {
          props.addnotification(
            "Der Artikel wurde gelöscht, aber die Seite wird nicht neu geladen damit du viele Artikel löschen kannst!",
            "green"
          );
        } else {
          props.addnotification("Etwas ist schief gelaufen #503");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function Artikelbearbeiten() {}

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    /*
    axios
      .post("https://spibi-online.de/Artikelvorschlage", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.artikel != null) {
          setArtikellist(response.data.artikel);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });*/
  }, []);

  function sha256(text) {
    // Convert the text to an array of words
    const words = crypto.enc.Utf8.parse(text);

    // Calculate the SHA-256 hash of the words
    const hash = crypto.SHA256(words);

    // Return the hash as a hexadecimal string
    return hash.toString(crypto.enc.Hex);
  }

  return (
    <div className="flex justify-center items-center">
      <div className="mt-[10vh] w-[800px] flex flex-row justify-between flex-wrap">
        {props.ArtikelVorschlaege != null &&
          props.ArtikelVorschlaege.map((obj, index) => {
            return (
              <Artikelselect
                handleAdd={handleAdd}
                deleatArtikel={deleatArtikel}
                key2={index}
                key={String(index)}
                id={obj._id}
                link={"/" + sha256(obj.name.split(" ").join("")) + "bearbeiten"}
                img={obj.img}
                title={obj.name}
                color={obj.color}
                text={obj.titletext}
                artikeltext={obj.artikel}
                autor={obj.autor}
                colormode={props.colormode}
                colors={props.colors}
                addnotification={props.addnotification}
              />
            );
          })}
        <p style={{ textAlign: "center" }}>
          {Artikellist == null ||
            (Artikellist.length == 0 && "Keine Vorgeschlagenen Artikel")}
        </p>
      </div>
    </div>
  );
}
