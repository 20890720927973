import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Navbar from "./Navbar";
import Kommentare from "./Kommentare";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

export default function ArtikelPreview(props) {
  const [update, setupdates] = useState(0);

  useEffect(() => {
    setupdates(update + 1);
  }, [props.update]);

  const cookies = new Cookies();
  let array = props.text;
  return (
    <div className="mt-0 overflow-y-scroll pb-10 only-desktop flex flex-col">
      <img src={props.img} className="w-[50vw] h-[33vw] bg-cover" />
      <div className="absolute right-2 top-[33vw]">Foto: {props.titletext}</div>
      <div key={1} className="Artikelpage-title w-[30vw] ml-[10vw] pt-2">
        {props.title}
      </div>
      <div className="Artikelpage-autor w-[30vw] ml-[10vw]">
        Von {props.anonym === false ? props.autor : "Anonym"}
      </div>
      <div key={2} className="w-[30vw] ml-[10vw]">
        <div className="Artikelpage-artikel-artikel-text">
          {array.length > 0 &&
            array.map((obj, index) => {
              if (obj.type === "video") {
                return (
                  <iframe
                    key={index}
                    className="Artikelpage-video"
                    src={`https://www.youtube.com/embed/${obj.link}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                );
              } else if (obj.type === "link") {
                return (
                  <a
                    key={index}
                    className="underline !text-blue-700"
                    href={obj.link}
                  >
                    {" " + obj.text + " "}
                  </a>
                );
              } else if (obj.type === "text") {
                if (array[index - 1] != undefined) {
                  if (array[index - 1].type === "link") {
                    return obj.text;
                  }
                }
                if (array[index + 1] != undefined) {
                  if (array[index + 1].type === "link") {
                    return obj.text;
                  }
                }
                return (
                  <div key={index} className="pt-2 pb-2">
                    {obj.text}
                  </div>
                );
              } else if (obj.type === "img") {
                if (obj.text.length !== 64) {
                  return (
                    <div key={index} className="relative">
                      <img
                        key={index}
                        className="Artikelpage-artikel-artikel-img"
                        src={obj.text}
                      ></img>
                      <p className="Artikelpage-artikel-img-catpion text-center">
                        {obj.caption}{" "}
                      </p>
                      <div className="absolute right-0 bottom-[-1em] text-base-300">
                        Foto: {obj.source}
                      </div>
                    </div>
                  );
                }
              } else if (obj.type === "audio") {
                if (obj.text.length !== 64) {
                  return (
                    <div key={index} className="flex w-full justify-center">
                      <div className="player">
                        <audio controls>
                          <source src={obj.text} />
                        </audio>
                      </div>
                    </div>
                  );
                }
              } else if (obj.type === "headline") {
                return (
                  <div key={index} className="pt-2 pb-2 font-bold">
                    {obj.text}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
