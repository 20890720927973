import React from "react";

const NoSideFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full bg-base-200 primary-content">
      <div className="text-[35vh]">404</div>
      <div className="text-3xl w-11/12 max-w-[650px] text-center">
        <p>
          Diese Seite konnte nicht gefunden werden oder sie existiert nicht.
        </p>{" "}
        <br /> Auf der Startseite warten jedoch viele tolle Artikel auf dich.
      </div>
      <a href="/" className="btn btn-primary text-black btn-wide mt-10">
        Spibi-Online Startseite
      </a>
    </div>
  );
};

export default NoSideFound;
