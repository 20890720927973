import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
} from "react-router-dom";

export default function Shop(props) {
  const [amounts, setamounts] = useState([0, 0, 0]);
  const [update, setupdate] = useState(0);
  const [list, setlist] = props.oldSpibis;

  useEffect(() => {
    setlist(props.oldSpibis);
  }, [props.oldSpibis]);

  return (
    <div className="Shop">
      <div className="Shop-wrapper">
        <div className="Shop-wrapper-wrapper">
          {list != null &&
            list.map((obj, index) => {
              console.log(obj, index, amounts);
              return (
                <Link to={`/${index}`} className="Shop-element" key={index}>
                  <img src={obj.img} className="Shop-element-img"></img>
                  <div className="Shop-element-title">{obj.name}</div>
                  <div className="Shop-element-price">{obj.price}€</div>
                  <div className="Shop-element-description">
                    {obj.description}
                  </div>
                  <div className="Shop-element-anzahl">
                    {obj.amount} Auf Lager
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}
