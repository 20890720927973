import React, { useRef, useEffect, useState } from "react";

const Changepassworduser = (props) => {
  const newPassword = useRef(null);

  return (
    <div className="deleteuseruser">
      <div className="deleteuseruser-name">{props.name}</div>
      <div className="form-control mr-8">
        <input
          type="text"
          ref={newPassword}
          placeholder="New Password"
          className="input w-full max-w-xs"
        />
      </div>
      <button
        className="deleteuseruser-btn"
        onClick={() =>
          props.handlepasswordlöschen(props._id, newPassword.current.value)
        }
      >
        Ändern
      </button>
    </div>
  );
};

export default Changepassworduser;
