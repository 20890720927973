import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

export default function Events(props) {
  const [Event, setEvent] = useState(props.Event);

  useEffect(() => {
    let sortevents = props.Event;

    if (sortevents != undefined) {
      sortevents.sort(function (a, b) {
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
      });
    }

    setEvent(sortevents);
  }, [props.Event]);

  let styleHome = {
    color: props.colors.light.color,
  };

  if (props.colormode == "dim") {
    styleHome.color = props.colors.dim.color;
    styleHome.backgroundColor = props.colors.dim.backgroundcolor2;
  }

  if (props.colormode == "dark") {
    styleHome.color = props.colors.dark.color;
    styleHome.backgroundColor = props.colors.dark.backgroundcolor;
  }

  let stylenavbar = {
    backgroundColor: props.colors.light.backgroundcolor,
  };

  if (props.colormode == "dark") {
    stylenavbar.backgroundColor = props.colors.dark.backgroundcolor;
    stylenavbar.color = props.colors.dark.color;
    stylenavbar.boxShadow = "none";
  }
  if (props.colormode == "dim") {
    stylenavbar.backgroundColor = props.colors.dim.backgroundcolor;
    stylenavbar.color = props.colors.dim.color;
    stylenavbar.boxShadow = "none";
  }

  let stylenavbar2 = {
    backgroundColor: props.colors.light.backgroundcolor,
  };

  if (props.colormode == "dark") {
    stylenavbar2.backgroundColor = props.colors.dark.backgroundcolor2;
    stylenavbar2.color = props.colors.dark.color;
    stylenavbar2.boxShadow = "none";
  }
  if (props.colormode == "dim") {
    stylenavbar2.backgroundColor = props.colors.dim.backgroundcolor;
    stylenavbar2.color = props.colors.dim.color;
    stylenavbar2.boxShadow = "none";
  }

  let stylebackground = {
    color: props.colors.light.color,
    backgroundColor: props.colors.light.backgroundcolor,
  };

  if (props.colormode == "dark") {
    stylebackground.backgroundColor = props.colors.dark.backgroundcolor;
    stylebackground.color = props.colors.dark.color;
  }
  if (props.colormode == "dim") {
    stylebackground.backgroundColor = props.colors.dim.backgroundcolor2;
    stylebackground.color = props.colors.dim.color;
  }

  return (
    <div className="Events">
      {Event &&
        Event.map((obj, index) => {
          return (
            <div key={index} className="Events-Event">
              <div className="Events-Event-picture">
                <img src={obj.picture} className="Events-Event-picture-img" />
              </div>
              <div className="Events-Event-title">{obj.title}</div>
              <div className="Events-Event-date">
                {obj.date.split("-").reverse().join(".")}
              </div>
              <div className="Events-Event-text">{obj.text}</div>
            </div>
          );
        })}
    </div>
  );
}
