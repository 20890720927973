import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Kommentare from "./Kommentare";
import Footer from "./footer";
import crypto from "crypto-js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

export default function Artikelpage(props) {
  const [Kommentaree, setKommentare] = useState(props.kommentare);
  const [update, setupdate] = useState(0);
  const [opend, setopend] = useState(0);
  const [readingTime, setreadingTime] = useState(0);
  const [readed, setreaded] = useState(0);
  const text = useRef(null);
  const articleElement = useRef(null);
  const wrapper = useRef(null);
  const cookies = new Cookies();
  const isBrowser = typeof window !== `undefined`;
  const [start, setStart] = useState(null);
  const [isAdmin, setisAdmin] = useState(null);
  const [array, setarray] = useState([]);

  let eventadded = false;

  function handelescroll() {
    if (!isBrowser) return 0;

    const target = articleElement.current;
    const position = target.getBoundingClientRect();
    let height = text.current.clientHeight - window.innerHeight;
    setreaded((((position.top - start) * -1) / height) * 100);
  }

  useEffect(() => {
    getfullArtikel(props.id);
    setStart(articleElement.current.getBoundingClientRect().top);
  }, []);

  useEffect(() => {
    setisAdmin(props.isAdmin);
  }, [props.isAdmin]);

  function getfullArtikel(id) {
    axios
      .post("https://spibi-online.de/getfullArtikel", {
        id: id,
        userid: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response.data.artikel);
        setarray(response.data.artikel);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function addView() {
    if (opend < 1) {
      axios
        .post("https://spibi-online.de/addView", {
          id: cookies.get("User_id"),
          artikelid: props.id,
        })
        .then((response) => {
          setopend(1);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }

  function sha256(text) {
    // Convert the text to an array of words
    const words = crypto.enc.Utf8.parse(text);

    // Calculate the SHA-256 hash of the words
    const hash = crypto.SHA256(words);

    // Return the hash as a hexadecimal string
    return hash.toString(crypto.enc.Hex);
  }

  return (
    <div className="bg-base-100">
      <div
        key={0}
        className="progress absolute top-0 z-50 progress-primary bg-primary rounded-none overflow-hidden max-w-full"
        style={{ width: `${readed}%` }}
      ></div>
      <Link key={1} to="/">
        <div className="zurück z-10">
          <span className="material-symbols-outlined text-primary-content rounded-full bg-[#dddddd44]">
            arrow_back
          </span>
        </div>
      </Link>
      {props.isAdmin && (
        <Link
          key={2}
          to={"/" + sha256(props.title.split(" ").join("")) + "bearbeiten"}
          className="fixed z-50 right-0 top-0 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Bearbeiten
        </Link>
      )}
      <div
        key={3}
        onScroll={handelescroll}
        className="relative top-0 w-full overflow-y-scroll overflow-x-hidden z-0 h-[100vh]"
      >
        <div
          ref={text}
          className="Artikelpage-Artikel box-border mx-auto mb-0 !relative"
        >
          <img
            className="Artikelpage-artikel-artikel-img-header bg-cover object-cover"
            src={props.img}
            ref={articleElement}
          ></img>

          <div key={2} className="Artikelpage-artikel-artikel relative">
            <div
              key={1000}
              className="Artikelpage-autor decoration-primary-content"
            >
              Veröffentlicht am{" "}
              {new Intl.DateTimeFormat("de-DE").format(props.time)}
            </div>{" "}
            <div className="absolute top-0 right-4">
              Foto: {props.titletext}
            </div>
            <div
              key={1001}
              className="Artikelpage-title decoration-primary-content "
            >
              {props.title}
            </div>{" "}
            <div className="Artikelpage-artikel-artikel-text text-left">
              {array.length > 0 &&
                array.map((obj, index) => {
                  if (obj.type === "video") {
                    return (
                      <iframe
                        key={index}
                        className="Artikelpage-video"
                        src={`https://www.youtube.com/embed/${obj.link}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    );
                  } else if (obj.type === "link") {
                    return (
                      <a
                        key={index}
                        className="underline !text-blue-700"
                        href={obj.link}
                      >
                        {" " + obj.text + " "}
                      </a>
                    );
                  } else if (obj.type === "text") {
                    if (array[index - 1] != undefined) {
                      if (array[index - 1].type === "link") {
                        return obj.text;
                      }
                    }
                    if (array[index + 1] != undefined) {
                      if (array[index + 1].type === "link") {
                        return obj.text;
                      }
                    }
                    return (
                      <div key={index} className="pt-2 pb-2">
                        {obj.text}
                      </div>
                    );
                  } else if (obj.type === "img") {
                    if (obj.text.length !== 64) {
                      return (
                        <div key={index} className="relative">
                          <img
                            key={index}
                            className="Artikelpage-artikel-artikel-img"
                            src={obj.text}
                          ></img>
                          <p className="Artikelpage-artikel-img-catpion text-center">
                            {obj.caption}{" "}
                          </p>
                          <div className="absolute right-0 bottom-[-1em] text-base-300">
                            Foto: {obj.source}
                          </div>
                        </div>
                      );
                    }
                  } else if (obj.type === "audio") {
                    if (obj.text.length !== 64) {
                      return (
                        <div key={index} className="flex w-full justify-center">
                          <div className="player">
                            <audio controls>
                              <source src={obj.text} />
                            </audio>
                          </div>
                        </div>
                      );
                    }
                  } else if (obj.type === "headline") {
                    return (
                      <div key={index} className="pt-2 pb-2 font-bold">
                        {obj.text}
                      </div>
                    );
                  }
                })}
              {array.length === 0 && (
                <div className="w-full h-[20vh] flex justify-center items-center">
                  <svg
                    width="24"
                    height="24"
                    stroke="#000"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g className="spinner_V8m1">
                      <circle
                        cx="12"
                        cy="12"
                        r="9.5"
                        fill="none"
                        strokeWidth="3"
                      ></circle>
                    </g>
                  </svg>
                </div>
              )}
            </div>
            <br />
            {props.autor != "Anonym" && (
              <div
                key={4}
                className="Artikelpage-autor decoration-primary-content"
              >
                Von {props.autor}{" "}
                {props.weitereAutoren != "" &&
                  props.weitereAutoren != undefined &&
                  "und"}{" "}
                {props.weitereAutoren && props.weitereAutoren}
              </div>
            )}
          </div>
        </div>

        <Kommentare
          colormode={props.colormode}
          kommentare={Kommentaree}
          id={props.id}
          username={props.username}
          colors={props.colors}
          addnotification={props.addnotification}
          isAdmin={props.isAdmin}
        />
        <div className="Profilsettings-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
