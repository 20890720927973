import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
export default function footer(props) {
  return (
    <div className="footer pl-5 pr-10 pt-10 pb-10 bg-base-200 text-base-content">
      <div className="flex flex-col">
        <svg
          id="Ebene_1"
          className="w-20 h-20 mr-10 -rotate-12 fill-current"
          data-name="Ebene 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 49.5 139.1"
        >
          <title>turm</title>
          <path
            d="M617.75,448.55a3.64,3.64,0,0,0-1.9,2.9l-.1,19.6v19h-41l.2-19.2c.1-12.7,0-16.7,0-19.1a4.78,4.78,0,0,0-1.4-3.2c-2.8-1-2.8-2.7-2.8-2.7l6.9.1v-17.4c-1.2-.6-3.6,0-2.8-1a11.5,11.5,0,0,1,2.8-2.4c.1-7,0-16.6,0-18.1,0-1.2,0-2.9,1.1-2.9s1.1,1.4,1.1,2.9V410h4.7c2.6-.6,1.3-1.7,1.9-4.6,1.1-1.9.6,0,0-1.9-.1-1.1,0-4.7,0-6.8.6-.4.7-.1,1.1-.4.1-1.4.1-4.4.1-6.7,0-.9-1.1-1-2.2-1.4v-2.9c0-1,0-1.4.6-1.9,1.1,0,1.7-2.9,1.7-3.8a5.59,5.59,0,0,1,3.3-5.3c1.7-.9,3.4-1.9,3.9-2.8.7,0,.7-3,.7-4.8s0-2.9-.6-2.9a2.07,2.07,0,0,1-.2-3.6,2.06,2.06,0,0,0,.8-2.1c-.1-.7.1-1.1,0-1.4s-.6,1-1.6.5c-.6-.3-.7-.5-.5-.9a1.69,1.69,0,0,1,.7-.6.44.44,0,0,0,.4-.6c-.1-.2-.9-.3-1.2-.7a.68.68,0,0,1-.1-1c.3,0,.1.4.4.6a1.34,1.34,0,0,0,.8.2c.1-.1.2-.1,0-.2-.9-.3-.6-.5.5-.5h.7V353c.1-.1-1.1,0-.6-1a6.13,6.13,0,0,0,.8-.5c.2-.1,0-.5.3-.5s.1.3.3.5c.5.3,0,0,.9.5.6,1-.6.8-.6,1v.5c1.1,0-.3-.2,1-.2h1.7c.6.5-.6.5-1.1.5a3.76,3.76,0,0,1-1.1,0c-.6.6.8.6,1.7,0,.8.1.7.5.8.6s-1.3,1,.2.5c.5-.2-.1.9-.4,1.3-.2.1-.3.2-.4.2-.4-.3-.3,0,0,0h.1c.4.3.8.5-.2.7-.3.1-1.1-.5-1.8-.4-1.6.1-.3.2-.3,1.6,0,1,.2,1.8.8,2.1a2.08,2.08,0,0,1-.3,3.6c-.6,0-.6,1-.6,2.9s0,4.8.6,4.8c.6,1,2.3,1.9,3.9,2.8,2.8,1.5,3.4,3.5,3.4,5.3,0,1,.6,3.8,1.7,3.8.6.5.6,1,.6,2v2.8c-1.1.4-2.3.5-2.2,1.5v6.6c.3.3.6,0,1.1.4v6.7c-.6,1.9-1.1,0,0,1.9.8,3-.7,3.6,1.9,4.3h3.4v-2.9c0-1.4,0-2.9,1.1-2.9s1.1,2.2,1.1,2.9V425c.8.8,1.7,1,2.9,2.3l-3.1.8-.1,17.5,7,.1C620.05,446.55,617.75,448.55,617.75,448.55Z"
            transform="translate(-570.75 -350.95)"
          />
        </svg>
        <p>SpiBi-Online</p>
      </div>

      <div className="mr-10">
        <span className="footer-title">Legales</span>
        <Link to="/Überuns" className="link link-hover">
          Über uns
        </Link>
        <Link to="/Datenschutz" className="link link-hover">
          Datenschutzerklärung
        </Link>
        <a href="https://gis-wf.de/Impressum" className="link link-hover">
          Impressum
        </a>
      </div>
      <div>
        <span className="footer-title">Folgt uns auf:</span>
        <div className="grid grid-flow-col gap-4">
          <a href="https://twitter.com/spibionline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
            </svg>
          </a>
          <a href="https://youtube.de">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
            </svg>
          </a>
          <a href="https://www.facebook.com/people/SPIBI-Gymnasium-im-Schloss/100063587140230/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
            </svg>
          </a>
          <a href="https://instagram.com/spibi.online?igshid=YmMyMTA2M2Y=">
            <svg
              width="24"
              height="24"
              className="w-[24px] h-[24px] fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 122.88 122.88"
            >
              <title>instagram-logo-black-and-white</title>
              <path d="M61.45,0C44.76,0,42.66.07,36.11.37A45.08,45.08,0,0,0,21.2,3.23a29.86,29.86,0,0,0-10.88,7.08,30.26,30.26,0,0,0-7.1,10.88A44.92,44.92,0,0,0,.37,36.11C.08,42.66,0,44.75,0,61.44S.07,80.21.37,86.77a45.08,45.08,0,0,0,2.86,14.91,30.12,30.12,0,0,0,7.08,10.88,30.13,30.13,0,0,0,10.88,7.1,45.17,45.17,0,0,0,14.92,2.85c6.55.3,8.64.37,25.33.37s18.77-.07,25.33-.37a45.17,45.17,0,0,0,14.92-2.85,31.54,31.54,0,0,0,18-18,45.6,45.6,0,0,0,2.86-14.91c.29-6.55.37-8.64.37-25.33s-.08-18.78-.37-25.33a45.66,45.66,0,0,0-2.86-14.92,30.1,30.1,0,0,0-7.09-10.88,29.77,29.77,0,0,0-10.88-7.08A45.14,45.14,0,0,0,86.76.37C80.2.07,78.12,0,61.43,0ZM55.93,11.07h5.52c16.4,0,18.34.06,24.82.36a34,34,0,0,1,11.41,2.11,19,19,0,0,1,7.06,4.6,19.16,19.16,0,0,1,4.6,7.06,34,34,0,0,1,2.11,11.41c.3,6.47.36,8.42.36,24.82s-.06,18.34-.36,24.82a33.89,33.89,0,0,1-2.11,11.4A20.35,20.35,0,0,1,97.68,109.3a33.64,33.64,0,0,1-11.41,2.12c-6.47.3-8.42.36-24.82.36s-18.35-.06-24.83-.36a34,34,0,0,1-11.41-2.12,19,19,0,0,1-7.07-4.59,19,19,0,0,1-4.59-7.06,34,34,0,0,1-2.12-11.41c-.29-6.48-.35-8.42-.35-24.83s.06-18.34.35-24.82a33.7,33.7,0,0,1,2.12-11.41,19,19,0,0,1,4.59-7.06,19.12,19.12,0,0,1,7.07-4.6A34.22,34.22,0,0,1,36.62,11.4c5.67-.25,7.86-.33,19.31-.34Zm38.31,10.2a7.38,7.38,0,1,0,7.38,7.37,7.37,7.37,0,0,0-7.38-7.37ZM61.45,29.89A31.55,31.55,0,1,0,93,61.44,31.56,31.56,0,0,0,61.45,29.89Zm0,11.07A20.48,20.48,0,1,1,41,61.44,20.48,20.48,0,0,1,61.45,41Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
