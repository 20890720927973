import React, { useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

export default function Passwortzurücksetzen(props) {
  const Key = useRef(null);
  const username = useRef(null);
  const password = useRef(null);

  let history = useHistory();
  const cookies = new Cookies();

  function handleSubmit() {
    axios
      .post("https://spibi-online.de/resetpasswordsendemailkey", {
        username: username.current.value,
        password: password.current.value,
        key: Key.current.value,
      })
      .then((response) => {
        if (response.data.msg == null) {
          history.push("/");
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  return (
    <div className="Anmelden">
      <Link to="/Passwortzurücksetzen">
        <div className="zurück z-10">
          <span className="material-symbols-outlined decoration-primary-content">
            arrow_back
          </span>
        </div>
      </Link>
      <div className="Form-card">
        <h3>Passwort zurücksetzen</h3>
        <hr />
        <div className="Anmelden-input-div">
          <label htmlFor="Name">Nutzername:</label>
          <input
            type="text"
            placeholder="Benutzername "
            ref={username}
            className="rounded-full"
          />
        </div>
        <div className="Anmelden-input-div">
          <label htmlFor="Name">Key:</label>
          <input
            type="text"
            placeholder="Benutzername "
            ref={Key}
            className="rounded-full"
          />
        </div>
        <div className="Anmelden-input-div">
          <label htmlFor="Password">Neues Passwort:</label>
          <input
            type="password"
            placeholder="• • • •*"
            ref={password}
            className="rounded-full"
          />
        </div>
        <button
          className="Anmelden-btn text-black !rounded-full"
          onClick={handleSubmit}
        >
          Password ändern
        </button>
      </div>
    </div>
  );
}
