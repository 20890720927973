import React from "react";
import Loadinggif from "./icons/ATB3o.gif";

const Loading = () => {
  return (
    <div className="frontloading">
      <img
        src={Loadinggif}
        loading="lazy"
        alt="this slowpoke moves"
        width="256"
      />
    </div>
  );
};

export default Loading;
