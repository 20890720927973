import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Deleteuseruser from "./deleteuseruser";

const Deleteusers = (props) => {
  const [users, setusers] = useState(null);
  const [Search, setSearch] = useState("");
  const [update, setupdate] = useState(0);

  const cookies = new Cookies();
  let history = useHistory();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getalluser", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          setusers(response.data.users);
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }, []);

  function updatesearch(e) {
    setSearch(e.target.value);
  }

  function handlelöschen(id) {
    if (!window.confirm("Bist du sicher das du den Nutzer löschen willst?"))
      return 0;
    axios
      .post("https://spibi-online.de/deleteuser", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg == null) {
          props.addnotification("Fertig");
          setupdate(update + 1);
          setusers(response.data.users);
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }

  function handleKommentarberechtigungenändern(id) {
    axios
      .post("https://spibi-online.de/kommentarberaechtigungaendern", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg == null) {
          props.addnotification("Fertig");
          setupdate(update + 1);
          setusers(response.data.users);
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }

  return (
    <div className="deleteusers mt-16">
      <input
        type="text"
        placeholder="Nutzer Suche"
        className="Plusfreischalten-search"
        onChange={updatesearch}
      />
      {users != null &&
        users.map((obj, index) => {
          if (obj.name.toLowerCase().includes(Search.toLowerCase())) {
            return (
              <div key={index}>
                <Deleteuseruser
                  name={obj.name}
                  _id={obj._id}
                  email={obj.email}
                  colormode={obj.colormode}
                  registertime={obj.registertime}
                  activated={obj.activated}
                  userkey={obj.key}
                  handlelöschen={handlelöschen}
                  Komentareschreiben={obj.Komentareschreiben}
                  handleKommentarberechtigungenändern={
                    handleKommentarberechtigungenändern
                  }
                />
              </div>
            );
          }
          // else if (Search === "") {
          //   return (
          //     <Deleteuseruser
          //       name={obj.name}
          //       _id={obj._id}
          //       handlelöschen={handlelöschen}
          //     />
          //   );
          // }
        })}
    </div>
  );
};

export default Deleteusers;
