import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Footer from "./../footer";
import Collapsible from "react-collapsible";

import LightMode from "./../img/spibi-online.de_light_mode_1.webp";
import DarkMode from "./../img/spibi-online.de_dark_mode_2.webp";

import Anleitungen from "./Anleitungen";

export default function Profilsettings(props) {
  const cookies = new Cookies();
  let history = useHistory();

  const [isMod, setisMod] = useState(false);
  const [isPlus, setisPlus] = useState(false);
  const [currentsettings, setcurrentsettings] = useState("");

  const [phoneleft, setphoneleft] = useState(false);

  const [img, setimg] = useState(null);
  const imgref = useRef(null);
  const img2 = useRef(null);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getProfilepicture", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response);
        setimg(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function resizeMe(img) {
    let max_width = 350;
    let max_height = 350;
    let preview = document.getElementById("preview");

    var canvas = document.createElement("canvas");

    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    preview.appendChild(canvas);
    return canvas.toDataURL("image/jpeg", 0.7);
  }

  function handleimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      var blob = new Blob([upload.target.result]);
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(blob);
      var image = new Image();
      image.src = blobURL;
      image.onload = function () {
        var resized = resizeMe(image);
        setimg(resized);
        axios
          .post("https://spibi-online.de/updateProfilepicture", {
            id: cookies.get("User_id"),
            img: resized,
          })
          .then((response) => {})
          .catch((error) => {
            console.log(error, "error");
          });
      };
    };
    reader.readAsArrayBuffer(file);
  }

  // function submitimg() {
  //   if (img == null) {
  //     props.addnotification("Bitte wähle ein Bild aus!");
  //     return;
  //   }
  //   axios
  //     .post("https://spibi-online.de/updateProfilepicture", {
  //       id: cookies.get("User_id"),
  //       img: img,
  //     })
  //     .then((response) => {})
  //     .catch((error) => {
  //       console.log(error, "error");
  //     });
  // }

  function resetimg() {
    axios
      .post("https://spibi-online.de/updateProfilepicture", {
        id: cookies.get("User_id"),
        img: null,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error, "error");
      });
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isMod", { id: cookies.get("User_id") })
      .then((response) => {
        if (response.data == true) {
          setisMod(response.data);
        } else {
          setisMod(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    axios
      .post("https://spibi-online.de/isPlus", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data === true) {
          setisPlus(true);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  const [description, setdescription] = useState("bug");
  const [content, setcontent] = useState(null);
  const [selectColor, setselectColor] = useState({ color: "#111" });

  function handledescription(e) {
    setdescription(e.target.value);
    switch (e.target.value) {
      case "bug":
        setselectColor({ color: "#111" });
        break;
      case "positive":
        setselectColor({ color: "#0dd60d" });
        break;
      case "negative":
        setselectColor({ color: "#f21111" });
        break;
      case "improvement": {
        setselectColor({ color: "#0da7d6" });
      }
    }
  }

  function handleSubmitFeddback(e) {
    e.preventDefault();
    if (description == null || content == null) {
      props.addnotification("Bitte fülle alle Felder aus!");
      return;
    }
    axios
      .post("https://spibi-online.de/writeMessage", {
        userid: cookies.get("User_id"),
        type: "userfeedback",
        description: description,
        content: content,
      })
      .then((response) => {
        if (response.data.message != null) {
          props.addnotification(response.data.message);
        } else {
          props.addnotification("Danke für dein Feedback!");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function handlecontent(e) {
    setcontent(e.target.value);
  }

  //Changepassword
  const [oldPassword, setoldPassword] = useState(null);
  const [newPassword, setnewPassword] = useState(null);

  function Changepassword() {
    console.log(newPassword.length);
    if (newPassword.length > 7) {
      axios
        .post("https://spibi-online.de/Changepassword", {
          password: oldPassword,
          newPassword: newPassword,
          id: cookies.get("User_id"),
        })
        .then((response) => {
          if (response.data.msg != null) {
            props.addnotification(response.data.msg);
          } else {
            props.addnotification("Dein Passwort wurde geändert", "green");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      props.addnotification(
        "Dein Passwort muss mindestens 8 Zeichen lang sein! #792"
      );
    }
  }

  //Changename
  const [Password, setPassword] = useState(null);
  const [newName, setnewName] = useState(null);

  function Changename() {
    axios
      .post("https://spibi-online.de/Changename", {
        Password: Password,
        newname: newName,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
          props.addnotification("Dein Name wurde geändert", "green");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  //Ersteinugsbild
  const [colormode, setcolormode] = useState(props.colormode);

  useEffect(() => {
    setcolormode(props.colormode);
  }, [colormode]);

  function changecolormode(color) {
    setcolormode(color);
    props.changecolormode(color);
  }

  //Help

  const [content2, setcontent2] = useState(null);
  const [selectColor2, setselectColor2] = useState({ color: "#ffab19" });

  function handlehelpsubmit(e) {
    e.preventDefault();
    if (content2 == null) {
      props.addnotification("Bitte fülle alle Felder aus!");
      return;
    }
    axios
      .post("https://spibi-online.de/writeMessage", {
        userid: cookies.get("User_id"),
        type: "userQuestion",
        description: "",
        content: content2,
      })
      .then((response) => {
        if (response.data.message == "ok") {
          props.addnotification("Deine Frage wurde gesendet");
        } else {
          props.addnotification("Dein Frage konnte nicht gesendet werden #575");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function handlecontent2(e) {
    setcontent2(e.target.value);
  }

  // massages

  const [messages, setMessages] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    getMessages();
  }, []);

  function getMessages() {
    axios
      .post("https://spibi-online.de/getMessages", {
        userId: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response.data.messages);
        setMessages(response.data.messages);
        response.data.messages.map((obj, index) => {
          if (obj.response !== "") {
            console.log(obj.response);
            answers[index] = obj.response;
          }
          setAnswers([...answers]);
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function getColor(value) {
    switch (value) {
      case "bug":
        return { color: "#ffab19" };
      case "positive":
        return { color: "#0dd60d" };
      case "negative":
        return { color: "#f21111" };
      case "improvement":
        return { color: "#0da7d6" };
    }
    return { color: "#111" };
  }

  function getName(value) {
    switch (value) {
      case "bug":
        return "Bug";
      case "positive":
        return "Positives Feedback";
      case "negative":
        return "Negatives Feedback";
      case "improvement":
        return "Verbesserungsvorschlag";
      case "userfeedback":
        return "Feedback";
      case "userQuestion":
        return "Frage";
    }
    return value;
  }

  function answer(id, index) {
    axios
      .post("https://spibi-online.de/answerMessage", {
        userId: cookies.get("User_id"),
        messageId: id,
        response: answers[index],
      })
      .then((response) => {
        getMessages();
        console.log(response);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function handleAnswerChange(index, e) {
    answers[index] = e.target.value;
    setAnswers([...answers]);
  }

  // Account löschen beantragen

  function requestdeleteAccount() {
    if (window.confirm("Willst du wirklich deinen Account löschen?")) {
      axios
        .post("https://spibi-online.de/requestdeleteAccount", {
          id: cookies.get("User_id"),
        })
        .then((response) => {
          if (response.data.msg == null) {
            props.addnotification(
              "Deine Anfrage wurde an den Support geschickt.",
              "green"
            );
          } else {
            props.addnotification(response.data.msg);
          }
        });
    } else {
      props.addnotification("Schön das du bleibst! :)");
    }
  }

  // Meine Artikel
  const [MyArticlesArray, setMyArticlesArray] = useState([]);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/Myarticles", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          setMyArticlesArray(response.data.artikel);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);
  return (
    <div
      className={
        "Profilsettings text-primary-content " +
        (isMod ? "!h-[2300px]" : "!h-[1520px]")
      }
    >
      <div className="only-desktop">
        <div className="bg-base-100 absolute w-[96vw] ml-[2vw] rounded-lg">
          <ul className="menu bg-base-100 w-80 rounded-l-lg !ml-0 m-2 pr-2 text-xl border-r-4 border-base-300">
            <div className="w-full flex justify-center items-center flex-col mb-4">
              {img != "Unknown" && img != " " && img != "" ? (
                <img
                  src={img}
                  className="w-[125px] h-[125px] rounded-full m-2"
                />
              ) : (
                <span
                  alt=""
                  className="material-symbols-outlined w-[125px] h-[125px] text-[125px] rounded-full m-2 padding-[1vh]"
                >
                  account_circle
                </span>
              )}
              <div className="text-3xl">{props.name}</div>
              <div className="text-lg mt-2">{props.email}</div>
              <div className="text-lg -mt-2">
                Registriert am
                {(function () {
                  let date = new Date(props.registertime);
                  return (
                    " " +
                    date.getDate() +
                    "." +
                    (date.getMonth() + 1) +
                    "." +
                    date.getFullYear()
                  );
                })()}
              </div>
              <div className="text-base mt-2">v.4.5.0 - 10.4.2023</div>
            </div>

            <li className="menu-title">
              <div className="!text-2xl !text-primary-content">
                Kontoeinstellungen
              </div>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Passwordändern");
                }}
              >
                Passwort ändern{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("nameändern");
                }}
              >
                Name ändern{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Erscheinungsbild");
                }}
              >
                Erscheinungsbild{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            {/* {!isPlus && (
            <li className="hover-bordered">
              <a className="!rounded-r-lg !rounded-l-none relative !pl-3">
                SpiBi-Plus{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">chevron_right</span>
                </div>
              </a>
            </li>
          )} */}
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Profilbild");
                }}
              >
                Profilbild{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="menu-title">
              <span className="!text-2xl !text-primary-content">Artikel</span>
            </li>
            {/* <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Meineartikel");
                }}
              >
                Meine Artikel{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li> */}
            <li className="hover-bordered">
              <Link
                to="/createartikel"
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
              >
                Artikel Erstellen
              </Link>
            </li>
            <li className="menu-title">
              <span className="!text-2xl !text-primary-content">
                Support & Feedback
              </span>
            </li>

            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Feedback");
                }}
              >
                Feedback{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Hilfe");
                }}
              >
                Hilfe{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Anleitung");
                }}
              >
                Anleitung{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Mitteilungen");
                }}
              >
                Mitteilungen{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            <li className="hover-bordered">
              <a
                className="!rounded-r-lg !rounded-l-none relative !pl-3"
                onClick={() => {
                  setcurrentsettings("Accountlöschen");
                }}
              >
                Daten Löschen beantragen{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </div>
              </a>
            </li>
            {isMod && (
              <div>
                <li className="menu-title">
                  <span className="!text-2xl !text-primary-content">
                    Admintools
                  </span>
                </li>

                {/* <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/bearbeiten"
                  >
                    Kommentare Moderieren
                  </Link>
                </li> */}
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/selectArtikel"
                  >
                    Artikel bestätigen
                    <span className="Profilsettings-open-ArtikelSelections">
                      {props.ArtikelVorschlaege}
                    </span>
                  </Link>
                </li>
                {/* <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/Plusfreischlten"
                  >
                    Benuzer Freischalten
                  </Link>
                </li> */}

                {/* <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/CodesErstellen"
                  >
                    Zugangscodes erstellen
                  </Link>
                </li> */}

                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/createbanner"
                  >
                    Banner erstellen
                  </Link>
                </li>
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/dealeatebanner"
                  >
                    Banner Löschen
                  </Link>
                </li>

                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/Mitgliederverwalten"
                  >
                    Spibimitglieder verwalten
                  </Link>
                </li>
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/Deleteuser"
                  >
                    Nutzer Verwalten
                  </Link>
                </li>
                <li className="hover-bordered mb-20">
                  <Link
                    className="!rounded-none relative"
                    to="/Changepasswordadmin"
                  >
                    Nutzerpassworter ändern
                  </Link>
                </li>

                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/Frageerstellen"
                  >
                    Fragen erstellen
                  </Link>
                </li>
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/createevents"
                  >
                    Events erstellen
                  </Link>
                </li>
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/dealeateevent"
                  >
                    Events Löschen
                  </Link>
                </li>
                <li className="hover-bordered">
                  <Link
                    className="!rounded-r-lg !rounded-l-none relative !pl-3"
                    to="/addOldSpibis"
                  >
                    Alte Spibis hinzufügen
                  </Link>
                </li>
              </div>
            )}
          </ul>
        </div>
        <div className="Profilsettings-settings-settings h-[870px] bg-base-100 absolute mt-[1vw] right-[3vw] flex justify-center items-center">
          {(function () {
            if (currentsettings === "Passwordändern") {
              return (
                <div className="w-96 h-96 bg-base-100 p-10 border border-current rounded-lg ">
                  <h1 className="text-2xl">Password ändern</h1>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Altes Passwort:</span>
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setoldPassword(e.target.value)}
                      placeholder="• • • •"
                      className="input w-full border border-current !rounded-full"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Neues Passwort:</span>
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setnewPassword(e.target.value)}
                      placeholder="• • • •"
                      className="input w-full  border border-current !rounded-full"
                    />
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <button
                      className="btn btn-primary mt-10 items-center !rounded-full w-52 text-base-100"
                      onClick={Changepassword}
                    >
                      Passwort Ändern
                    </button>
                  </div>
                </div>
              );
            } else if (currentsettings === "nameändern") {
              return (
                <div className="w-96 h-96 bg-base-100 p-10 border border-current rounded-lg">
                  <h1 className="text-2xl">Name ändern</h1>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Neuer Name:</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setnewName(e.target.value)}
                      placeholder="Max Mustermann"
                      className="input w-full  border border-current !rounded-full"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Passwort:</span>
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="• • • •"
                      className="input w-full  border border-current !rounded-full"
                    />
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <button
                      className="btn btn-primary mt-10 !rounded-full w-52 text-base-100"
                      onClick={Changename}
                    >
                      Name ändern
                    </button>
                  </div>
                </div>
              );
            } else if (currentsettings == "Erscheinungsbild") {
              return (
                <div className="flex flex-row w-full lg:flex-row">
                  <div
                    className="grid flex-grow h-[60vh] card bg-base-100 rounded-box place-items-center"
                    onClick={() => changecolormode("cmyk")}
                  >
                    <img src={LightMode} loading="lazy" className="w-[15vw]" />
                    <input
                      type="radio"
                      className="radio mt-[5vh]"
                      readOnly
                      onClick={() => changecolormode("cmyk")}
                      checked={colormode == "cmyk"}
                    />
                  </div>

                  <div
                    className="grid flex-grow h-[60vh] card bg-base-100 rounded-box place-items-center"
                    onClick={() => changecolormode("dark")}
                  >
                    <img src={DarkMode} loading="lazy" className="w-[15vw]" />
                    <input
                      type="radio"
                      className="radio mt-[5vh]"
                      readOnly
                      onClick={() => changecolormode("dark")}
                      checked={colormode == "dark"}
                    />
                  </div>
                </div>
              );
            } else if (currentsettings == "Profilbild") {
              return (
                <div className="w-96 h-96 bg-base-100 pl-10 pr-10 border border-current rounded-lg">
                  <div id="preview" style={{ display: "none" }}></div>
                  {img != "Unknown" && img != " " && img != "" ? (
                    <div className="w-full h-[230px] flex justify-center items-center">
                      <img
                        src={img}
                        className="w-[220px] h-[220px] rounded-full m-2"
                      />
                    </div>
                  ) : (
                    <div className="w-full h-[230px] flex justify-center items-center">
                      <span className="material-symbols-outlined w-[220px] h-[220px] text-[220px] rounded-full m-auto padding-[1vh]">
                        account_circle
                      </span>
                    </div>
                  )}
                  <div className="upload-btn-wrapper mt-2">
                    <button className="btn btn-primary w-full !rounded-full text-base-100">
                      <div
                        ref={imgref}
                        className="bg-primary text-base-100 h-full flex justify-center items-center "
                      >
                        <div>Bild hochladen</div>
                      </div>
                    </button>

                    <input
                      style={{
                        width: "90vw",
                        height: "100%",
                      }}
                      ref={img2}
                      type="file"
                      name="file"
                      className="Artikelerstellen-img cursor-pointer !rounded-full"
                      id="file"
                      onChange={handleimg}
                      encType="multipart/form-data"
                      required
                    />
                  </div>
                  <br />
                  <button
                    className="btn btn-primary w-full text-base-100 !rounded-full"
                    onClick={resetimg}
                  >
                    Zurücksetzen
                  </button>
                </div>
              );
            } else if (currentsettings === "Feedback") {
              return (
                <div className="w-96 h-96 bg-base-100 pl-10 pt-4 pr-10 border border-current rounded-lg">
                  <h2 className="text-2xl">Feedback</h2>
                  <div className="form-control w-full ">
                    <label className="label pl-0">
                      Welche Art von Feedback?
                    </label>
                    <select
                      className="select select-bordered text-base-content"
                      onChange={handledescription}
                    >
                      <option value="bug">Fehler</option>
                      <option value="positive">Positives Feedback</option>
                      <option value="negative">Negatives Feedback</option>
                      <option value="improvement">
                        Verbesserungs Vorschlag
                      </option>
                    </select>
                  </div>
                  <div className="form-control">
                    <label className="label pl-0">
                      Teile uns mit was du denkst oder was dir aufgefallen ist:
                    </label>
                    <textarea
                      className="textarea focus:bg-base-100 textarea-bordered h-20 !w-full border-2 border-solid"
                      onChange={handlecontent}
                      placeholder="Deine Nachricht an uns"
                    ></textarea>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <button
                      className="btn btn-primary !rounded-full w-52 text-base-100"
                      onClick={handleSubmitFeddback}
                    >
                      Senden
                    </button>
                  </div>
                </div>
              );
            } else if (currentsettings === "Hilfe") {
              return (
                <div className="w-96 h-96 bg-base-100 pl-10 pt-4 pr-10 border border-current rounded-lg">
                  <h2 className="text-2xl">Du brauchst Hilfe?</h2>
                  <div className="form-control w-full ">
                    <label className="label pl-0">
                      Schreibe uns deine Frage und wir melden uns bei dir:
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="label pl-0">Deine Frage an uns:</label>
                    <textarea
                      className="textarea focus:bg-base-100 textarea-bordered h-20 !w-full border-2 border-solid"
                      onChange={handlecontent2}
                      placeholder="Deine Nachricht an uns"
                    ></textarea>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <button
                      className="btn btn-primary !rounded-full w-52 text-base-100"
                      onClick={handlehelpsubmit}
                    >
                      Senden
                    </button>
                  </div>
                </div>
              );
            } else if (currentsettings === "Anleitung") {
              return <Anleitungen />;
            } else if (currentsettings === "Mitteilungen") {
              return (
                <div className="messages h-full text-base-content w-full bg-base-100 overflow-y-scroll">
                  <h2>Mitteilungen</h2>
                  <br />
                  {messages.map((obj, index) => {
                    return (
                      <div className="message-box bg-base-200" key={index}>
                        <h3>{getName(obj.type)}</h3>
                        <div style={getColor(obj.description)}>
                          {getName(obj.description)}
                        </div>
                        <div>{obj.content}</div>
                        {obj.response !== "" && obj.forAdmin == true && (
                          <div>
                            <textarea
                              value={answers[index]}
                              placeholder="Antwort"
                              onChange={(e) => {
                                handleAnswerChange(index, e);
                              }}
                            ></textarea>
                            <button
                              onClick={() => {
                                answer(obj._id, index);
                              }}
                            >
                              Ändern
                            </button>
                          </div>
                        )}
                        {obj.response === "" && obj.forAdmin === true && (
                          <div>
                            <textarea
                              value={answers[index]}
                              placeholder="Antwort"
                              onChange={(e) => {
                                handleAnswerChange(index, e);
                              }}
                            />
                            <button
                              onClick={() => {
                                answer(obj._id, index);
                              }}
                            >
                              Antworten
                            </button>
                          </div>
                        )}
                        {obj.response === "" &&
                          obj.forAdmin === false &&
                          obj.type === "userFeedback" && (
                            <div className="message-answer">
                              Keine Rückmeldung bisher.
                            </div>
                          )}
                        {obj.response === "" &&
                          obj.forAdmin === false &&
                          obj.type === "userQuestion" && (
                            <div className="message-answer">
                              Keine Antwort bisher.
                            </div>
                          )}
                        {obj.response !== "" && obj.forAdmin === false && (
                          <div className="message-answer">{obj.response}</div>
                        )}
                      </div>
                    );
                  })}
                  {messages.length === 0 && (
                    <div className="no-message">Keine Mitteilungen</div>
                  )}
                </div>
              );
              // } else if (currentsettings === "Meineartikel") {
              //   return (
              //     <div className="h-full w-full overflow-y-scroll">
              //       {MyArticlesArray &&
              //         MyArticlesArray.map((obj, index) => {
              //           console.log(obj);
              //           return (
              //             <div className="leftcard leftcard-side bg-base-100 shadow-xl w-11/12 mb-4">
              //               <figure>
              //                 <img src={obj.img} alt="img" />
              //               </figure>
              //               <div className="leftcard-body p-4">
              //                 <h2 className="leftcard-title text-2xl">
              //                   {obj.name}
              //                 </h2>
              //                 <p>Erschienen am {obj.time}</p>
              //               </div>
              //             </div>
              //           );
              //         })}
              //     </div>
              //   );
            } else if (currentsettings === "Accountlöschen") {
              return (
                <div className="w-96 h-96 bg-base-100 p-10 border border-current rounded-lg">
                  <h1 className="text-2xl">Account Löschen</h1>
                  <p>
                    Bist du sicher, dass du dein Konto löschen möchtest? Dieser
                    Vorgang kann nicht rückgängig gemacht werden und alle deine
                    Kontodaten werden dauerhaft gelöscht. Wenn du trotzdem
                    fortfahren möchtest, klick auf "Account Löschen beantragen".
                  </p>
                  <div className="w-full flex justify-center items-center">
                    <button
                      className="btn btn-primary mt-10 !rounded-full w-52 text-base-100"
                      onClick={requestdeleteAccount}
                    >
                      Account löschen
                    </button>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="h-full w-full flex justify-center items-center">
                  <div className="w-full flex justify-center items-center flex-col mb-4">
                    {img != "Unknown" && img != " " && img != "" ? (
                      <img
                        src={img}
                        className="w-[300px] h-[300px] rounded-full m-2"
                      />
                    ) : (
                      <span className="material-symbols-outlined w-[300px] h-[300px] text-[300px] rounded-full m-2 padding-[1vh]">
                        account_circle
                      </span>
                    )}
                    <div className="text-3xl">{props.name}</div>
                    <div className="text-lg mt-2">{props.email}</div>
                    <div className="text-lg -mt-2">
                      Registriert am
                      {(function () {
                        let date = new Date(props.registertime);
                        return (
                          " " +
                          date.getDate() +
                          "." +
                          (date.getMonth() + 1) +
                          "." +
                          date.getFullYear()
                        );
                      })()}
                    </div>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </div>

      <div className="only-phone">
        <div className="w-[96vw] bg-base-100 ml-[2vw] !rounded-lg pb-4 relative overflow-x-hidden">
          <div
            className={
              phoneleft
                ? "Profilsettings-phone-animation translate-x-[-96vw]"
                : "Profilsettings-phone-animation"
            }
          >
            <div className="h-[80vh] overflow-y-scroll">
              <ul className="menu bg-base-100 text-xl border-base-300 rounded-lg ">
                {!phoneleft && (
                  <div className="w-full flex justify-center items-center flex-col mb-4">
                    {img != "Unknown" && img != " " && img != "" ? (
                      <img
                        src={img}
                        className="w-[125px] h-[125px] rounded-full m-2"
                      />
                    ) : (
                      <span className="material-symbols-outlined w-[125px] h-[125px] text-[125px] rounded-full m-2 padding-[1vh]">
                        account_circle
                      </span>
                    )}
                    <div className="text-3xl">{props.name}</div>
                    <div className="text-lg mt-2">{props.email}</div>
                    <div className="text-lg -mt-2">
                      Registriert am
                      {(function () {
                        let date = new Date(props.registertime);
                        return (
                          " " +
                          date.getDate() +
                          "." +
                          (date.getMonth() + 1) +
                          "." +
                          date.getFullYear()
                        );
                      })()}
                    </div>
                  </div>
                )}
                <li className="menu-title">
                  <div className="!text-2xl !text-primary-content">
                    Kontoeinstellungen
                  </div>
                </li>
                <li className="hover-bordered">
                  <a
                    className=" !rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Passwordändern");
                    }}
                  >
                    Passwort ändern{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("nameändern");
                    }}
                  >
                    Name ändern{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Erscheinungsbild");
                    }}
                  >
                    Erscheinungsbild{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                {/* {!isPlus && (
            <li className="hover-bordered">
              <a className="!rounded-r-lg !rounded-l-none relative !pl-3">
                SpiBi-Plus{" "}
                <div className="absolute right-0 flex justify-center">
                  <span className="material-symbols-outlined">chevron_right</span>
                </div>
              </a>
            </li>
          )} */}
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Profilbild");
                    }}
                  >
                    Profilbild{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="menu-title">
                  <span className="!text-2xl !text-primary-content">
                    Artikel
                  </span>
                </li>
                {/* <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Meineartikel");
                    }}
                  >
                    Meine Artikel{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li> */}
                <li className="hover-bordered">
                  <Link to="/createartikel" className=" relative">
                    Artikel Erstellen
                  </Link>
                </li>
                <li className="menu-title">
                  <span className="!text-2xl !text-primary-content">
                    Support & Feedback
                  </span>
                </li>

                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Feedback");
                    }}
                  >
                    Feedback{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Hilfe");
                    }}
                  >
                    Hilfe{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Anleitung");
                    }}
                  >
                    Anleitung{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Mitteilungen");
                    }}
                  >
                    Mitteilungen{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>
                <li className="hover-bordered">
                  <a
                    className="!rounded-none relative"
                    onClick={() => {
                      setphoneleft(true);
                      setcurrentsettings("Accountlöschen");
                    }}
                  >
                    Daten Löschen beantragen{" "}
                    <div className="absolute right-0 flex justify-center">
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </a>
                </li>

                {isMod && (
                  <div>
                    <li className="menu-title">
                      <span className="!text-2xl !text-primary-content">
                        Admintools
                      </span>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/Frageerstellen"
                      >
                        Fragen erstellen
                      </Link>
                    </li>
                    {/* <li className="hover-bordered">
                      <Link className="!rounded-none relative" to="/bearbeiten">
                        Kommentare Moderieren
                      </Link>
                    </li> */}
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/selectArtikel"
                      >
                        Artikel bestätigen
                        <span className="Profilsettings-open-ArtikelSelections">
                          {props.ArtikelVorschlaege}
                        </span>
                      </Link>
                    </li>
                    {/* <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/Plusfreischlten"
                      >
                        Benuzer Freischalten
                      </Link>
                    </li> */}
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/addOldSpibis"
                      >
                        Alte Spibis hinzufügen
                      </Link>
                    </li>
                    {/* <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/CodesErstellen"
                      >
                        Zugangscodes erstellen
                      </Link>
                    </li> */}

                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/createbanner"
                      >
                        Banner erstellen
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/dealeatebanner"
                      >
                        Banner Löschen
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/createevents"
                      >
                        Events erstellen
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/dealeateevent"
                      >
                        Events Löschen
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link className="!rounded-none relative" to="/Deleteuser">
                        Nutzer Verwalten
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/Mitgliederverwalten"
                      >
                        Mitglieder verwalten
                      </Link>
                    </li>
                    <li className="hover-bordered">
                      <Link
                        className="!rounded-none relative"
                        to="/Changepasswordadmin"
                      >
                        Nutzerpassworter ändern
                      </Link>
                    </li>
                  </div>
                )}
              </ul>
            </div>
            <div className="overflow-y-hidden !rounded-lg fixed bg-base-100 absolute top-0 h-[80vh] w-[96vw] left-full flex justify-center items-center">
              {phoneleft && (
                <div
                  className="z-10 absolute top-4 left-4 cursor-pointer"
                  onClick={() => setphoneleft(!phoneleft)}
                >
                  <span className="material-symbols-outlined decoration-primary-content">
                    arrow_back
                  </span>
                </div>
              )}
              {(function () {
                if (currentsettings === "Passwordändern") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 p-10 border border-current rounded-lg ">
                      <h1 className="text-2xl">Password ändern</h1>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Altes Passwort:</span>
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setoldPassword(e.target.value)}
                          placeholder="• • • •"
                          className="input w-full  border border-current !rounded-full"
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Neues Passwort:</span>
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setnewPassword(e.target.value)}
                          placeholder="• • • •"
                          className="input w-full  border border-current !rounded-full"
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="btn btn-primary mt-10 items-center !rounded-full w-52 text-base-100"
                          onClick={Changepassword}
                        >
                          Passwort Ändern
                        </button>
                      </div>
                    </div>
                  );
                } else if (currentsettings === "nameändern") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 p-10 border border-current rounded-lg">
                      <h1 className="text-2xl">Name ändern</h1>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Neuer Name:</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setnewName(e.target.value)}
                          placeholder="Max Mustermann"
                          className="input w-full  border border-current !rounded-full"
                        />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Passwort:</span>
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="• • • •"
                          className="input w-full  border border-current !rounded-full"
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="btn btn-primary mt-10 !rounded-full w-52 text-base-100"
                          onClick={Changename}
                        >
                          Name ändern
                        </button>
                      </div>
                    </div>
                  );
                } else if (currentsettings == "Erscheinungsbild") {
                  return (
                    <div className="flex flex-row w-full lg:flex-row">
                      <div
                        className="grid flex-grow h-[60vh] card bg-base-100 rounded-box place-items-center"
                        onClick={() => changecolormode("cmyk")}
                      >
                        <img
                          src={LightMode}
                          loading="lazy"
                          className="w-[15vw]"
                        />
                        <input
                          type="radio"
                          className="radio mt-[5vh]"
                          readOnly
                          onClick={() => changecolormode("cmyk")}
                          checked={colormode == "cmyk"}
                        />
                      </div>

                      <div
                        className="grid flex-grow h-[60vh] card bg-base-100 rounded-box place-items-center"
                        onClick={() => changecolormode("dark")}
                      >
                        <img
                          src={DarkMode}
                          loading="lazy"
                          className="w-[15vw]"
                        />
                        <input
                          type="radio"
                          className="radio mt-[5vh]"
                          readOnly
                          onClick={() => changecolormode("dark")}
                          checked={colormode == "dark"}
                        />
                      </div>
                    </div>
                  );
                } else if (currentsettings == "Profilbild") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 pl-10 pr-10 border border-current rounded-lg">
                      <div id="preview" style={{ display: "none" }}></div>
                      {img != "Unknown" && img != " " && img != "" ? (
                        <div className="w-full h-[230px] flex justify-center items-center">
                          <img
                            src={img}
                            className="w-[220px] h-[220px] rounded-full m-2"
                          />
                        </div>
                      ) : (
                        <div className="w-full h-[230px] flex justify-center items-center">
                          <span className="material-symbols-outlined w-[220px] h-[220px] text-[220px] rounded-full m-auto padding-[1vh]">
                            account_circle
                          </span>
                        </div>
                      )}
                      <div className="upload-btn-wrapper mt-2">
                        <button className="btn btn-primary w-full !rounded-full text-base-100">
                          <div
                            ref={imgref}
                            className="bg-primary  h-full flex justify-center items-center "
                          >
                            <div>Bild hochladen</div>
                          </div>
                        </button>

                        <input
                          style={{
                            width: "90vw",
                            height: "100%",
                          }}
                          ref={img2}
                          type="file"
                          name="file"
                          className="Artikelerstellen-img cursor-pointer !rounded-full"
                          id="file"
                          onChange={handleimg}
                          encType="multipart/form-data"
                          required
                        />
                      </div>
                      <br />
                      <button
                        className="btn btn-primary w-full text-base-100 !rounded-full"
                        onClick={resetimg}
                      >
                        Zurücksetzen
                      </button>
                    </div>
                  );
                } else if (currentsettings === "Feedback") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 pl-10 pt-4 pr-10 border border-current rounded-lg">
                      <h2 className="text-2xl">Feedback</h2>
                      <div className="form-control w-full ">
                        <label className="label pl-0">
                          Welche Art von Feedback?
                        </label>
                        <select
                          className="select select-bordered text-base-content"
                          onChange={handledescription}
                        >
                          <option value="bug">Fehler</option>
                          <option value="positive">Positives Feedback</option>
                          <option value="negative">Negatives Feedback</option>
                          <option value="improvement">
                            Verbesserungs Vorschlag
                          </option>
                        </select>
                      </div>
                      <div className="form-control">
                        <label className="label pl-0">
                          Teile uns mit was du denkst oder was dir aufgefallen
                          ist:
                        </label>
                        <textarea
                          className="textarea focus:bg-base-100 textarea-bordered h-20 !w-full border-2 border-solid"
                          onChange={handlecontent}
                          placeholder="Deine Nachricht an uns"
                        ></textarea>
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="btn btn-primary !rounded-full w-52 text-base-100"
                          onClick={handleSubmitFeddback}
                        >
                          Senden
                        </button>
                      </div>
                    </div>
                  );
                } else if (currentsettings === "Hilfe") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 pl-10 pt-4 pr-10 border border-current rounded-lg">
                      <h2 className="text-2xl">Du brauchst Hilfe?</h2>
                      <div className="form-control w-full ">
                        <label className="label pl-0">
                          Schreibe uns deine Frage und wir melden uns bei dir:
                        </label>
                      </div>
                      <div className="form-control">
                        <label className="label pl-0">
                          Deine Frage an uns:
                        </label>
                        <textarea
                          className="textarea focus:bg-base-100 textarea-bordered h-20 !w-full border-2 border-solid"
                          onChange={handlecontent2}
                          placeholder="Deine Nachricht an uns"
                        ></textarea>
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="btn btn-primary !rounded-full w-52 text-base-100"
                          onClick={handlehelpsubmit}
                        >
                          Senden
                        </button>
                      </div>
                    </div>
                  );
                } else if (currentsettings === "Anleitung") {
                  return <Anleitungen />;
                } else if (currentsettings === "Mitteilungen") {
                  return (
                    <div className="messages h-full text-base-content w-full bg-base-100 overflow-y-scroll">
                      <h2>Mitteilungen</h2>
                      <br />
                      {messages.map((obj, index) => {
                        return (
                          <div className="message-box bg-base-200" key={index}>
                            <h3>{getName(obj.type)}</h3>
                            <div style={getColor(obj.description)}>
                              {getName(obj.description)}
                            </div>
                            <div>{obj.content}</div>
                            {obj.response !== "" && obj.forAdmin == true && (
                              <div>
                                <textarea
                                  value={answers[index]}
                                  placeholder="Antwort"
                                  onChange={(e) => {
                                    handleAnswerChange(index, e);
                                  }}
                                ></textarea>
                                <button
                                  onClick={() => {
                                    answer(obj._id, index);
                                  }}
                                >
                                  Ändern
                                </button>
                              </div>
                            )}
                            {obj.response === "" && obj.forAdmin === true && (
                              <div>
                                <textarea
                                  value={answers[index]}
                                  placeholder="Antwort"
                                  onChange={(e) => {
                                    handleAnswerChange(index, e);
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    answer(obj._id, index);
                                  }}
                                >
                                  Antworten
                                </button>
                              </div>
                            )}
                            {obj.response === "" &&
                              obj.forAdmin === false &&
                              obj.type === "userFeedback" && (
                                <div className="message-answer">
                                  Keine Rückmeldung bisher.
                                </div>
                              )}
                            {obj.response === "" &&
                              obj.forAdmin === false &&
                              obj.type === "userQuestion" && (
                                <div className="message-answer">
                                  Keine Antwort bisher.
                                </div>
                              )}
                            {obj.response !== "" && obj.forAdmin === false && (
                              <div className="message-answer">
                                {obj.response}
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {messages.length === 0 && (
                        <div className="no-message">Keine Mitteilungen</div>
                      )}
                    </div>
                  );
                  // } else if (currentsettings === "Meineartikel") {
                  //   return (
                  //     <div className="h-full w-full overflow-y-scroll pt-10 pb-20">
                  //       {MyArticlesArray &&
                  //         MyArticlesArray.map((obj, index) => {
                  //           console.log(obj);
                  //           return (
                  //             <div className="leftcard m-4 rounded-lg leftcard-side bg-base-100 shadow-xl w-11/12 mb-4">
                  //               <figure>
                  //                 <img src={obj.img} alt="img" />
                  //               </figure>
                  //               <div className="leftcard-body p-4">
                  //                 <h2 className="leftcard-title text-2xl">
                  //                   {obj.name}
                  //                 </h2>
                  //                 <p>Erschienen am {obj.time}</p>
                  //               </div>
                  //             </div>
                  //           );
                  //         })}
                  //     </div>
                  //   );
                } else if (currentsettings === "Accountlöschen") {
                  return (
                    <div className="w-[90vw] h-96 bg-base-100 p-10 border border-current rounded-lg">
                      <h1 className="text-2xl">Account Löschen</h1>
                      <p>
                        Bist du sicher, dass du dein Konto löschen möchtest?
                        Dieser Vorgang kann nicht rückgängig gemacht werden und
                        alle deine Kontodaten werden dauerhaft gelöscht. Wenn du
                        trotzdem fortfahren möchtest, klick auf "Account Löschen
                        beantragen".
                      </p>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="btn btn-primary mt-10 !rounded-full w-52 text-base-100"
                          onClick={requestdeleteAccount}
                        >
                          Account löschen
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="h-full w-full flex justify-center items-center">
                      <div className="w-full flex justify-center items-center flex-col mb-4">
                        {img != "Unknown" && img != " " && img != "" ? (
                          <img
                            src={img}
                            className="w-[300px] h-[300px] rounded-full m-2"
                          />
                        ) : (
                          <span className="material-symbols-outlined w-[300px] h-[300px] text-[300px] rounded-full m-2 padding-[1vh]">
                            account_circle
                          </span>
                        )}
                        <div className="text-3xl">{props.name}</div>
                        <div className="text-lg mt-2">{props.email}</div>
                        <div className="text-lg -mt-2">
                          Registriert am
                          {(function () {
                            let date = new Date(props.registertime);
                            return (
                              " " +
                              date.getDate() +
                              "." +
                              (date.getMonth() + 1) +
                              "." +
                              date.getFullYear()
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      <div className="Profilsettings-footer absolute bottom-0">
        <Footer colormode={colormode} />
      </div>
    </div>
  );
}
