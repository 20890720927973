import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

export default function Registrieren(props) {
  const [Benutzername, setBenutzername] = useState(null);
  const [password, setpassword] = useState(null);
  const [passwordwid, setpasswordwid] = useState(null);
  const [email, setemail] = useState(null);
  const [weiter, setweiter] = useState(false);
  const [showreq, setschowreq] = useState(false);

  const [cookiesallowed, setcookiesallowed] = useState(props.cookiesallowed);

  useEffect(() => {
    setcookiesallowed(props.cookiesallowed);
  }, [props.cookiesallowed]);

  const cookies = new Cookies();
  let history = useHistory();

  function handleBenutzername(e) {
    setBenutzername(e.target.value);
  }

  function handlePassword(e) {
    setpassword(e.target.value);
  }

  function handlePasswortWiederholen(e) {
    setpasswordwid(e.target.value);
  }

  function handleEmail(e) {
    setemail(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (cookies.get("Cookieallowed") || cookiesallowed === true) {
      if (Benutzername == null || password == null || passwordwid == null) {
        props.addnotification("Du musst alles Ausfüllen!");
      } else if (password.length < 8) {
        props.addnotification(
          "Das Passwort muss mindestens 8 Zeichen lang sein #666"
        );
      } else if (!email.includes("@iserv-gis.de") && !weiter) {
        setschowreq(true);
      } else if (password == passwordwid) {
        axios
          .post("https://spibi-online.de/register", {
            name: Benutzername,
            password: password,
            email: email,
          })
          .then((response) => {
            if (response.data.msg != null) {
              props.addnotification(response.data.msg);
            } else {
              cookies.set("User_id", response.data.id, { path: "/" });
              cookies.set("Hasaccount", true, { path: "/" });
              console.log(response.data.id);
              props.setUserid(response.data.id);
              history.push("/Activate");
              props.setstartloadingagainupdate();
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } else {
        props.addnotification("Die Passwörter stimmen nicht überein! #069");
      }
    } else {
      props.addnotification(
        "Du musst die Cookies erlauben um dich anmelden zu können! #111"
      );
    }
  }

  return (
    <div className="Registrieren">
      <div
        className={
          showreq
            ? "fixed w-11/12 h-36 top-4 ml-1/24 bg-rose-500 p-4 rounded-2xl z-10 transition-all duration-500 ease-in"
            : "fixed w-11/12 h-36 top-4 ml-1/24 bg-rose-500 p-4 rounded-2xl z-10 transition-all duration-500 ease-in hidden"
        }
      >
        <div>
          <div>
            Du hast keine IServ-Adresse angegeben, so kannst du nicht alle
            Artikel sehen.
          </div>
          <div>Trotzdem fortfahren?</div>
        </div>
        <div className="absolute right-4 bottom-4">
          <button
            className="btn btn-error p-4"
            onClick={() => {
              setschowreq(false);
            }}
          >
            Nein
          </button>
          <button
            className="btn btn-success ml-4 p-4"
            onClick={() => {
              setschowreq(false);
              setweiter(true);
              handleSubmit({ preventDefault: () => {} });
            }}
          >
            Ja
          </button>
        </div>
      </div>

      <div className="Form-card">
        <h3 className="mb-2">Registrierung:</h3>

        <div className="Registrieren-input-div">
          <input
            type="text"
            maxLength="16"
            placeholder="Benutzername festlegen"
            onChange={handleBenutzername}
            autoComplete="off"
            className="!rounded-full placeholder:opacity-50"
          />
        </div>
        <div className="Registrieren-input-div">
          <input
            type="text"
            placeholder="Email"
            onChange={handleEmail}
            autoComplete="off"
            className="!rounded-full placeholder:opacity-50"
          />
        </div>
        <div className="Registrieren-input-div">
          <input
            type="password"
            placeholder="Passwort"
            onChange={handlePassword}
            className="!rounded-full placeholder:opacity-50"
          />
        </div>
        <div className="Registrieren-input-div">
          <input
            type="password"
            placeholder="Passwort wiederholen"
            onChange={handlePasswortWiederholen}
            className="!rounded-full placeholder:opacity-50"
          />
        </div>
        <button
          className="Registrieren-btn text-black !rounded-full"
          onClick={handleSubmit}
        >
          Registrieren
        </button>
        <p className="Registrieren-p">
          Hast du schon einen Account?
          <br />
          <Link to="/Anmelden">Anmelden</Link>
        </p>
      </div>
      <div className="absolute top-[90vh] text-white width-[50vw]">
        <Link to="/Datenschutz" className="absolute left-[5vw] link link-hover">
          Datenschutz
        </Link>
        <div className="absolute right-[50%]">|</div>
        <a
          href="https://gis-wf.de/Impressum"
          className="link link-hover absolute right-[5vw]"
        >
          Impressum
        </a>
      </div>
    </div>
  );
}
