import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const Createbanner = (props) => {
  const titleref = useRef(null);
  const position = useRef(null);
  const link = useRef(null);
  const type = useRef(null);
  const [svg, setsvg] = useState(null);

  const cookies = new Cookies();
  let history = useHistory();

  function createBanner() {
    if (
      svg != null &&
      titleref.current.value.length > 0 &&
      position.current.value.length > 0
    ) {
      axios
        .post("https://spibi-online.de/addbanner", {
          userid: cookies.get("User_id"),
          svg: svg,
          title: titleref.current.value,
          link: link.current.value,
          position: Number(position.current.value),
          type: type.current.value,
        })
        .then((response) => {
          if (response.data.foo) {
            titleref.current.value = "";
            position.current.value = "";
            link.current.value = "";
          } else {
            if (response.data.msg) {
              props.addnotification(response.data.msg);
            }
          }
        })
        .catch((error) => {
          console.error(error, "error");
        });
    } else {
      props.addnotification("Du musst erst was eintragen");
    }
  }

  function handleimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        setsvg(upload.target.result);
      } else {
        props.addnotification("Diese Datei ist zu groß #023", "red");
      }
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className="Createbanner">
      <div className="Createbanner-first">
        <div className="Createbanner-text">Title:</div>
        <input
          className="Createbanner-input"
          ref={titleref}
          name="title"
          placeholder=" Title"
        />
      </div>
      <div className="Createbanner-secound">
        <div className="Createbanner-text">Svg:</div>
        <input
          className="Createbanner-input"
          onChange={handleimg}
          type="file"
          name="svg"
          placeholder=" Svg"
        />
      </div>
      <div className="Createbanner-third">
        <div className="Createbanner-text">Position:</div>
        <input
          className="Createbanner-input"
          ref={position}
          name="position"
          placeholder=" Position des Banners"
        />
      </div>
      <div className="Createbanner-fourth">
        <div className="Createbanner-text">Link:</div>
        <input
          className="Createbanner-input"
          ref={link}
          name="link"
          placeholder=" Link zum Banner"
        />
      </div>
      <div className="Createbanner-fives">
        <div className="Createbanner-text">Type:</div>
        <select
          className="Createbanner-input"
          ref={type}
          name="type"
          placeholder=" Position des Banners"
        >
          <option>Werbung</option>
          <option>Events</option>
          <option>Hinweis</option>
        </select>
      </div>
      <button className="Createbanner-btn" type="submit" onClick={createBanner}>
        Banner Erstellen
      </button>
    </div>
  );
};

export default Createbanner;
