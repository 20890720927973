import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Frage from "./Fragenbearbeitenfrage";

export default function Fragenbearbeiten(props) {
  const [fragen, setFragen] = useState(null);

  const cookies = new Cookies();
  let history = useHistory();

  function handleloeschen(frage, text, id) {
    axios
      .post("https://spibi-online.de/Antwortloeschen", {
        frageid: frage,
        text: text,
        antwortenid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  function deleat(id) {
    axios
      .post("https://spibi-online.de/deleateFrage", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  function handlesave(frageid, text, mode) {
    axios
      .post("https://spibi-online.de/Fragebearbeiten", {
        userid: cookies.get("User_id"),
        id: frageid,
        mode: mode,
        text: text,
      })
      .then((response) => {
        if (response.data.msg == null) {
          window.location.reload();
        } else {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isMod", { id: cookies.get("User_id") })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    axios
      .post("https://spibi-online.de/getFragen", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          setFragen(response.data.fragen);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <div className="Fragenbearbeiten">
      {fragen != null &&
        fragen.map((frage, index) => {
          return (
            <Frage
              key={index}
              key2={index}
              frage={frage}
              mode={frage.mode}
              handlesave={handlesave}
              handleloeschen={handleloeschen}
              deleat={deleat}
            />
          );
        })}
    </div>
  );
}
