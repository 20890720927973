import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MyArticle from "./MyArticle";

export default function MyArticles(props) {
  const [MyArticlesArray, setMyArticlesArray] = useState([]);
  const cookies = new Cookies();

  useEffect(() => {
    axios
      .post("https://spibi-online.de/Myarticles", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg == null) {
          setMyArticlesArray(response.data.artikel);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <div>
      <div className="MyArticles">
        <h3>Meine Artikel</h3>
        <br />
        <br />
        {MyArticlesArray &&
          MyArticlesArray.map((obj, index) => {
            return (
              <MyArticle
                id={obj._id}
                key={String(index)}
                img={obj.img}
                title={obj.name}
                time={obj.time}
                views={obj.views}
              />
            );
          })}
        <br />
        <div className="Home-bottom"></div>
        <br />
        <br />
      </div>
    </div>
  );
}
