import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Artikel from "./Artikel";
import Navbar from "./Navbar";
import Banner from "./banner";
import { useLocation } from "react-router-dom";
import LogoB from "./icons/spibiLogo.svg";
import LogoW from "./icons/spibiLogo_weiß.svg";

import Artikelloading from "./Artikelloading";
import Footer from "./footer";

export default function Home(props) {
  const location = useLocation();
  const cookies = new Cookies();
  const [search, setsearch] = useState("");
  const [filterHidden, setFilterHidden] = useState(false);
  const [category, setcategory] = useState("");
  const [sorting, setsorting] = useState("1");
  const [artikel, setartikel] = useState(props.artikel);
  const [sortedartikel, setsortedartikel] = useState([]);
  const [Name, setname] = useState(props.name);
  const [artikelids, setartikelids] = useState(null);
  const [banners, setbanners] = useState(props.banners);
  const [Plus, setPlus] = useState(false);
  const [gelesen, setgelesen] = useState(props.gelesen);
  const homeScroll = useRef(null);
  const [toggleLeft, settoggleLeft] = useState(false);
  const [img, setimg] = useState(null);
  let history = useHistory();
  let something = 0;

  function handleinputChange(e) {
    setsearch(e.target.value);
  }

  function setcategoryfoo(search) {
    if (search === category) {
      setcategory("");
    } else {
      setcategory(search);
    }
  }

  function showFilter() {
    if (filterHidden === true) {
      setFilterHidden(false);
    } else {
      setFilterHidden(true);
    }
  }

  function setsetsorting(e) {
    setsorting(e.target.value);
  }

  useEffect(() => {
    setartikel(props.artikel);
    setbanners(props.banners);
    sort();
  }, [props.artikel, props.banners]);

  useEffect(() => {
    setname(props.name);
    setgelesen(props.gelesen);
  }, [props.name, props.gelesen]);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isPlus", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == true) {
          setPlus(true);
        }
      });
  }, [props.artikel]);

  useEffect(() => {
    axios
      .post("https://spibi-online.de/getProfilepicture", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        console.log(response);
        setimg(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  function sort() {
    let curartikel = props.artikel.map((obj) => obj);
    // if (sorting === "2") {
    //   curartikel = curartikel.reverse();
    // } else if (sorting === "1") {
    //   curartikel = curartikel.sort((a, b) => {
    //     console.log(a.name);
    //     console.log(Date.parse(a.time));
    //     if (Date.parse(a.time) < Date.parse(b.time)) {
    //       return 1;
    //     }
    //     if (Date.parse(a.time) > Date.parse(b.time)) {
    //       return -1;
    //     }
    //   });
    // } else if (sorting === "3") {
    //   curartikel = curartikel.sort((a, b) => {
    //     if (a.views < b.views) {
    //       return 1;
    //     }
    //     if (a.views > b.views) {
    //       return -1;
    //     }
    //   });
    // } else if (sorting === "4") {
    //   curartikel = curartikel.sort((a, b) => {
    //     if (a.views < b.views) {
    //       return -1;
    //     }
    //     if (a.views > b.views) {
    //       return 1;
    //     }
    //   });
    // }
    console.log(search);
    curartikel = curartikel.filter((obj) => {
      console.log(obj);
      let search2;
      if (search) {
        search2 = search.toLowerCase();
      } else {
        search2 = "";
      }

      if (search === "") {
        return true;
      } else if (obj.name.toLowerCase().includes(search2)) {
        return true;
      } else if (obj.autor.toLowerCase().includes(search2)) {
        return true;
      } else if (obj.titletext.toLowerCase().includes(search2)) {
        return true;
      } else {
        return false;
      }
    });

    setsortedartikel(curartikel);
    //let foo = 0;
    // let curartikel2 = curartikel.map((obj, index) => {
    //   if (!obj.plus || obj.artikel[0] != null) {
    //     foo += 1;
    //     return { id: foo };
    //   } else {
    //     return { id: null };
    //   }
    // });
    // setartikelids(curartikel2);
  }

  useEffect(() => {
    sort();
  }, [sorting, search]);

  useEffect(() => {
    setsearch(props.search);
  }, [props.search]);

  const executeScroll = () => homeScroll.current.scrollIntoView();

  let foo = 0;

  function logout() {
    cookies.set("User_id", "", { path: "/" });
    history.push("/Anmelden");
    window.location.reload();
  }

  return (
    <div>
      <div className="navbar bg-base-100 rounded-lg !w-[96vw]">
        <div className="navbar-start">
          <div className="only-phone">
            <label htmlFor="my-drawer-3" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
          </div>
          <div className="dropdown only-desktop">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Kategorien">Kategorien</Link>
              </li>
              <li>
                <Link to="/createartikel">Artikel erstellen</Link>
              </li>
              <li className="text-base-300 hover:bg-base-100">
                {/*to="/Events"*/}
                <div className="hover:bg-base-100">Events</div>
              </li>

              <li className="text-base-300 hover:bg-base-100">
                <div className="hover:bg-base-100">Umfragen</div>
              </li>
              <hr />
              <li>
                <Link to="/Konto">Einstellungen</Link>
              </li>
              {/* <li>
                <Link to="/myArticles">Meine Artikel</Link>
              </li> */}
              {/* <li>
                <Link to="/createArtikel">Artikel erstellen</Link>
              </li> */}
              <li>
                <div onClick={() => logout()}>Abmelden</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <Link className="btn btn-ghost normal-case text-xl" to="/">
            <img
              src={props.colormode == "cmyk" ? LogoB : LogoW}
              className="navbar-top-text-logo"
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-end">
          <div className="form-control only-desktop">
            <input
              type="text"
              placeholder="Search"
              className="input input-bordered !rounded-full"
              onChange={handleinputChange}
            />
          </div>
          <Link to="/Konto" className="max-h-[48px] rounded-full">
            <label
              tabIndex="0"
              className="btn btn-ghost btn-circle rounded-full"
            >
              <div className="w-[48px] rounded-full">
                {img != "Unknown" && img != " " && img != "" && img != "" ? (
                  <img src={img} className="rounded-full w-[48px] h-[48px]" />
                ) : (
                  <span className="material-symbols-outlined relative top-[-1px] text-[48px]">
                    account_circle
                  </span>
                )}
              </div>
            </label>
          </Link>
        </div>
      </div>
      <div className="Home pb-60">
        <div className="Home-inner" ref={homeScroll}>
          <div className="Home-Artikel flex justify-center relative">
            <div className="artikel-list-normal only-phone object-cover">
              {sortedartikel.length > 0 &&
                sortedartikel.map((obj, index) => {
                  let searchedbanner = [];
                  if (banners) {
                    searchedbanner = banners.filter(
                      (banner) => Number(banner.position) === index
                    );
                  }
                  if (searchedbanner.length > 0) {
                    return (
                      <div key={index}>
                        <Artikel
                          key2={index}
                          key={String(index)}
                          id={obj._id}
                          img={obj.img}
                          title={obj.name}
                          time={obj.time}
                          color={obj.color}
                          text={obj.titletext}
                          autor={obj.autor}
                          artikeltext={obj.artikel}
                          className={"normal-article"}
                          plus={obj.plus}
                          colormode={props.colormode}
                          tags={obj.tags}
                          colors={props.colors}
                          gelesen={gelesen}
                        />
                        <Banner
                          svg={searchedbanner[0].svg}
                          link={searchedbanner[0].link}
                          type={searchedbanner[0].type}
                          isPlus={Plus}
                          colors={props.colors}
                          colormode={props.colormode}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <Artikel
                        key2={index}
                        key={String(index)}
                        id={obj._id}
                        img={obj.img}
                        title={obj.name}
                        time={obj.time}
                        color={obj.color}
                        text={obj.titletext}
                        autor={obj.autor}
                        artikeltext={obj.artikel}
                        className={"normal-article"}
                        plus={obj.plus}
                        colormode={props.colormode}
                        tags={obj.tags}
                        colors={props.colors}
                        gelesen={gelesen}
                      />
                    </div>
                  );
                })}
              {sortedartikel.length < 1 && search.length < 1 && (
                <div>
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                </div>
              )}
            </div>

            <div
              className={
                "spibi-artikel only-desktop relative Home-limitsize flex "
              }
            >
              {(function () {
                if (sortedartikel.length > 0) {
                  let obj = sortedartikel[0];
                  let index = 0;
                  return (
                    <Artikel
                      key2={index}
                      key={String(index)}
                      id={obj._id}
                      img={obj.img}
                      title={obj.name}
                      color={obj.color}
                      colormode={props.colormode}
                      text={obj.titletext}
                      autor={obj.autor}
                      colors={props.colors}
                      artikeltext={obj.artikel}
                      className={"first-article"}
                      time={obj.time}
                      plus={obj.plus}
                      tags={obj.tags}
                      gelesen={gelesen}
                    />
                  );
                } else if (search.length < 1) {
                  return (
                    <Artikelloading
                      version={4}
                      colors={props.colors}
                      colormode={props.colormode}
                    />
                  );
                }
              })()}

              <div className="column1 absolute">
                {sortedartikel.length > 1 &&
                  sortedartikel.map((obj, index) => {
                    if (index != 0) {
                      if (index % 2 != 0) {
                        return (
                          <Artikel
                            key2={index}
                            key={String(index)}
                            id={obj._id}
                            img={obj.img}
                            title={obj.name}
                            color={obj.color}
                            colormode={props.colormode}
                            text={obj.titletext}
                            autor={obj.autor}
                            colors={props.colors}
                            artikeltext={obj.artikel}
                            time={obj.time}
                            className={"normal-article"}
                            plus={obj.plus}
                            tags={obj.tags}
                            gelesen={gelesen}
                          />
                        );
                      }
                    }
                  })}
                {sortedartikel.length < 1 && search.length < 1 && (
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                )}
              </div>
              <div className="column2 absolute right-0">
                {sortedartikel.length > 0 &&
                  sortedartikel.map((obj, index) => {
                    if (index != 0) {
                      if (index % 2 == 0) {
                        return (
                          <Artikel
                            key2={index}
                            key={String(index)}
                            id={obj._id}
                            img={obj.img}
                            title={obj.name}
                            color={obj.color}
                            colormode={props.colormode}
                            time={obj.time}
                            text={obj.titletext}
                            autor={obj.autor}
                            colors={props.colors}
                            artikeltext={obj.artikel}
                            className={"normal-article"}
                            plus={obj.plus}
                            tags={obj.tags}
                            gelesen={gelesen}
                          />
                        );
                      }
                    }
                  })}
                {sortedartikel.length < 1 && search.length < 1 && (
                  <Artikelloading
                    version={1}
                    colors={props.colors}
                    colormode={props.colormode}
                  />
                )}
                {(function () {
                  if (sortedartikel.length % 2 == 0 && search.length < 1) {
                    return (
                      <Link to={"/createartikel"} className="font-serif">
                        <div
                          className={
                            "artikel card bg-base-content shadow-md normal-article"
                          }
                        >
                          <img className="bg-base-200 opacity-0" alt="" />
                          <div className="absolute card w-full h-1/2 bottom-0"></div>
                          <div className="card-body absolute bottom-0 text-white">
                            <h2
                              className="card-title mt-1 artikel-title"
                              style={{ wordBreak: "break-word" }}
                            >
                              Hier könnte dein Artikel stehen
                            </h2>
                            <p className="text-xl">Klick Mich!</p>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
