import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Createevent = (props) => {
  const titleref = useRef(null);
  const [img, setimg] = useState(null);
  const imgref = useRef(null);
  const img2 = useRef(null);
  const datumref = useRef(null);
  const textref = useRef(null);

  const cookies = new Cookies();
  let history = useHistory();

  function handleimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = function (upload) {
      if (upload.target.result.length < 10000000) {
        setimg(upload.target.result);
        imgref.current.innerHTML = "Titelbild hochladen: " + img2.current.value;
      } else {
        props.addnotification("Diese Datei ist zu groß #010");
        img2.current.value = "";
      }
    };
    reader.readAsDataURL(file);
  }

  function createEvent() {
    if (
      img.length > 0 &&
      titleref.current.value.length > 0 &&
      datumref.current.value.length > 0 &&
      textref.current.value.length > 0
    ) {
      axios
        .post("https://spibi-online.de/addevent", {
          userid: cookies.get("User_id"),
          picture: img,
          title: titleref.current.value,
          text: textref.current.value,
          date: datumref.current.value,
        })
        .then((response) => {
          if (response.data.foo) {
            setimg("");
            titleref.current.value = "";
            textref.current.value = "";
            datumref.current.value = "";
          } else {
            if (response.data.msg) {
              props.addnotification(response.data.msg);
            }
          }
        })
        .catch((error) => {
          console.error(error, "error");
        });
    } else {
      props.addnotification("Du musst erst etwas eingeben!");
      console.log(datumref.current.value);
    }
  }

  return (
    <div className="CreateEvent">
      <div className="CreateEvent-first">
        <div className="CreateEvent-text">Title:</div>
        <input
          className="CreateEvent-input"
          ref={titleref}
          name="title"
          placeholder=" Title"
        />
      </div>
      <div className="CreateEvent-secound">
        <div className="upload-btn-wrapper">
          <button
            className="btn-artikelerstellen"
            style={{
              boxShadow:
                "rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px",
            }}
          >
            <p ref={imgref}>Titelbild hochladen</p>
          </button>

          <input
            style={{
              boxShadow:
                "rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px",
            }}
            ref={img2}
            type="file"
            name="file"
            className="Artikelerstellen-img"
            id="file"
            onChange={handleimg}
            encType="multipart/form-data"
            required
          />
        </div>
      </div>
      <div className="CreateEvent-third">
        <div className="CreateEvent-text">Datum:</div>
        <input
          className="CreateEvent-input"
          ref={datumref}
          name="datum"
          type="date"
          placeholder=" Position des Banners"
        />
      </div>
      <div className="CreateEvent-fourth">
        <div className="CreateEvent-text">Text:</div>
        <textarea
          className="CreateEvent-input"
          ref={textref}
          name="position"
          placeholder=" Position des Banners"
        ></textarea>
      </div>
      <button className="CreateEvent-btn" type="submit" onClick={createEvent}>
        Event Erstellen
      </button>
    </div>
  );
};

export default Createevent;
