import React, { useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const Codeserstellen = (props) => {
  const Anzahl = useRef(null);
  const Time = useRef(null);
  const codes = useRef(null);

  const cookies = new Cookies();
  let history = useHistory();

  function GeneriereCodes() {
    let number = Anzahl.current.value;
    if (Number.isInteger(Number(number))) {
      axios
        .post("https://spibi-online.de/generatecodes", {
          userid: cookies.get("User_id"),
          anzahl: number,
          time: Time.current.value,
        })
        .then((response) => {
          if (response.data.msg == null) {
            codes.current.innerHTML = response.data.ids;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      props.addnotification("Du musst eine Zahl angeben!");
      Anzahl.current.value = "";
    }
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isAdmin", {
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <div className="Codeserstellen fixed" style={{ marginTop: "7vh" }}>
      <div className="grid gap-1 grid-cols-3">
        <input
          ref={Anzahl}
          className="mt-4 ml-2 placeholder:italic placeholder:text-gray-400 block bg-white w-full border border-gray-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
          placeholder="Anzahl an Codes"
          type="text"
        />
        <select
          ref={Time}
          className="mt-4 ml-2 placeholder:italic placeholder:text-gray-400 block bg-white w-full border border-gray-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
        >
          <option value={2592000} selected>
            1 Monat
          </option>
          <option value={7776000}>3 Monate</option>
          <option value={31536000}>1 Jahr</option>
        </select>
        <button
          onClick={GeneriereCodes}
          className="mt-4 tounded-lg border-4 border-black mr-2 rounded-lg ml-2"
        >
          Generieren
        </button>
        <div ref={codes}></div>
      </div>
    </div>
  );
};

export default Codeserstellen;
