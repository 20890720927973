import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Spibi_logo from "./img/Logo.svg";

const Kommentarreply = (props) => {
  const inputKommentar = useRef(null);
  const [name, setname] = useState(props.username);
  const [update, setupdate] = useState(0);
  const [replys, setreplys] = useState([]);
  //console.log(props);

  const cookies = new Cookies();
  let history = useHistory();

  function handlelöschen(uuid, id) {
    axios
      .post("https://spibi-online.de/Kommentarloeschen", {
        artikelid: props.id,
        uuid: uuid,
        kommentarid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data == true) {
          props.getKommentare();
          props.addnotification("Gelöscht");
        }
      })
      .catch((error) => {
        console.log(error, "Das hat nicht geklappt #187");
      });
  }

  useEffect(() => {
    props.replys.map((obj, index) => {
      return "";
    });
  }, []);

  function changereply(e, number) {
    let newreply = replys.map((obj) => obj);
    newreply[number] = e.target.value;
    setreplys(newreply);
  }

  function handlereply(text, id) {
    axios
      .post("https://spibi-online.de/aufkommentareantworten", {
        artikelid: props.id,
        text: text,
        kommentarid: id,
        id: cookies.get("User_id"),
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        } else {
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function Profilepicture(obj) {
    if (
      obj.profilepic == null ||
      obj.profilepic == "" ||
      obj.profilepic == undefined ||
      obj.profilepic == "Unknown"
    ) {
      return (
        <div className="avatar placeholder">
          <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
            <span>{obj.name.substring(0, 1).toUpperCase()}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="avatar">
          <div className="w-12 rounded-full">
            <img src={obj.profilepic} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="Replys border-l-2 border-secondary">
      {props.replys &&
        props.replys.map((obj, index) => {
          return (
            <div key={index} className="mt-4 ml-2">
              <div className="Kommentare-Kommentar-hedline ml-1 relative">
                {Profilepicture(obj)}
                <div className="Kommentare-Kommentar-name ml-3">
                  {obj.name} vor{" "}
                  {(function () {
                    if (Date.now() - obj.time > 1000 * 60 * 60 * 24) {
                      let Tage =
                        (Date.now() - obj.time) / (1000 * 60 * 60 * 24);
                      Tage = Math.floor(Tage);
                      if (Tage > 1) {
                        return Tage + " Tagen";
                      } else {
                        return "einem Tag";
                      }
                    } else if (Date.now() - obj.time > 1000 * 60 * 60) {
                      let Stunden = (Date.now() - obj.time) / (1000 * 60 * 60);
                      Stunden = Math.floor(Stunden);
                      if (Stunden > 1) {
                        return Stunden + " Stunden";
                      } else {
                        return "einer Stunde";
                      }
                    } else if (Date.now() - obj.time > 1000 * 60) {
                      let Minuten = (Date.now() - obj.time) / (1000 * 60);
                      Minuten = Math.floor(Minuten);
                      if (Minuten > 1) {
                        return Minuten + " Minuten";
                      } else {
                        return "einer Minute";
                      }
                    } else {
                      return "weniger als einer Minute";
                    }
                  })()}
                </div>
                {obj != null &&
                  (obj.name === name || props.isAdmin) &&
                  obj.name !== undefined && (
                    <button
                      onClick={() => handlelöschen(obj.uuid, obj.id)}
                      className=" absolute right-0"
                    >
                      <span className="material-symbols-outlined text-secondary">
                        delete
                      </span>
                    </button>
                  )}
              </div>
              <div className="Kommentare-Kommentar-text">{obj.text}</div>
              {/*
                <Kommentarreply
                  replys={obj.replys}
                  id={props.id}
                  username={props.username}
                />
                <div className="Kommentare-Kommentieren">
                  <input
                    type="text"
                    placeholder="Deine Antwort"
                    onChange={(e) => {
                      changereply(e, index);
                    }}
                    id="Kommentar"
                    className="Kommentare-Kommentieren-input"
                  />
                  <button
                    className="Kommentare-Kommentieren-submit"
                    onClick={() => {
                      handlereply(replys[index], obj.uuid);
                    }}
                  >
                    Kommentieren
                  </button>
                </div>*/}
            </div>
          );
        })}
    </div>
  );
};

export default Kommentarreply;
