import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Artikelbearbeiten from "./Artikelbearbeiten";

export default function Bearbeiten(props) {
  const [vorschlagArtikellist, setvorschlagArtikellist] = useState(null);
  const [Artikellist, setArtikellist] = useState(props.artikel);
  const [isMod, setisMod] = useState(false);

  const cookies = new Cookies();
  let history = useHistory();

  function save(id, img, title, text, color, artikeltext) {
    axios
      .post("https://spibi-online.de/bearbeiteArtikel", {
        userid: cookies.get("User_id"),
        id: id,
        img: img,
        title: title,
        text: text,
        color: color,
        artikeltext: artikeltext,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  function deleat(id) {
    axios
      .post("https://spibi-online.de/deleateArtikel", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  function save2(id, img, title, text, color, artikeltext) {
    axios
      .post("https://spibi-online.de/bearbeitevorArtikel", {
        userid: cookies.get("User_id"),
        id: id,
        img: img,
        title: title,
        text: text,
        color: color,
        artikeltext: artikeltext,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  function deleat2(id) {
    axios
      .post("https://spibi-online.de/deleatevorArtikel", {
        userid: cookies.get("User_id"),
        id: id,
      })
      .then((response) => {
        if (response.data.msg != null) {
          props.addnotification(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    window.location.reload();
  }

  useEffect(() => {
    axios
      .post("https://spibi-online.de/isMod", { id: cookies.get("User_id") })
      .then((response) => {
        if (response.data != true) {
          history.push("/");
        } else {
          setisMod(response.data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    if (isMod) {
      axios
        .post("https://spibi-online.de/Artikelvorschlage", {
          id: cookies.get("User_id"),
        })
        .then((response) => {
          if (response.data.artikel != null) {
            setvorschlagArtikellist(response.data.artikel);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [isMod]);

  return (
    <div className="SelectArticle">
      {Artikellist != null &&
        isMod == true &&
        Artikellist.map((obj, index) => {
          return (
            <Artikelbearbeiten
              save={save}
              deleat={deleat}
              key2={index}
              key={String(index)}
              id={obj._id}
              img={obj.img}
              title={obj.name}
              color={obj.color}
              text={obj.titletext}
              artikeltext={obj.artikel}
              kommentare={obj.Kommentare}
              autor={obj.autor}
            />
          );
        })}

      <h1>Vorgeschlagene Artikel</h1>

      {vorschlagArtikellist != null &&
        vorschlagArtikellist.map((obj, index) => {
          return (
            <Artikelbearbeiten
              save={save2}
              deleat={deleat2}
              key2={index}
              key={String(index)}
              id={obj._id}
              img={obj.img}
              title={obj.name}
              color={obj.color}
              text={obj.titletext}
              artikeltext={obj.artikel}
              kommentare={obj.Kommentare}
              autor={obj.autor}
            />
          );
        })}
    </div>
  );
}
